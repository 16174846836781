import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import './PQRSLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const PQRSLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <section className='pqrsLayoutContainer'>
      <div className="headerContainerPQRS">
        <div className="PQRSAdminContainer">
          <p className="PQRSAdminParagraph">{scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.pqrs : ''}</p>
        </div>
      </div>
      <hr className="hrPQRSAdmin" />
      <Outlet />
    </section>
  )
}
