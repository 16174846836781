import React, { createContext, useEffect, useState, useMemo } from 'react'

const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN

export const ScriptContext = createContext()

export const ScriptProvider = ({ children }) => {
  const [scripts, setScripts] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${REACT_APP_DOMAIN}/scripts/admin.json`)
      const jsonData = await response.json()
      setScripts(jsonData)
    }

    fetchData()
  }, [])

  const scriptsSave = useMemo(() => {
    if (!scripts) return null

    return scripts
  }, [scripts])

  const data = {
    scriptsSave
  }

  return (
    <ScriptContext.Provider value={data}>
      {children}
    </ScriptContext.Provider>
  )
}
