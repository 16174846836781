import { useState } from 'react'
export const useIncorrectInputFormat = () => {
  const [incorrectInputFormat, setIncorrectInputFormat] = useState({
    comertialNameFormat: 'employeeIncorrectFormat',
    comertialNameMessage: 'El nombre comercial debe tener más de 10 caracteres.',
    bussinessNameFormat: 'employeeIncorrectFormat',
    bussinessNameMessage: 'El nombre empresarial debe tener más de 10 caracteres.',
    companyEmailFormat: 'employeeIncorrectFormat',
    companyEmailMessage: 'El correo no puede iniciar con espacios'
  })
  return [incorrectInputFormat, setIncorrectInputFormat]
}
