import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../utils/functions'
import { getCompanyQuery } from '../../utils/queries'

export const formatCompaniesManagementList = (companiesManagementList, setCompaniesManagementList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(companiesManagementList)
  const currentItem = page
  setCompaniesManagementList(companiesManagementList.map((user, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      ...user,
      userType: 'Usuario Empresa'
    }
  }))
}

export const onDetailClick = async (
  navigate,
  user,
  idToken,
  dataDispatch,
  dataContext
) => {
  const getCompanyQueryResponse = await getCompanyQuery(dataContext, user.companyId, idToken)
  let company = {}
  if (getCompanyQueryResponse.response) {
    const { body } = getCompanyQueryResponse
    company = {
      ...body
    }
  }
  const action = {
    type: dataTypes.setCompanyManagementToBeReviewd,
    payload: company
  }
  dataDispatch(action)
  navigate('DetalleEmpresa')
}
