import React, { useContext } from 'react'

import { DataContext } from '../../context/DataContext'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { RequestsGrid } from '../../components/RequestsGrid/RequestsGrid'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { getResponsesByEntity } from '../../utils/userQueries'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import { formatResponsesByEntityList } from './RequestsFunctions'

import './Requests.css'

export const Requests = () => {
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, requests } = dataState.lawyer
  const { responsesByEntity } = requests
  const responsesByEntityList = responsesByEntity.list

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    responsesByEntity,
    'responsesByEntity',
    getResponsesByEntity,
    dataTypes.getResponsesByEntity,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    responsesByEntityList,
    formatResponsesByEntityList,
    page,
    limit
  )

  return (
    <div className='requests'>
      <RequestsGrid
        originalRequestsList={originalList}
        responsesByEntityList={listFormatted}
        setResponsesByEntityList={setListFormatted}
        responsesByEntity={responsesByEntity}
        paginationValues={paginationValues}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </div>
  )
}
