import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import backIcon from '../../../assets/icons/icon_volver.svg'
import eyeIcon from '../../../assets/icons/icon_pass2.svg'
import { ImageModal } from '../../../components/ImageModal/ImageModal'
import { useModal } from '../../../hooks/useModal'
import { formatDateDDMMYYMonthInLetters, handleViewDocument } from '../../../utils/functions'

import './UserAttachments.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const UserAttachments = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { state: pqrsDetail } = useLocation()
  const { userId } = pqrsDetail
  const { personId } = userId

  const [showModal, handleShow, handleClose] = useModal()

  const [attachmentsList, setAttachmentsList] = useState([])
  const [imgPath, setImgPath] = useState('')

  useEffect(() => {
    const attachmentsList = JSON.parse(pqrsDetail?.documentPath)
    setAttachmentsList(attachmentsList)
  }, [])

  return (
    <section id='UserAttachments'>
      <div>
        <div id='UserAttachments-card'>
          <div className='UserAttachments-card-tside'>
            <h2 className='card-rside-title'>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.data : ''}</h2>
            <div className='card-rside-grid'>
              <div>
                <p className='card-rside-key'>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.name : ''}</p>
                <p className='card-rside-value'>{`${personId.personName} ${personId.personLastName}`}</p>
              </div>
              <div>
                <p className='card-rside-key'>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.identification : ''}</p>
                <p className='card-rside-value'>{personId.personIdentNum}</p>
              </div>
              <div>
                <p className='card-rside-key'>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.cel : ''}</p>
                <p className='card-rside-value'>{personId.personPhone}</p>
              </div>
              <div>
                <p className='card-rside-key'>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.identificationType : ''}</p>
                <p className='card-rside-value'>{personId.identDocId.identDocName}</p>
              </div>
              <div>
                <p className='card-rside-key'>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.email : ''}</p>
                <p className='card-rside-value'>{userId.credentialId.credentialEmail}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='UserAttachments-bside'>
          <h2>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.changes : ''}</h2>
          <table>
            <thead>
              <tr>
                <th>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.document : ''}</th>
                <th>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.date : ''}</th>
                <th>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.see : ''}</th>
              </tr>
            </thead>
            <tbody>
              {
                attachmentsList?.map((attachment, index) => (
                  <tr key={index}>
                    <td>
                      {attachment.fileName.length > 15
                        ? `${attachment.fileName.substring(0, 12)}...`
                        : attachment.fileName}
                    </td>
                    <td>{formatDateDDMMYYMonthInLetters(pqrsDetail.createDt)}</td>
                    <td>
                      <img
                        alt={scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.altImageTxt1 : ''}
                        className='userAttachmentsEyeIcon'
                        onClick={() => handleViewDocument(
                          attachment.fileName,
                          attachment.path,
                          setImgPath,
                          handleShow
                        )}
                        src={eyeIcon}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className='UserAttachmentsFooter'>
        <Link to={'../SolicitudDelUsuario/'} state={pqrsDetail} className='UserAttachmentsFooterBackLink'>
          <img
            src={backIcon}
            alt={scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.altImageTxt2 : ''}
          />
          <span>{scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.back : ''}</span>
        </Link>
      </div>
      <ImageModal isOpen={showModal} title='imagen' imageSrc={imgPath} buttonContainerClass='imageModalButtonsOperativo'>
        <ImageModal.Buttons>
          <button className='editNotAllowedContactModalBtn' onClick={handleClose}>
            {scriptsSave && scriptsSave.userAttachmentsString ? scriptsSave.userAttachmentsString.modalButton1Text : ''}
          </button>
        </ImageModal.Buttons>
      </ImageModal>
    </section>
  )
}
