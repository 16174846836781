import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { NewUserCard } from './components/NewUserCard/NewUserCard'
import backIcon from '../../../../assets/icons/icon_volver.svg'

import './NewUser.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

const roles = ['Administrativo', 'Abogado', 'Operativo']

export const NewUser = () => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <>
      <div>
        <div className='newUserHeaderContainer'>
          <p className={'newUserParagraph'}>
            {scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.newUser : ''}
          </p>
        </div>
        <hr className='hrNewUser' />
        <div className='newUserAuxContainer'>
          <p className='newUserMainTitle'>{scriptsSave && scriptsSave.newUserString ? scriptsSave.newUserString.info1 : ''}</p>
          <p className='newUserMainSubtitle'>{scriptsSave && scriptsSave.newUserString ? scriptsSave.newUserString.info2 : ''}</p>
        </div>
      </div>
      <div className='newUserCardsContainer'>
        {
          roles.map((role) => (
            <NewUserCard key={role} type='createUser' rol={role} />
          ))
        }
      </div>
      <hr className='newUserSecondaryHR' />
      <div className='newUserFooter'>
        <NavLink to={'..'} className='newUserFooterBackLink'>
          <img
            src={backIcon}
            alt={scriptsSave && scriptsSave.newUserString ? scriptsSave.newUserString.altImageTxt1 : ''}
          />
          <span>{`${scriptsSave && scriptsSave.newUserString ? scriptsSave.newUserString.goBack : ''}`}</span>
        </NavLink>
      </div>
    </>
  )
}
