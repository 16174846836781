import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import {
  getCities,
  getDocTypes,
  getGenders,
  getStates,
  getUserData,
  updateUserData,
  updateUserEmail
} from '../../../../utils/userQueries'
import { employeeString } from '../../../../utils/strings'

export const fetchData = async ({
  idToken,
  state,
  setinitialValues,
  setNewValues,
  dataContext
}) => {
  const { body } = await getUserData(dataContext, idToken, state)
  const userData = body.personId
  const userEmail = body.credentialId
  const userId = body.userId
  const personId = body.personId.personId
  const credentialId = body.credentialId.credentialId

  const initValuesBody = {
    userId,
    personId,
    credentialId,
    name: userData.personName,
    lastName: userData.personLastName,
    documentTypeId: userData.identDocName,
    gender: userData.genderId.genderId,
    documentExpeditionCity: userData.expeditionCity,
    documentNumber: userData.personIdentNum,
    phone: userData.personPhone,
    birthdayDate: userData.personBirthDt,
    email: userEmail.credentialEmail,
    city: {
      cityId: userData.personCity.cityId,
      stateId: { stateId: userData.personCity.stateId.stateId }
    },
    country: {
      id: userData.personCity.stateId.stateId,
      name: userData.personCity.stateId.stateName
    }
  }
  setinitialValues(initValuesBody)
  setNewValues(initValuesBody)
}

export const getLocationValues = async ({
  citiesFetched,
  statesFetched,
  dataDispatch
}) => {
  let cities, states

  if (citiesFetched.length < 1) {
    cities = await getCities()
    dataDispatch({
      type: dataTypes.getCities,
      payload: cities
    })
  }

  if (statesFetched.length < 1) {
    states = await getStates()
    dataDispatch({
      type: dataTypes.getStates,
      payload: states
    })
  }
  // setLocationValues({ states: states, cities: cities, citiesFiltered: cities })
}

export const getDocTypesValues = async ({
  docTypesFetched,
  dataDispatch
}) => {
  let docTypes
  if (docTypesFetched.length < 1) {
    docTypes = await getDocTypes()
    dataDispatch({
      type: dataTypes.getDocTypes,
      payload: docTypes
    })
  }
}

export const getGendersValues = async ({
  gendersFetched,
  dataDispatch
}) => {
  let genders
  if (gendersFetched.length < 1) {
    genders = await getGenders()
    dataDispatch({
      type: dataTypes.getGenders,
      payload: genders
    })
  }
}

export const filterNewValues = (initValues, newValues) => {
  const keysToRequest = {
    name: 'personName',
    lastName: 'personLastName',
    documentTypeId: 'identDocName',
    gender: 'genderId',
    documentExpeditionCity: 'expeditionCity',
    documentNumber: 'personIdentNum',
    phone: 'personPhone',
    birthdayDate: 'personBirthDt',
    city: 'personCity'
  }

  const filteredValues = compareValues(initValues, newValues)

  const formattedValuesToRequest = filteredValues.map(([key, value]) => {
    const newKey = keysToRequest[key] || key
    return [newKey, value]
  })

  const valuesToRequest = Object.fromEntries(formattedValuesToRequest)

  return valuesToRequest
}

export const handleSubmit = async (
  idToken,
  newValues,
  initialValues,
  handleCancelModalClose,
  handleSaveModalShow,
  dataContext,
  setIsDisabled,
  setIncorrectInputFormat,
  incorrectInputFormat
) => {
  setIsDisabled(true)
  if (typeof newValues.city === typeof initialValues.city) {
    newValues.city = {
      cityId: newValues.city.cityId,
      stateId: { stateId: newValues.country.id }
    }
  } else {
    newValues.city = {
      cityId: newValues.city,
      stateId: { stateId: newValues.country }
    }
  }
  delete initialValues.country
  delete newValues.country
  const valuesToRequest = filterNewValues(initialValues, newValues)
  if (!valuesToRequest) return null
  const { email, personName, personLastName, expeditionCity, personIdentNum, personPhone } = valuesToRequest
  if (personName && personName.length < 5) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectUserName', nameMessage: 'El nombre es corto.' })
    return false
  }
  if (personLastName && personLastName.length < 5) {
    setIncorrectInputFormat({ ...incorrectInputFormat, lastNameFormat: 'employeeIncorrectUserName', lastNameMessage: 'El apellido es corto.' })
    return false
  }
  if (expeditionCity === '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.mandatoryField
    })
    return false
  }
  if (personIdentNum === '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentNumberFormat: 'employeeIncorrectFormat',
      documentNumberMessage: employeeString.mandatoryField
    })
    return false
  }
  if (personPhone === '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      phoneFormat: 'employeeIncorrectFormat',
      phoneMessage: employeeString.mandatoryField
    })
    return false
  }
  if (email === '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      emailFormat: 'employeeIncorrectFormat',
      emailMessage: employeeString.incorrectEmailFormat
    })
  }
  if (email) {
    await updateUserEmail(
      dataContext,
      idToken,
      initialValues.userId,
      valuesToRequest
    )
  }
  const responseUpdateUser = await updateUserData(
    dataContext,
    idToken,
    initialValues.personId,
    valuesToRequest
  )
  if (responseUpdateUser.response) {
    setIsDisabled(false)
    handleCancelModalClose()
    handleSaveModalShow()
  }
}

export const compareValues = (initValues, newValues) => {
  const modifiedValues = Object.entries(newValues)

  const filteredValues = modifiedValues.filter(([key, value]) => {
    if (key === 'city') {
      return !compareCityObject(initValues.city, value)
    } else {
      return key in initValues && initValues[key] !== value
    }
  })

  return filteredValues
}

const compareCityObject = (initCity, newCity) => {
  const validation =
    initCity.cityId === newCity.cityId &&
    initCity.stateId.stateId === newCity.stateId.stateId
  return validation
}

export const cancelEdit = (isDisabled, handleCancelModalShow, navigate) => {
  if (!isDisabled) handleCancelModalShow()
  else navigate(-1)
}
