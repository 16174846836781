import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { DataContext } from '../../context/DataContext'

export const PrivateRoute = ({ children }) => {
  const { dataState } = useContext(DataContext)
  const { logged } = dataState

  return (
    logged
      ? children
      : <Navigate to='/' />
  )
}
