import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import dbImage from '../../assets/icons/icon_bd-operativo.svg'
import arrowIcon from '../../assets/icons/icon_flecha_gris.svg'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import { onCompanyClick, onUploadFileChange } from './BulkUploadsFunctions'
import { useGetCompanies } from './hooks/useGetCompanies'

import './BulkUploads.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const BulkUploads = () => {
  const dataContext = useContext(DataContext)
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, users } = dataState.operative
  const { company } = users.massiveUploads

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')

  const navigate = useNavigate()
  const [isSponsorDropdownActive, setIsSponsorDropdownActive] = useState(false)
  const [dropdownTitle, setDropdownTitle] = useState(scriptsSave && scriptsSave.bulkUploadsString ? scriptsSave.bulkUploadsString.dropdownTitle : '')

  const { companies } = useGetCompanies(idToken, dataDispatch, dataContext)

  return (
    <section>
      <div className="bulkUploadMainContainer">
        <div className="bulkUploadsTopContent">
          <div className="bulkUploadsDropdown">
            <div
              className="bulkUploadsDropdownClosed"
              onClick={() =>
                setIsSponsorDropdownActive(!isSponsorDropdownActive)
              }
            >
              {dropdownTitle}
              <img
                src={arrowIcon}
                alt={scriptsSave && scriptsSave.bulkUploadsString ? scriptsSave.bulkUploadsString.dropdownAltImgText1 : ''}
              />
            </div>
            <div
              className={`bulkUploadsDropdownItemList ${isSponsorDropdownActive ? 'dropActive' : 'dropDisable'
                }`}
            >
              {
                companies.map((company) => (
                  <p
                    className="bulkUploadDropdownItem"
                    id={company.companyId}
                    key={company.companyId}
                    onClick={(e) => onCompanyClick(e, setIsSponsorDropdownActive, setDropdownTitle, dataDispatch)}
                  >
                    {company.companyComertialName}
                  </p>
                ))
              }
            </div>
          </div>
          <img
            className="bulkUploadDBImage"
            src={dbImage}
            alt={scriptsSave && scriptsSave.bulkUploadsString ? scriptsSave.bulkUploadsString.principalSectionAltImgText1 : ''}
          />
        </div>
        <div className={'bulkUploadSubContainer1'}>
          <p className="bulkUploadParagraph">
            {scriptsSave && scriptsSave.bulkUploadsString ? scriptsSave.bulkUploadsString.warningNote1 : ''}
          </p>
          <div className="bulkUploadButtonContainer">
            <p className="bulkUploadButtonParagraph">
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.userCharge : ''}
            </p>
            <input
              accept=".csv"
              className="bulkInput"
              id="bulkFile"
              onChange={(e) =>
                onUploadFileChange(
                  e,
                  navigate,
                  dataDispatch,
                  scriptsSave && scriptsSave.bulkUploadsString ? scriptsSave.bulkUploadsString.allowedExtensions : '',
                  setModalMessage,
                  handleShow
                )
              }
              required
              type="file"
            />
            {
              company !== ''
                ? (
                  <label className="bulkUploadButton" htmlFor="bulkFile">
                    {scriptsSave && scriptsSave.compString ? scriptsSave.compString.uploadXlsxFile : ''}
                  </label>
                  )
                : (
                  <p className="bulkUploadButtonDisabled">
                    {scriptsSave && scriptsSave.compString ? scriptsSave.compString.uploadXlsxFile : ''}
                  </p>
                  )
            }
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={handleClose}>
            {scriptsSave && scriptsSave.bulkUploadsString ? scriptsSave.bulkUploadsString.modalBtnText : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
