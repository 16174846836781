export const onPageClick = (
  setActivePage,
  paginationValues,
  setPaginationValues,
  number
) => {
  setActivePage(number)
  setPaginationValues({
    ...paginationValues,
    page: number
  })
}

export const onPrevBtnClick = (
  activePage,
  setActivePage,
  paginationValues,
  setPaginationValues,
  paginationPagesRef,
  paginationItemRef,
  setIsPrevActive
) => {
  setIsPrevActive(false)
  const parentWidth = paginationPagesRef.current.offsetWidth
  const parentBorderLeftDistance = paginationPagesRef.current.getBoundingClientRect().x
  const childBorderLeftDistance = paginationItemRef.current.getBoundingClientRect().x
  const distanceFromParentToChild = childBorderLeftDistance - parentBorderLeftDistance
  const distanceFromParentToChildPercentage = (distanceFromParentToChild / parentWidth) * 100

  // Obtenemos el ancho del viewport en píxeles
  const visibleWidthInPixels = window.innerWidth
  let scrollAmount = 0

  if (distanceFromParentToChildPercentage < 20) {
    // Convertimos 2.5vw a píxeles
    scrollAmount = (2.5 * visibleWidthInPixels) / 100
    paginationPagesRef.current.scrollLeft -= scrollAmount
  }

  if (activePage > 1) {
    setActivePage(activePage - 1)
    setPaginationValues({
      ...paginationValues,
      page: activePage - 1,
      scrollMeasurement: paginationPagesRef.current.scrollLeft
    })
  }
}

export const onNextBtnClick = (
  activePage,
  setActivePage,
  paginationValues,
  setPaginationValues,
  totalPages,
  paginationPagesRef,
  paginationItemRef,
  setIsNextActive
) => {
  setIsNextActive(false)
  const parentWidth = paginationPagesRef.current.offsetWidth
  const parentBorderLeftDistance = paginationPagesRef.current.getBoundingClientRect().x
  const childBorderLeftDistance = paginationItemRef.current.getBoundingClientRect().x
  const distanceFromParentToChild = childBorderLeftDistance - parentBorderLeftDistance
  const distanceFromParentToChildPercentage = (distanceFromParentToChild / parentWidth) * 100

  // // Obtenemos el ancho del viewport en píxeles
  const visibleWidthInPixels = window.innerWidth
  let scrollAmount = 0

  if (distanceFromParentToChildPercentage > 60) {
  //   // Convertimos 2.5vw a píxeles
    scrollAmount = (2.5 * visibleWidthInPixels) / 100
    paginationPagesRef.current.scrollLeft += scrollAmount
  }

  if (activePage < totalPages) {
    setActivePage(activePage + 1)
    setPaginationValues({
      ...paginationValues,
      page: activePage + 1,
      scrollMeasurement: paginationPagesRef.current.scrollLeft
    })
  }
}
