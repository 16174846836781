import { dataTypes } from '../../../../../../context/reducers/dataReducer/dataTypes'

const rolesMap = {
  Administrativo: 6,
  Operativo: 5,
  Abogado: 4
}

export const onChooseRoleClick = (role, dataDispatch) => {
  const payload = {
    role: rolesMap[role] || '',
    userProfile: role
  }
  const action = {
    type: dataTypes.roleIdToBeCreated,
    payload
  }

  dataDispatch(action)
}
