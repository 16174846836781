import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { capitalizeFirstLetter } from '../../utils/functions'
import { deleteEmployeeCredentialQuery, getOneEmployeeCredentialQuery } from '../../utils/userQueries'
import { activateRequestButton, handleRequestButton } from '../../utils/validateFunctions'

export const onEditEmployeeClick = async (
  employeeCredentialId,
  dataDispatch,
  idToken,
  dataContext,
  navigate) => {
  if (employeeCredentialId) {
    const getOneEmployeeCredentialResponse = await getOneEmployeeCredentialQuery(idToken, dataContext, employeeCredentialId)
    if (getOneEmployeeCredentialResponse.response) {
      const body = getOneEmployeeCredentialResponse.body
      const { employeeId } = body
      const { personId } = employeeId
      const action = {
        type: dataTypes.employeeToBeEdited,
        payload: {
          employeeCredentialId: body.employeeCredentialId,
          employeeCredentialEmail: body.employeeCredentialEmail,
          employeeId: {
            employeeId: employeeId.employeeId,
            personId: {
              expeditionCity: personId.expeditionCity,
              genderId: personId.genderId.genderType,
              identDocId: personId.identDocId.identDocId,
              personBirthDt: personId.personBirthDt,
              personCity: personId.personCity.cityId,
              personId: personId.personId,
              personIdentNum: personId.personIdentNum,
              personLastName: personId.personLastName,
              personName: personId.personName,
              personPhone: personId.personPhone
            }
          },
          roleName: capitalizeFirstLetter(getOneEmployeeCredentialResponse.body.employeeId.roleId.roleName)
        }
      }

      dataDispatch(action)
      navigate('DatosUsuario')
    }
  }
}

export const onDeleteEmployeeClick = (
  user,
  employeeCredentialEmail,
  dataDispatch,
  handleShowA
) => {
  if (user.employeeCredentialEmail !== employeeCredentialEmail) {
    const action = {
      type: dataTypes.employeeToBeEdited,
      payload: user
    }

    dataDispatch(action)
    handleShowA()
  }
}

export const onDeleteEmployeeConfirmationClick = async (
  employeeCredentialIdToDelete,
  idToken,
  dataDispatch,
  handleCloseA,
  handleShowB,
  isRequesting,
  setIsRequesting,
  dataContext
) => {
  if (isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)
  const deleteEmployeeCredentialResponse = await deleteEmployeeCredentialQuery(employeeCredentialIdToDelete, idToken, dataContext)
  if (deleteEmployeeCredentialResponse.body.code.includes('USER_DATA_DELETED')) {
    const action = {
      type: dataTypes.deleteEmployeeInEmployeesList,
      payload: employeeCredentialIdToDelete
    }

    dataDispatch(action)
    handleCloseA()
    handleShowB()
    activateRequestButton(setIsRequesting, timeOutId)
  }
}

export const onDetailsButtonClick = (dataBase, dataDispatch) => {
  const action = {
    type: dataTypes.setDatabaseToBeReviewed,
    payload: dataBase
  }

  dataDispatch(action)
}
