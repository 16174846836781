import Papa from 'papaparse'

export const parseCSV = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const result = reader.result
      Papa.parse(result, {
        header: true,
        complete: (parsedData) => {
          const jsonRecords = processRecords(parsedData.data)
          resolve(jsonRecords)
        },
        error: (error) => {
          reject(error)
        }
      })
    }
    reader.readAsText(file)
  })
}

const processRecords = (records) => {
  const validRecords = []

  records.forEach((record) => {
    if (isValidRecord(record)) {
      validRecords.push(record)
    }
  })
  return validRecords
}

const isValidRecord = (record) => {
  const { sponsorCode, documentNumber } = record

  const sponsorCodeRegex = /^[A-Z]{3}\d{7}$/
  const isValidSponsorCode =
    typeof sponsorCode === 'string' && sponsorCodeRegex.test(sponsorCode)

  const isValidDocumentNumber =
    typeof documentNumber === 'string' && /^\d{6,10}$/.test(documentNumber)
  return isValidSponsorCode && isValidDocumentNumber
}
