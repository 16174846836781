import React from 'react'
import { Outlet } from 'react-router-dom'
import './UserAdminLayout.css'

export const UserAdminLayout = () => {
  return (
    <section id='UserAdmonLayout'>
        <Outlet/>
    </section>
  )
}
