import React, { useContext, useEffect } from 'react'
import { LawyersDetailedDescription } from '../../../../components/LawyersDetailedDescription/LawyersDetailedDescription'
import { EditNotAllowedContactRow } from './components/EditNotAllowedContactRow'
import { NavFooter } from '../../../../components/NavFooter/NavFooter'

import './EditNotAllowedContact.css'
import { NavLink, useLocation } from 'react-router-dom'
import { DataContext } from '../../../../context/DataContext'
import { getNotAllowedContactDetail } from '../../../../utils/userQueries'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const EditNotAllowedContact = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { state } = useLocation()
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, notAllowedContact } = dataState.lawyer
  const { notAllowedContactDetail } = notAllowedContact

  useEffect(() => {
    const fetchData = async () => {
      const queryResponse = await getNotAllowedContactDetail(
        idToken,
        dataContext,
        state.requestId
      )
      if (queryResponse.response) {
        const contentFormatted = formatContent(queryResponse.body.content)
        const documentPathFormatted = JSON.parse(
          queryResponse.body.documentPath
        )
        const bodyToSet = {
          ...queryResponse.body,
          content: contentFormatted,
          documentPath: documentPathFormatted
        }
        const action = {
          type: dataTypes.getNotAllowedContactDetail,
          payload: {
            notAllowedContactDetail: { ...bodyToSet, ...state }
          }
        }

        dataDispatch(action)
      }
    }

    fetchData()
  }, [])

  const formatContent = (content) => {
    const contentFormatted = {}
    let lines = content.split('\\n')
    if (!lines[1]) lines = content.split('\n')
    lines.forEach((line) => {
      const [key, value] = line.split(':')
      const cleanKey = key.trim().toLowerCase().replace(/\s/g, '')
      const cleanValue = value.trim()

      contentFormatted[cleanKey] = cleanValue
    })

    return contentFormatted
  }

  return (
    <>
      <LawyersDetailedDescription
        description={notAllowedContactDetail}
        entity={state.claimTo}
      >
        <table className="editNotAllowedContactTable">
          <thead className="editNotAllowedContactThead">
            <tr className="editNotAllowedContactRowHead">
              <th className="editNotAllowedContactCellHead">
                {scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.files : ''}
              </th>
              <th className="editNotAllowedContactCellHead textEnd">
                {scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.details : ''}
              </th>
            </tr>
          </thead>
          <tbody className="editNotAllowedContactTbody">
            {
              !notAllowedContactDetail?.documentPath
                ? <h2 className='notAttachmentsFoundMessage'>{scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.notAttachments : ''}</h2>
                : notAllowedContactDetail?.documentPath?.map((item, index) => (
                  <EditNotAllowedContactRow
                    key={index}
                    name={item.fileName}
                    path={item.path}
                  />
                ))
            }
          </tbody>
        </table>
      </LawyersDetailedDescription>
      <NavFooter returnPath='..'>
        <NavLink to="../Rechazar" state={notAllowedContactDetail}>
          <button className="navFooterButton navFooterButton1 rejectButton">
            {scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.reject : ''}
          </button>
        </NavLink>
        <NavLink to="../Proceder" state={notAllowedContactDetail}>
          <button className="navFooterButton navFooterButton2">
            {scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.proceed : ''}
          </button>
        </NavLink>
      </NavFooter>
    </>
  )
}
