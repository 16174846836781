import { validateFunctionsString } from './strings'

export const handleRequestButton = (setIsRequesting, timeOutId) => {
  clearTimeout(timeOutId)
  setIsRequesting(true)

  const id = setTimeout(() => {
    setIsRequesting(false)
  }, 10000)
  return id
}

export const activateRequestButton = (setIsRequesting, timeOutId) => {
  clearTimeout(timeOutId)
  setIsRequesting(false)
}

export const maxSize = (e, field = 'other') => {
  let length = null
  field === 'password'
    ? (length = 16)
    : field === 'email' || field === 'flName'
      ? (length = 48)
      : field === 'compName'
        ? (length = 70)
        : field === 'long'
          ? (length = 480)
          : field === 'nit'
            ? (length = 9)
            : field === 'cc'
              ? (length = 10)
              : (length = 10)
  if (e.target.value.length > length) {
    e.target.value = e.target.value.slice(0, length)
  }
}

export const validateEmailFormat = (email) => {
  const regEx = /^[0-9a-zA-Z_.-]{6,30}@[0-9a-zA-Z-]{3,}(\.[0-9a-zA-Z]{2,})+$/
  return regEx.test(email)
}

export const validatePassFormat = (text) => {
  const allowedSpecialChars = '!#$%&*,.:?@[]^_{|}~'
  const allowedChars = `a-zA-Z\\d${allowedSpecialChars.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`
  const regExp = new RegExp(`^(?=.*\\d)(?=.*[${allowedSpecialChars.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}])(?=.*[a-z])(?=.*[A-Z]).{8,}$`)
  const allowedCharsRegExp = new RegExp(`^[${allowedChars}]+$`)
  return regExp.test(text) && allowedCharsRegExp.test(text)
}

export const validateNames = (text) => {
  const regEx = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ]+(?: [a-zA-ZáéíóúüÁÉÍÓÚÜñÑ]+)*$/
  if ((text[text.length - 2] === ' ' && text[text.length - 1] === ' ')) {
    return false
  }
  return regEx.test(text.trim())
}

export const validateAddress = (text) => {
  const regEx = /^[A-Za-z0-9\s#áéíóúÁÉÍÓÚüÜ.,()-]+$/u
  return regEx.test(text)
}

export const validateCompanyName = (text) => {
  const regEx = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ,0-9\s]+$/u
  return regEx.test(text)
}

export const validateBeginWithZero = (text) => {
  const regEx = /^0/
  return regEx.test(text)
}

export const validateOnlyIntegersIncludingZero = (text) => {
  const regEx = /^[0-9]*$/
  return regEx.test(text)
}

export const validateCellphone = (e) => {
  let phoneStr = e.target.value
  const regEx = /^(|([1-9]\d*))$/

  if (regEx.test(phoneStr)) {
    if (phoneStr.startsWith('3') || phoneStr.length === 0) {
      phoneStr = phoneStr.slice(0, 10)
      return phoneStr
    }
  }
}

export const validateNoWhiteSpacesAtBeginning = (text) => {
  if (
    text === ' ' ||
    text === '  ' ||
    text === '  ' ||
    text === '   ' ||
    text === '   '
  ) {
    return false
  }
  const regEx = /^(\s*|[^\s].*)$/
  return regEx.test(text)
}

export const hasRepeatedCharactersAtMiddle = (text) => {
  if (text.length > 0) {
    let firstChar = null
    let secondChar = null
    let thirdChar = null
    let fourthChar = null
    for (let i = 0; i < text.length; i++) {
      firstChar = text[i]
      secondChar = text[i + 1]
      thirdChar = text[i + 2]
      fourthChar = text[i + 3]
      if (
        firstChar === '.' &&
        secondChar === '.' &&
        thirdChar === '.' &&
        fourthChar === '.'
      ) {
        return true
      }
      if (firstChar === ' ' && secondChar === ' ' && thirdChar === ' ') {
        return true
      }
      if (
        firstChar !== '.' &&
        firstChar === secondChar &&
        firstChar === thirdChar
      ) {
        return true
      }
    }
    return false
  }
}

export const fileExtension = (
  e,
  message,
  setModalMessage,
  handleShow,
  setFileName = null,
  setFile = null
) => {
  setModalMessage('')

  const files = e.target.files
  const acceptAttributeWithoutSpaces = e.target.accept.replace(/\s/g, '')
  const acceptAttributeArray = acceptAttributeWithoutSpaces.split(',')
  const allowedExtensions = acceptAttributeArray.map((extension) =>
    extension.substring(1)
  )

  const maxSizePerMediaFile = 20971520 // 20971520 Bytes or 20 MB
  const maxSizePerStaticFile = 5242880 // 5242880 Bytes or 5 MB

  const emptyFilesField = (notAllowedSize = false) => {
    if (setFileName) setFileName(null)
    if (setFile) setFile(null)

    const filesArray = Array.from(e.target.files)
    filesArray.splice(0, 1)
    const newFileList = new DataTransfer()
    filesArray.forEach((file) => {
      newFileList.items.add(file)
    })
    e.target.files = newFileList.files

    if (notAllowedSize) {
      setModalMessage(
        `${validateFunctionsString.maxFileExceededPart1} ${(
          size /
          (1024 * 1024)
        ).toFixed(2)} ${validateFunctionsString.measurementUnits} ${validateFunctionsString.maxFileExceededPart2
        } ${maxSizePerMediaFile / (1024 * 1024)} ${validateFunctionsString.measurementUnits
        } ${validateFunctionsString.maxFileExceededPart3} ${maxSizePerStaticFile / (1024 * 1024)
        } ${validateFunctionsString.measurementUnits}.`
      )
    } else {
      setModalMessage(`${message}${allowedExtensions.map(item => {
        if (item !== 'jpeg') {
          return ' ' + item
        }
        return null
      }).filter(item => item !== null)}`)
    }
    handleShow()
  }

  if (files.length === 0) {
    emptyFilesField()
    return false
  }

  const file = files[0]
  const { type, size } = file
  const fileExtension = type.split('/')[1]
  const fileType = type.split('/')[0]
  if (!allowedExtensions.includes(fileExtension)) {
    emptyFilesField()
    return false
  } else {
    if (fileType.includes('text')) {
      // if (size > maxSizePerStaticFile) {
      //   emptyFilesField(true)
      //   return false
      // }
    }
    if (fileType.includes('audio') || fileType.includes('video')) {
      if (size > maxSizePerMediaFile) {
        emptyFilesField(true)
        return false
      }
    }
    if (fileType.includes('image') || fileType.includes('application')) {
      if (size > maxSizePerStaticFile) {
        emptyFilesField(true)
        return false
      }
    }
  }

  if (setFileName) setFileName(file.name)
  if (setFile) setFile(file)
  return true
}
