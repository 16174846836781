import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { parseCSV } from '../../utils/csvUtils'
import { fileExtension } from '../../utils/validateFunctions'

export const onUploadFileChange = async (
  e,
  navigate,
  dataDispatch,
  message,
  setModalMessage,
  handleShow
) => {
  const isAValidExtension = fileExtension(
    e,
    message,
    setModalMessage,
    handleShow
  )

  if (!isAValidExtension) return

  const file = e.target.files[0]
  const objCsv = await parseCSV(file)

  if (objCsv && objCsv?.length) {
    const action = {
      type: dataTypes.setCsvDataOnMassiveUploads,
      payload: objCsv
    }
    dataDispatch(action)

    navigate('CargaUsuarios')
  }
}

export const onCompanyClick = (e, setIsSponsorDropdownActive, setDropdownTitle, dataDispatch) => {
  setIsSponsorDropdownActive(false)

  const companyName = e.target.firstChild.textContent
  setDropdownTitle(companyName)

  const companyId = e.target.id
  const action = {
    type: dataTypes.setCompanyOnMassiveUploads,
    payload: companyId
  }
  dataDispatch(action)
}
