import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useDataReducer } from './reducers/dataReducer/useDataReducer'
import { emptySession } from '../utils/objects'
import { dataTypes } from './reducers/dataReducer/dataTypes'
import { useModal } from '../hooks/useModal'
import { Modal } from '../components/Modal/Modal'
import { removeData } from '../views/BaseAdministrativo/BaseAdministrativoFunctions'
import { dataContextStrings } from '../utils/strings'
import { disableForwardAndBackwardNavigation } from '../utils/functions'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const dataReducerValues = useDataReducer()
  const { dataDispatch } = dataReducerValues
  const [infoSaved, setInfoSaved] = useState('Holi')

  const { pathname } = useLocation()

  useEffect(() => {
    if (!pathname.includes('DB')) {
      const action = {
        type: dataTypes.updateDataBases,
        payload: emptySession.administrative.dataBases
      }
      dataDispatch(action)
    }
  }, [pathname])

  const [showModal, handleShowInactiveSessionModal, handleClose] = useModal()

  const [activePage] = useState(false)
  // Redirect to landing page if user refreshes any page on the app
  // ---------------------------------------------------------------
  const navigate = useNavigate()
  useEffect(() => {
    if (!activePage) {
      navigate('/')
    }
  }, [activePage])
  // ---------------------------------------------------------------

  const closeInactiveSessionModal = () => {
    removeData({ navigate, dataDispatch: dataReducerValues.dataDispatch })
    handleClose()
  }

  disableForwardAndBackwardNavigation()

  return (
    <DataContext.Provider value={{ ...dataReducerValues, navigate, handleShowInactiveSessionModal, setInfoSaved, infoSaved }}>
      {children}
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>
            {dataContextStrings.expiredSessionMessage}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={closeInactiveSessionModal} className='Modal-greenBtn'>
            {dataContextStrings.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </DataContext.Provider>
  )
}
