import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../../../context/DataContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import checkIcon from '../../../../assets/icons/icon_bien.svg'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { useFetchPaymentPerUser } from './hooks/useFetchPaymentPerUser'
import { formatDateDDMMYYMonthInLetters } from '../../../../utils/functions'
import {
  onAcceptClick,
  onContinueModalBtnClick,
  onRejectClick
} from './MovimientosTuDataControlFunctions'

import './MovimientosTuDataControl.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const MovimientosTuDataControl = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  // const { dataState } = dataContext
  // const { idToken } = dataState.operative

  const {
    // companyId,
    // userId,
    name,
    email,
    logo,
    companyName,
    nit,
    paymentDate,
    paymentId,
    type
  } = useFetchPaymentPerUser()

  const [modalMessage, setModalMessage] = useState('')
  const [showModal, handleShow, handleClose] = useModal()
  const [autorize, setAutorize] = useState(true)
  const navigate = useNavigate()

  return (
    <section id="MovimientosTuDataControl">
      <div className="MovimientosTuDataControl-tside">
        <div className="MovimientosTuDataControl-tside-lside">
          <div className="MovimientosTuDataControl-tside-lside-header">
            <p>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.dataUsers : ''}</p>
            <p>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.status : ''}</p>
          </div>
          <div className="MovimientosTuDataControl-tside-lside-info">
            <div>
              <p className="MovimientosTuDataControl-name">
                {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.name : ''}
              </p>
              <p className="MovimientosTuDataControl-value">{name}</p>
            </div>
            <div>
              <span>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.payDone : ''}</span>
              <img
                src={checkIcon}
                alt={scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.altImageTxt1 : ''}
              />
            </div>
            <div>
              <p className="MovimientosTuDataControl-name">
                {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.email : ''}
              </p>
              <p className="MovimientosTuDataControl-value">{email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="MovimientosTuDataControl-bside">
        <h2>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.blockQuery : ''}</h2>
        <table>
          <thead>
            {type === 'compra'
              ? (
            <>
            <th>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead1 : ''}</th>
            <th>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead2 : ''}</th>
            <th>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead3 : ''}</th>
            <th className="MovimientosTuDataControl-th3">
              {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead4 : ''}
            </th>
            </>
                )
              : (
            <>
              <th>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead5 : ''}</th>
              <th>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead6 : ''}</th>
              <th className="MovimientosTuDataControl-th3">
                {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.thead7 : ''}
              </th>
            </>
                )}
          </thead>
          <tbody>
          {type === 'compra'
            ? (
            <tr>
              <td>
                <div className="MovimientosTuDataControl-bside-companyCard">
                  <img
                    src={logo}
                    alt={scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.altImageTxt2 : ''}
                  />
                  <p>{companyName}</p>
                </div>
              </td>
              <td>
                <span>{nit}</span>
              </td>
              <td>
                <p>{formatDateDDMMYYMonthInLetters(paymentDate)}</p>
              </td>
              <td>
                <p>{paymentId}</p>
              </td>
            </tr>
              )
            : (
            <tr>
              <td>
                <span>{companyName}</span>
              </td>
              <td>
                <p>{formatDateDDMMYYMonthInLetters(paymentDate)}</p>
              </td>
              <td>
                <p>{paymentId}</p>
              </td>
            </tr>
              )}
          </tbody>
        </table>
      </div>
      <div className="MovimientosTuDataControl-comments">
        <div className="MovimientosTuDataControl-comments-btnbox">
          <button onClick={() => {
            setAutorize(true)
            onAcceptClick(setModalMessage, handleShow, scriptsSave && scriptsSave.movementsControlString)
          }}>
            {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.accept : ''}
          </button>
          <button onClick={() => {
            setAutorize(false)
            onRejectClick(setModalMessage, handleShow, scriptsSave && scriptsSave.movementsControlString)
          }}>
            {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.reject : ''}
          </button>
        </div>
      </div>
      <div className="MovimientosTuDataControl-footer">
        <button
          onClick={() => navigate('../PagosTuDataControl')}
          className="MovimientosTuDataControl-footer-backLink"
        >
          <img
            src={backIcon}
            alt={scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.altImageTxt3 : ''}
          />
          <span>{scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.back : ''}</span>
        </button>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>
            {modalMessage}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className="Modal-normalBtn"
            onClick={handleClose}
          >
            {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={() => onContinueModalBtnClick(autorize, paymentId, dataContext)}
          >
            {scriptsSave && scriptsSave.movementsControlString ? scriptsSave.movementsControlString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
