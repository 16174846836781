import React from 'react'
import './Aside.css'

export const Aside = ({ isOpen, children }) => {
  if (!isOpen) {
    return null
  }
  return (
        <section
          className={`AsideBackground ${!isOpen ? 'AsideClose' : 'AsideOpen'}`}
        >
          <div className="AsideBody">{children}</div>
        </section>
  )
}

const Content = ({ children }) => {
  return <div className="AsideBody-content">{children}</div>
}

const Buttons = ({ children }) => {
  return <div className="AsideBody-buttons">{children}</div>
}

Aside.Content = Content
Aside.Buttons = Buttons
