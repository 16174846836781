import React, { useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ScriptContext } from '../../../context/ScriptsContext'
import './DataBaseLayout.css'

export const DataBaseLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const location = useLocation()
  return (
    <section className="dataBaseLayoutContainer">
      <div className="listaDBHeaderContainer">
        <p className={'ListaDBHeaderParagraph'}>
          {location.pathname.includes('DetalleDB') &&
          location.pathname.endsWith('DB')
            ? `${scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.dataBaseDetails : ''}`
            : location.pathname.endsWith('DB')
              ? `${scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.dataBases : ''}`
              : null}
        </p>
      </div>
      <hr className="hrListaDB" />
      <Outlet />
    </section>
  )
}
