import { useEffect, useState } from 'react'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { getCaseTrackingEntityDetail, getEntityWithoutContactChannelDetail } from '../../../../utils/userQueries'

export const fetchCaseTrackingEntityDetail = (dataContext, requestData, view) => {
  const { dataState, dataDispatch } = dataContext
  const { idToken } = dataState.lawyer
  const [bodyData, setbodyData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (view === 'caseTrackingDetail') {
        const queryResponse = await getCaseTrackingEntityDetail(dataContext, idToken, requestData)
        if (queryResponse.response) {
          const data = queryResponse.body
          const bodyEntityData = {
            name: data.company?.companyBussinesName,
            legalName: data.buyer?.buyerLegalRepresentative,
            contactName: data.buyer?.buyerContactName,
            email: data.company?.companyEmail,
            nit: data.company?.companyNit,
            phone: data.company?.companyPhone,
            logo: data.company?.companyLogoPath,
            demands: data.demands,
            category: data.company?.categoryCompany.categoryCompanyId
          }
          setbodyData(bodyEntityData)
        }
      } else {
        const queryResponse = await getEntityWithoutContactChannelDetail(dataContext, idToken, requestData)
        if (queryResponse.response) {
          const data = queryResponse.body
          const bodyEntityData = {
            name: data.companyId?.companyBussinesName,
            nit: data.companyId?.companyNit,
            logo: data.companyId?.companyLogoPath,
            userId: data.userId?.userId,
            demandId: data.demandId
          }
          setbodyData(bodyEntityData)
        }
      }
    }
    // if (caseTrackingEntityDetail.name !== requestData.name && requestData.name) fetchData()
    fetchData()
  }, [])

  useEffect(() => {
    if (view === 'caseTrackingDetail') {
      const action = {
        type: dataTypes.getCaseTrackingEntityDetail,
        payload: {
          caseTrackingEntityDetail: bodyData
        }
      }
      dataDispatch(action)
    } else {
      const action = {
        type: dataTypes.getEntityWithoutChannelContactDetail,
        payload: {
          entityWithoutChannelContactDetail: bodyData
        }
      }
      dataDispatch(action)
    }
  }, [bodyData])
}
