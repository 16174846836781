import { patchDeleteUser, updateUserData } from '../../utils/userQueries'

export const onReactivateAccountClick = async (
  operativeUsersAdminDetailsStrings,
  dataContext,
  idToken,
  personId,
  handleShow,
  setModalMessage
) => {
  const requestBody = {
    personActive: true
  }
  const updateUserDataResponse = await updateUserData(dataContext, idToken, personId, requestBody)

  if (updateUserDataResponse.response) {
    setModalMessage(operativeUsersAdminDetailsStrings.modalMessage1)
    handleShow()
  }
}

export const onModalButtonClick = (handleClose, navigate) => {
  handleClose()
  navigate('../')
}

export const onDeleteDataClick = async (
  operativeUsersAdminDetailsStrings,
  dataContext,
  credentialId,
  idToken,
  email,
  setModalMessage,
  handleShow
) => {
  const queryBody = {
    email
  }
  const patchDeleteUserResponse = await patchDeleteUser(dataContext, credentialId, queryBody, idToken)
  if (patchDeleteUserResponse.response) {
    setModalMessage(operativeUsersAdminDetailsStrings.modalMessage2)
    handleShow()
  }
}
