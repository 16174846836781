import React, { useContext } from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import './CompaniesLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const CompaniesLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <div className='companiesLayout'>
      <div className="companiesLayoutHeaderContainer">
        <div>
          <NavLink
            to=''
            className={({ isActive }) =>
              isActive ? 'activeHeader' : 'companiesLayoutItem'
            }
            end
          >
            <p className="companiesLayoutParagraph">
              {scriptsSave && scriptsSave.companiesLayoutStrings ? scriptsSave.companiesLayoutStrings.headerItem1 : ''}
            </p>
          </NavLink>
          <NavLink
            to='CargasMasivas'
            className={({ isActive }) =>
              isActive ? 'activeHeader' : 'companiesLayoutItem'
            }>
              <p className='companiesLayoutParagraph'>
                {scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.bulkUpload : ''}
              </p>
          </NavLink>
        </div>
      </div>
      <hr className="hrUsersPanel" />
      <Outlet />
    </div>
  )
}
