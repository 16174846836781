import { slashedFormatDateDDMMYYYY } from '../../../utils/functions'
import { employeeString } from '../../../utils/strings'
import { getEmployeeEmailExists, getEmployeePhoneOrIDExists } from '../../../utils/userQueries'
import {
  hasRepeatedCharactersAtMiddle,
  validateAddress,
  validateBeginWithZero,
  validateCellphone,
  validateEmailFormat,
  validateNames,
  validateNoWhiteSpacesAtBeginning,
  validateOnlyIntegersIncludingZero,
  validatePassFormat
} from '../../../utils/validateFunctions'

// --------------------------------------------------------------------------------------------
// Inputs methods in CreateNewUser.jsx and EditUser.jsx
export const onNameChange = (e, formValues, setFormValues) => {
  const name = e.target.value.replace(/\s+/g, ' ')
  // if (validateNames(name)) {
  setFormValues({ ...formValues, name })
  // }
}

export const onIsValidNameBlur = (formValues, incorrectInputFormat, setIncorrectInputFormat) => {
  const { name } = formValues
  if (!name) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectFormat' })
    return false
  } else if (name.length < 5) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectUserName', nameMessage: 'El nombre es corto.' })
    return false
  } else if (name.startsWith(' ')) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectUserName', nameMessage: 'El nombre no puede comenzar con espacio.' })
    return false
  } else {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeRightFormat' })
    return true
  }
}

export const onLastNameChange = (e, formValues, setFormValues) => {
  const lastName = e.target.value.replace(/\s+/g, ' ')
  if (validateNames(lastName)) {
    setFormValues({ ...formValues, lastName })
  }
}

export const onIsValidLastNameBlur = (formValues, incorrectInputFormat, setIncorrectInputFormat) => {
  const { lastName } = formValues
  if (!lastName) {
    setIncorrectInputFormat({ ...incorrectInputFormat, lastNameFormat: 'employeeIncorrectFormat' })
    return false
  } else if (lastName.length < 5) {
    setIncorrectInputFormat({ ...incorrectInputFormat, lastNameFormat: 'employeeIncorrectUserName', lastNameMessage: 'El apellido es corto.' })
    return false
  } else if (lastName.startsWith(' ')) {
    setIncorrectInputFormat({ ...incorrectInputFormat, lastNameFormat: 'employeeIncorrectUserName', lastNameMessage: 'El apellido no puede comenzar con espacio.' })
    return false
  } else {
    setIncorrectInputFormat({ ...incorrectInputFormat, lastNameFormat: 'employeeRightFormat' })
    return true
  }
}

export const onFormInputChange = (e, formValues, setFormValues, validateFunction, incorrectInputFormat, setIncorrectInputFormat) => {
  const element = e.target.value
  if (validateNoWhiteSpacesAtBeginning(element)) {
    if (hasRepeatedCharactersAtMiddle(element)) {
      return
    }
    setFormValues({ ...formValues, [e.target.name]: element.trimStart() })
  }
  setIncorrectInputFormat({ ...incorrectInputFormat, comertialNameformat: 'editCompanyFormItemInput' })
  if (validateFunction(element)) {
    setFormValues({ ...formValues, [e.target.name]: element.trimStart() })
  }
}

export const onCreateEmailChange = (e, formValues, setFormValues, validateFunction, incorrectInputFormat, setIncorrectInputFormat) => {
  const element = e.target.value.replace(/\s+/g, '')
  // console.log(validateFunction(element))
  // setIncorrectInputFormat({ ...incorrectInputFormat, comertialNameformat: 'editCompanyFormItemInput' })
  // if (validateFunction(element)) {
  setFormValues({ ...formValues, [e.target.name]: element.trimStart() })
  // }
}

export const onEditEmailChange = (e, formValues, setFormValues) => {
  const element = e.target.value.replace(/\s+/g, '')
  setFormValues({ ...formValues, [e.target.name]: element })
}

// export const onDirectRemitentChange = (e, createValues, setCreateValues) => {
//   setCreateValues(prev => ({ ...prev, [e.target.name]: !createValues.directRemitent }))
// }

export const onInputChange = (e, formValues, setFormValues) => {
  if (validateNames(e.target.value)) {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }
}

export const onNitChange = (e, formValues, setFormValues) => {
  const value = e.target.value
  const regEx = /^(|([1-9]\d*))$/
  if (!regEx.test(value)) {
    return null
  } else {
    setFormValues({ ...formValues, [e.target.name]: value })
  }
}

export const onIsValidInputBlur = (e, formValues, incorrectInputFormat, setIncorrectInputFormat) => {
  const formItem = e.target.name
  const value = formValues[formItem]
  if (!value) {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeIncorrectFormat' })
    return false
  } else {
    setIncorrectInputFormat({ ...incorrectInputFormat, nameFormat: 'employeeRightFormat' })
    return true
  }
}

export const onIsValidEmailBlur = async (
  dataContext,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  idToken,
  initialFormValues
) => {
  const { email } = formValues
  let emailFormat = 'employeeRightFormat'
  let emailMessage = ''
  let valueToReturn = true

  if (!email) {
    emailFormat = 'employeeIncorrectFormat'
    emailMessage = employeeString.mandatoryField
    valueToReturn = false
  } else if (!validateEmailFormat(email)) {
    emailFormat = 'employeeIncorrectFormat'
    emailMessage = employeeString.incorrectEmailFormat
    valueToReturn = false
  } else if (email !== initialFormValues?.email) {
    const getEmployeeEmailExistsResponse = await getEmployeeEmailExists(dataContext, email, idToken)
    if (getEmployeeEmailExistsResponse.response) {
      emailFormat = 'employeeIncorrectFormat'
      emailMessage = employeeString.emailAlreadyExists
      valueToReturn = false
      setFormValues({ ...formValues, email: '' })
    }
  }

  setIncorrectInputFormat({ ...incorrectInputFormat, emailFormat, emailMessage })
  return valueToReturn
}

export const onEmailChange = (e, formValues, setFormValues) => {
  const email = e.target.value.replace(/\s+/g, '')
  setFormValues({ ...formValues, email })
}

export const validateInputOnBlur = async (
  e,
  inputValues,
  setValidateInputs,
  validateFunction,
  validateProps,
  originalValue = null,
  validateInputFormatFn
) => {
  const validateParameters = Object.values(validateProps)

  if (originalValue) {
    // Si lo que escribió el usuario es igual a lo que viene en el contexto, entonces salir de la función (Aplica para edición de empresa únicamente)
    if (e.target.name === 'phone') {
      if (originalValue === validateParameters[1].phone) {
        setValidateInputs({ ...inputValues, [e.target.name]: false })
        return
      }
    }
    if (originalValue === validateParameters[1]) {
      setValidateInputs({ ...inputValues, [e.target.name]: false })
      return
    }
  }
  if (e.target.name === 'email') {
    if (!validateInputFormatFn(e.target.value)) {
      setValidateInputs({ ...inputValues, [e.target.name]: 'NOT_VALID' })
      return
    }
    setValidateInputs({ ...inputValues, [e.target.name]: false })
  }

  if (e.target.value === '') {
    setValidateInputs({ ...inputValues, [e.target.name]: 'EMPTY' })
    return
  }
  const responseValidate = await validateFunction(...validateParameters)

  const emailOrNitValidated = responseValidate.value || responseValidate.data?.code.includes('DATA_NOT_FOUND') || responseValidate.data?.code.includes('PHONE_NOT_FOUND')
  if (emailOrNitValidated) {
    if (responseValidate.response) setValidateInputs({ ...inputValues, [e.target.name]: true })
    else setValidateInputs({ ...inputValues, [e.target.name]: false })
  } else {
    if (!responseValidate.response) setValidateInputs({ ...inputValues, [e.target.name]: true })
    else setValidateInputs({ ...inputValues, [e.target.name]: false })
  }
}

export const onEditEmailBlur = async (e, inputValues, setValidateInputs, validateFunction, validateProps, originalValue = null) => {
  const validateParameters = Object.values(validateProps)

  if (originalValue) {
    // Si lo que escribió el usuario es igual a lo que viene en el contexto, entonces salir de la función (Aplica para edición de empresa únicamente)
    if (originalValue === validateParameters[1]) {
      setValidateInputs({ ...inputValues, [e.target.name]: false })
      return
    }
  }
  let responseValidate = null
  let emailValidated = false

  if (validateEmailFormat(validateParameters[1])) {
    responseValidate = await validateFunction(...validateParameters)
    emailValidated = responseValidate.value || responseValidate.data?.code.includes('DATA_NOT_FOUND')
  }

  if (emailValidated) {
    if (responseValidate.response) setValidateInputs({ ...inputValues, [e.target.name]: true })
    else setValidateInputs({ ...inputValues, [e.target.name]: false })
  } else {
    if (!responseValidate?.response) setValidateInputs({ ...inputValues, [e.target.name]: true })
    else setValidateInputs({ ...inputValues, [e.target.name]: false })
  }
}

export const onAddressChange = (e, formValues, setFormValues) => {
  if (validateAddress(e.target.value)) {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }
}

export const onCountryChange = (e, formValues, setFormValues) => {
  const country = e.target.value.replace(/\s+/g, ' ')
  setFormValues({ ...formValues, country: country.trimStart() })
}

export const onIsValidCountryBlur = (
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  countriesFetched
) => {
  const { country } = formValues
  if (!country) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      countryFormat: 'employeeIncorrectFormat',
      countryMessage: employeeString.mandatoryField
    })
    return false
  }

  setIncorrectInputFormat({ ...incorrectInputFormat, countryFormat: 'employeeRightFormat' })

  // Documentación: Los métodos .normalize('NFD').replace(/[\u0300-\u036f]/g, '') se utilizan para quitar tildes de una cadena de caracteres
  const countryFound = countriesFetched.find(
    (e) =>
      e.countryName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim() ===
      country
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
  )

  if (countryFound) {
    setFormValues({ ...formValues, country: countryFound.countryName })
    return true
  } else if (!countryFound && country !== '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      countryFormat: 'employeeIncorrectFormat',
      countryMessage: employeeString.incorrectCountry
    })
    setFormValues({ ...formValues, country: '' })
    return false
  }
}

export const onCityChange = (e, formValues, setFormValues) => {
  const city = e.target.value.replace(/\s+/g, ' ')
  setFormValues({ ...formValues, city: city.trimStart() })
}

export const onIsValidCityBlur = (
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  citiesFetched
) => {
  const { city } = formValues
  if (!city) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      cityFormat: 'employeeIncorrectFormat',
      cityMessage: employeeString.mandatoryField
    })
    return false
  }

  setIncorrectInputFormat({ ...incorrectInputFormat, cityFormat: 'employeeRightFormat' })
  // Documentación: Los métodos .normalize('NFD').replace(/[\u0300-\u036f]/g, '') se utilizan para quitar tildes de una cadena de caracteres
  let cityFound = citiesFetched.find(
    (e) =>
      e.cityName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim() ===
      city
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
  )

  if (!cityFound) {
    cityFound = citiesFetched.find(
      (e) =>
        e.cityName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim() ===
        (
          (city
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .includes(
              'Bogotá'
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim()
            ))
            ? 'Bogotá D.C.'
            : ''
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim()
    )

    if (cityFound) {
      setFormValues({ ...formValues, city: cityFound.cityName, cityId: cityFound.cityId.toString() })
      return true
    }
  }

  if (cityFound) {
    setFormValues({ ...formValues, city: cityFound.cityName, cityId: cityFound.cityId.toString() })
    return true
  } else if (!cityFound && city !== '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      cityFormat: 'employeeIncorrectFormat',
      cityMessage: employeeString.incorrectCity
    })
    setFormValues({ ...formValues, city: '', cityId: '' })
    return false
  }
}

export const onPasswordChange = (
  e,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat
) => {
  const password = e.target.value
  setFormValues({ ...formValues, password })

  const { passwordConfirmation } = formValues

  if (password === passwordConfirmation) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      passwordConfirmationFormat: 'employeeRightFormat',
      passwordConfirmationMessage: ''
    })
  }
}

export const onIsValidPasswordBlur = (formValues, incorrectInputFormat, setIncorrectInputFormat) => {
  const { password } = formValues

  const isPasswordFormatIncorrect = !validatePassFormat(password)

  let message = ''
  let format = 'employeeRightFormat'
  let valueToReturn = true

  if (!password) {
    message = employeeString.mandatoryField
    format = 'employeeIncorrectFormat'
    valueToReturn = false
  } else if (isPasswordFormatIncorrect) {
    message = employeeString.incorrectPasswordFormat
    format = 'employeeIncorrectFormat'
    valueToReturn = false
  } else {
    message = ''
    format = 'employeeRightFormat'
    valueToReturn = true
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    passwordFormat: format,
    passwordMessage: message
  })

  return valueToReturn
}

export const onPasswordKeyDown = (
  e,
  generateAleatoryPassword,
  formValues,
  setFormValues,
  setIncorrectInputFormat
) => {
  if (e.key === 'Enter') {
    generateAleatoryPassword(formValues, setFormValues)
    setIncorrectInputFormat(prev => ({ ...prev, passwordFormat: 'employeeRightFormat' }))
  }
}

export const onPasswordClick = (
  generateAleatoryPassword,
  formValues,
  setFormValues,
  setIncorrectInputFormat
) => {
  generateAleatoryPassword(formValues, setFormValues)
  setIncorrectInputFormat(prev => ({ ...prev, passwordFormat: 'employeeRightFormat' }))
}

export const onPasswordEyeIconClick = (
  passwordState,
  setPasswordState,
  openEyeIcon,
  closeEyeIcon
) => {
  const isPasswordVisible = !passwordState.password.isPasswordVisible
  const newInputType = isPasswordVisible ? 'text' : 'password'
  const newEyeIcon = isPasswordVisible ? closeEyeIcon : openEyeIcon

  setPasswordState({
    ...passwordState,
    password: {
      eyeIcon: newEyeIcon,
      isPasswordVisible,
      passwordInputType: newInputType
    }
  })
}

export const onDocumentTypeIdChange = (
  e,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat
) => {
  const documentTypeId = e.target.value
  setFormValues({ ...formValues, documentTypeId })

  if (documentTypeId > 0) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentTypeIdFormat: 'employeeRightFormat',
      documentTypeIdMessage: ''
    })
  } else {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentTypeIdFormat: 'employeeIncorrectFormat',
      documentTypeIdMessage: employeeString.mandatoryField
    })
  }
}

export const onIsValidDocumentTypeId = (
  formValues,
  incorrectInputFormat,
  setIncorrectInputFormat
) => {
  const { documentTypeId } = formValues

  if (documentTypeId > 0) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentTypeIdFormat: 'employeeRightFormat',
      documentTypeIdMessage: ''
    })
    return true
  } else {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentTypeIdFormat: 'employeeIncorrectFormat',
      documentTypeIdMessage: employeeString.mandatoryField
    })
    return false
  }
}

export const onDocumentNumberChange = (e, formValues, setFormValues) => {
  const documentNumber = e.target.value

  if (validateBeginWithZero(documentNumber) || !validateOnlyIntegersIncludingZero(documentNumber)) return

  setFormValues({ ...formValues, documentNumber: documentNumber.substring(0, 10) })
}

export const onDocumentRepNumberChange = (e, formValues, setFormValues) => {
  const documentNumber = e.target.value

  if (validateBeginWithZero(documentNumber) || !validateOnlyIntegersIncludingZero(documentNumber)) return

  setFormValues({ ...formValues, repId: documentNumber.substring(0, 10) })
}

export const onIsValidDocumentNumberBlur = async (
  dataContext,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  idToken,
  initialFormValues
) => {
  const { documentNumber } = formValues
  let documentNumberFormat = 'employeeRightFormat'
  let documentNumberMessage = ''
  let valueToReturn = true

  if (!documentNumber) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.mandatoryField
    valueToReturn = false
  } else if (documentNumber < 10000) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.biggerNumber
    valueToReturn = false
  } else if (documentNumber > 2000000000) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.minorNumber
    valueToReturn = false
  } else if (documentNumber !== initialFormValues?.documentNumber) {
    const getEmployeePhoneOrIDExistsResponse = await getEmployeePhoneOrIDExists(dataContext, documentNumber, idToken)
    if (getEmployeePhoneOrIDExistsResponse.response) {
      documentNumberFormat = 'employeeIncorrectFormat'
      documentNumberMessage = employeeString.documentAlreadyExists
      valueToReturn = false
      setFormValues({ ...formValues, documentNumber: '' })
    }
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    documentNumberFormat,
    documentNumberMessage
  })

  return valueToReturn
}

export const onIsValidRepDocumentNumberBlur = async (
  dataContext,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  idToken,
  initialFormValues
) => {
  const { repId } = formValues
  let documentNumberFormat = 'employeeRightFormat'
  let documentNumberMessage = ''
  let valueToReturn = true

  if (!repId) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.mandatoryField
    valueToReturn = false
  } else if (repId < 10000) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.biggerNumber
    valueToReturn = false
  } else if (repId > 2000000000) {
    documentNumberFormat = 'employeeIncorrectFormat'
    documentNumberMessage = employeeString.minorNumber
    valueToReturn = false
  } else if (repId !== initialFormValues?.repId) {
    const getEmployeePhoneOrIDExistsResponse = await getEmployeePhoneOrIDExists(dataContext, repId, idToken)
    if (getEmployeePhoneOrIDExistsResponse.response) {
      documentNumberFormat = 'employeeIncorrectFormat'
      documentNumberMessage = employeeString.documentAlreadyExists
      valueToReturn = false
      setFormValues({ ...formValues, repId: '' })
    }
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    documentNumberFormat,
    documentNumberMessage
  })

  return valueToReturn
}

export const onBirthdayDateChange = (e, formValues, setFormValues) => {
  const birthdayDate = e.target.value
  setFormValues({ ...formValues, birthdayDate })
}

export const onIsValidBirthdayDateBlur = (
  formValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  maxBirthdayDate,
  minBirthdayDate
) => {
  const { birthdayDate } = formValues
  const birthdayDateFormatted = new Date(birthdayDate)
  const maxBirthdayDateFormatted = new Date(maxBirthdayDate)
  const minBirthdayDateFormatted = new Date(minBirthdayDate)

  let birthdayDateFormat = 'employeeRightFormat'
  let birthdayDateMessage = ''
  let valueToReturn = true

  if (!birthdayDate) {
    birthdayDateFormat = 'employeeIncorrectFormat'
    birthdayDateMessage = getBirthdayDateMessage('incorrect')
    valueToReturn = false
  } else if (birthdayDateFormatted < minBirthdayDateFormatted) {
    birthdayDateFormat = 'employeeIncorrectFormat'
    birthdayDateMessage = getBirthdayDateMessage('min', minBirthdayDateFormatted)
    valueToReturn = false
  } else if (birthdayDateFormatted > maxBirthdayDateFormatted) {
    birthdayDateFormat = 'employeeIncorrectFormat'
    birthdayDateMessage = getBirthdayDateMessage('max', null, maxBirthdayDateFormatted)
    valueToReturn = false
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    birthdayDateFormat,
    birthdayDateMessage
  })

  return valueToReturn
}

const getBirthdayDateMessage = (type, minDate, maxDate) => {
  switch (type) {
    case 'incorrect':
      return employeeString.incorrectBirthdayDate
    case 'min':
      return `${employeeString.minBirthdayDate}${slashedFormatDateDDMMYYYY(minDate)}`
    case 'max':
      return `${employeeString.maxBirthdayDate}${slashedFormatDateDDMMYYYY(maxDate)}`
    default:
      return ''
  }
}

export const onExpeditionCityChange = (e, formValues, setFormValues) => {
  const documentExpeditionCity = e.target.value.replace(/\s+/g, ' ')
  setFormValues({ ...formValues, documentExpeditionCity: documentExpeditionCity.trimStart() })
}

export const onIsValidExpeditionCityBlur = (
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  citiesFetched
) => {
  const { documentExpeditionCity } = formValues
  if (!documentExpeditionCity) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.mandatoryField
    })
    return false
  }

  setIncorrectInputFormat({ ...incorrectInputFormat, documentExpeditionCityFormat: 'employeeRightFormat' })

  // Documentación: Los métodos .normalize('NFD').replace(/[\u0300-\u036f]/g, '') se utilizan para quitar tildes de una cadena de caracteres
  let cityFound = citiesFetched.find(
    (e) =>
      e.cityName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim() ===
      documentExpeditionCity
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
  )

  if (!cityFound) {
    cityFound = citiesFetched.find(
      (e) =>
        e.cityName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim() ===
        (
          (documentExpeditionCity
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .includes(
              'Bogotá'
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim()
            ))
            ? 'Bogotá D.C.'
            : ''
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim()
    )
    if (cityFound) {
      setFormValues({
        ...formValues,
        documentExpeditionCity: cityFound.cityName,
        documentExpeditionCityId: cityFound.cityId.toString()
      })
      return true
    }
  }

  if (cityFound) {
    setFormValues({
      ...formValues,
      documentExpeditionCity: cityFound.cityName,
      documentExpeditionCityId: cityFound.cityId.toString()
    })
    return true
  } else if (!cityFound && documentExpeditionCity !== '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.incorrectCity
    })
    setFormValues({ ...formValues, documentExpeditionCity: '', documentExpeditionCityId: '' })
    return false
  }
}

export const onIsValidExpCityBlur = (
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  citiesFetched
) => {
  const { expCity } = formValues
  if (!expCity) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.mandatoryField
    })
    return false
  }

  setIncorrectInputFormat({ ...incorrectInputFormat, documentExpeditionCityFormat: 'employeeRightFormat' })

  // Documentación: Los métodos .normalize('NFD').replace(/[\u0300-\u036f]/g, '') se utilizan para quitar tildes de una cadena de caracteres
  let cityFound = citiesFetched.find(
    (e) =>
      e.cityName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim() ===
      expCity
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
  )

  if (!cityFound) {
    cityFound = citiesFetched.find(
      (e) =>
        e.cityName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim() ===
        (
          (expCity
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .includes(
              'Bogotá'
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim()
            ))
            ? 'Bogotá D.C.'
            : ''
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim()
    )
    if (cityFound) {
      setFormValues({
        ...formValues,
        expCity: cityFound.cityName,
        documentExpeditionCityId: cityFound.cityId.toString()
      })
      return true
    }
  }

  if (cityFound) {
    setFormValues({
      ...formValues,
      expCity: cityFound.cityName,
      documentExpeditionCityId: cityFound.cityId.toString()
    })
    return true
  } else if (!cityFound && expCity !== '') {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      documentExpeditionCityFormat: 'employeeIncorrectFormat',
      documentExpeditionCityMessage: employeeString.incorrectCity
    })
    setFormValues({ ...formValues, expCity: '', documentExpeditionCityId: '' })
    return false
  }
}

export const onGenderChange = (
  e,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat
) => {
  const gender = e.target.value
  setFormValues({ ...formValues, gender })

  if (gender > 0) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      genderFormat: 'employeeRightFormat',
      genderMessage: ''
    })
  } else {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      genderFormat: 'employeeIncorrectFormat',
      genderMessage: employeeString.mandatoryField
    })
  }
}

export const onIsValidGender = (
  formValues,
  incorrectInputFormat,
  setIncorrectInputFormat
) => {
  const { gender } = formValues

  if (gender > 0) {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      genderFormat: 'employeeRightFormat',
      genderMessage: ''
    })
    return true
  } else {
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      genderFormat: 'employeeIncorrectFormat',
      genderMessage: employeeString.mandatoryField
    })
    return false
  }
}

export const onPhoneChange = (
  e,
  formValues,
  setFormValues
) => {
  const phone = validateCellphone(e)
  if (phone !== undefined) setFormValues({ ...formValues, phone })
}

export const onIsValidPhoneBlur = async (
  dataContext,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  idToken,
  initialFormValues
) => {
  const { phone } = formValues

  let phoneFormat = 'employeeRightFormat'
  let phoneMessage = ''
  let valueToReturn = true

  if (!phone) {
    phoneFormat = 'employeeIncorrectFormat'
    phoneMessage = employeeString.mandatoryField
    valueToReturn = false
  } else if (phone.length !== 10 || !phone.startsWith('3')) {
    phoneFormat = 'employeeIncorrectFormat'
    phoneMessage = employeeString.incorrectPhone
    valueToReturn = false
  } else if (phone !== initialFormValues?.phone) {
    const getEmployeePhoneOrIDExistsResponse = await getEmployeePhoneOrIDExists(dataContext, phone, idToken)
    if (getEmployeePhoneOrIDExistsResponse.response) {
      phoneFormat = 'employeeIncorrectFormat'
      phoneMessage = employeeString.phoneAlreadyExists
      valueToReturn = false
      setFormValues({ ...formValues, phone: '' })
    }
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    phoneFormat,
    phoneMessage
  })
  return valueToReturn
}

export const onPasswordConfirmationChange = (e, formValues, setFormValues) => {
  const passwordConfirmation = e.target.value
  setFormValues({ ...formValues, passwordConfirmation })
}

export const onIsValidPasswordConfirmationBlur = (formValues, incorrectInputFormat, setIncorrectInputFormat) => {
  const { passwordConfirmation, password } = formValues

  const isPasswordFormatIncorrect = !validatePassFormat(passwordConfirmation)

  let message = ''
  let format = 'employeeRightFormat'
  let valueToReturn = true

  if (!passwordConfirmation) {
    message = employeeString.mandatoryField
    format = 'employeeIncorrectFormat'
    valueToReturn = false
  } else if (isPasswordFormatIncorrect) {
    message = employeeString.incorrectPasswordFormat
    format = 'employeeIncorrectFormat'
    valueToReturn = false
  } else if (passwordConfirmation !== password) {
    message = employeeString.incorrectPasswordConfirmation
    format = 'employeeIncorrectFormat'
    valueToReturn = false
  }

  setIncorrectInputFormat({
    ...incorrectInputFormat,
    passwordConfirmationFormat: format,
    passwordConfirmationMessage: message
  })

  return valueToReturn
}

export const onPasswordConfirmationEyeIconClick = (
  passwordState,
  setPasswordState,
  openEyeIcon,
  closeEyeIcon
) => {
  const isPasswordVisible = !passwordState.passwordConfirmation.isPasswordVisible
  const newInputType = isPasswordVisible ? 'text' : 'password'
  const newEyeIcon = isPasswordVisible ? closeEyeIcon : openEyeIcon

  setPasswordState({
    ...passwordState,
    passwordConfirmation: {
      eyeIcon: newEyeIcon,
      isPasswordVisible,
      passwordInputType: newInputType
    }
  })
}

// --------------------------------------------------------------------------------------------
// Other methods

export const calculateBirthdayDatesRange = () => {
  // Documentación: Esta expresión calcula la fecha de hace 120 años a partir de la fech actual
  const minBirthdayDate = new Date(new Date().setFullYear(new Date().getFullYear() - 120))
    .toISOString()
    .split('T')[0]

  // Documentación: Esta expresión calcula la fecha de hace 18 años a partir de la fech actual
  const maxBirthdayDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
    .toISOString()
    .split('T')[0]

  return { minBirthdayDate, maxBirthdayDate }
}
