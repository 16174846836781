import { handleDownload } from '../../../../../../utils/functions'
import { finishProcess, getDemandAnnexes, uploadNewAnnex } from '../../../../../../utils/userQueries'

export const downloadAnnexes = async (dataContext, demandId, setDownloadAnnexesDisabled) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const responseReq = await getDemandAnnexes(dataContext, idToken, demandId)

  if (responseReq.response) {
    handleDownload(responseReq.body)
  } else {
    setDownloadAnnexesDisabled(true)
  }
}

export const uploadNewAnnexes = async (dataContext, demandId, file, setFile) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const fileNameFormatted = file.name.replace(/\s/g, '_')

  const requestBody = {
    demandId,
    files: {
      value: 'demand',
      fileNames: [
        {
          fileName: fileNameFormatted,
          contentType: file.type
        }
      ]
    }
  }

  const responseReq = await uploadNewAnnex(dataContext, idToken, requestBody)
  if (responseReq.response) {
    const keyName = Object.keys(responseReq.body)[0]
    const urlToSendFile = responseReq.body[keyName].url
    await fetch(urlToSendFile, {
      method: 'PUT',
      body: file
    })
    setFile(null)
  }
}

export const handlefinishProcess = async (dataContext, statusIdNumber, handleShow, demandId, setRadicateNum) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const requestBody = { statusId: statusIdNumber }
  const responseReq = await finishProcess(dataContext, idToken, requestBody, demandId)

  if (responseReq.response) {
    setRadicateNum(responseReq.body.radicatedNum)
    handleShow()
  }
}
