import React, { useContext } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { ScriptContext } from '../../../context/ScriptsContext'

import './MovementsLayout.css'

export const MovementsLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { pathname } = useLocation()

  return (
    <div className="movementsLayoutContainer">
      <div className="MovementsHeaderContainer">
        {pathname.endsWith('MovimientosTuDataControl')
          ? (
          <NavLink
            to="PagosTuDataControl/MovimientosTuDataControl"
            className="MovementsActiveHeader"
          >
            <p className="MovementsPanelParagraph">
              {scriptsSave && scriptsSave.movementsLayoutString ? scriptsSave.movementsLayoutString.tuDataControlMovements : ''}
            </p>
          </NavLink>
            )
          : pathname.endsWith('MovimientosUsuario')
            ? (
          <NavLink
            to="PagosTuDataControl/MovimientosTuDataControl"
            className="MovementsActiveHeader"
          >
            <p className="MovementsPanelParagraph">
              {scriptsSave && scriptsSave.movementsLayoutString ? scriptsSave.movementsLayoutString.userMovements : ''}
            </p>
          </NavLink>
              )
            : pathname.endsWith('MovimientosEntidad')
              ? (
          <NavLink
            to="PagosTuDataControl/MovimientosTuDataControl"
            className="MovementsActiveHeader"
          >
            <p className="MovementsPanelParagraph">
              {scriptsSave && scriptsSave.movementsLayoutString ? scriptsSave.movementsLayoutString.entityMovements : ''}
            </p>
          </NavLink>
                )
              : (
          <>
            <NavLink
              to=""
              className={({ isActive }) =>
                isActive ? 'MovementsActiveHeader' : ''
              }
              end
            >
              <p className="MovementsPanelParagraph">
                {scriptsSave && scriptsSave.movementsLayoutString ? scriptsSave.movementsLayoutString.userPocket : ''}
              </p>
            </NavLink>
            <NavLink
              to="PagoEntidades"
              className={({ isActive }) =>
                isActive ? 'MovementsActiveHeader' : ''
              }
            >
              <p className="MovementsPanelParagraph">
                {scriptsSave && scriptsSave.movementsLayoutString ? scriptsSave.movementsLayoutString.entitiesPayment : ''}
              </p>
            </NavLink>

            <NavLink
              to="PagosTuDataControl"
              className={({ isActive }) =>
                isActive ? 'MovementsActiveHeader' : ''
              }
            >
              <p className="MovementsPanelParagraph">
                {scriptsSave && scriptsSave.movementsLayoutString ? scriptsSave.movementsLayoutString.tuDataControlPayments : ''}
              </p>
            </NavLink>
            <NavLink
              to='HistorialPagos'
              className={({ isActive }) =>
                isActive ? 'MovementsActiveHeader' : ''
              }
            >
              <p className={'MovementsPanelParagraph'}>
                {scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.historyPays : ''}
              </p>
          </NavLink>
          </>
                )}
      </div>
      <hr className="hrUsersPanel" />
      <Outlet />
    </div>
  )
}
