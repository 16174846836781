import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../../context/DataContext'
import { getBuyerNotActive } from '../../../utils/userQueries'
import { dataTypes } from '../../../context/reducers/dataReducer/dataTypes'

export const useFetchBuyerNotActive = () => {
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, newEntries } = dataState.operative
  const { buyerCredentialId } = newEntries.entityEntryToBeReviewed

  const [buyerNotActiveData, setBuyerNotActiveData] = useState({
    buyerCredentialId: '',
    buyerId: '',
    companyComertialName: '',
    companyBussinesName: '',
    companyEmail: '',
    companyNit: '',
    buyerLegalRepresentative: '',
    buyerContactName: '',
    companyPhone: '',
    companyLogoPath: '',
    buyerRUTPath: ''
  })

  useEffect(() => {
    const fetchUserNotActive = async () => {
      const getBuyerNotActiveResponse = await getBuyerNotActive(dataContext, buyerCredentialId, idToken)
      if (getBuyerNotActiveResponse.response) {
        const data = {
          buyerCredentialId: getBuyerNotActiveResponse.body.buyerCredentialId,
          buyerId: getBuyerNotActiveResponse.body.buyerId.buyerId,
          companyComertialName: getBuyerNotActiveResponse.body.buyerId.companyId.companyComertialName,
          companyBussinesName: getBuyerNotActiveResponse.body.buyerId.companyId.companyBussinesName,
          companyEmail: getBuyerNotActiveResponse.body.buyerId.companyId.companyEmail,
          companyNit: getBuyerNotActiveResponse.body.buyerId.companyId.companyNit,
          buyerLegalRepresentative: getBuyerNotActiveResponse.body.buyerId.buyerLegalRepresentative,
          buyerContactName: getBuyerNotActiveResponse.body.buyerId.buyerContactName,
          companyPhone: getBuyerNotActiveResponse.body.buyerId.companyId.companyPhone,
          companyLogoPath: getBuyerNotActiveResponse.body.buyerId.companyId.companyLogoPath,
          buyerRUTPath: getBuyerNotActiveResponse.body.buyerId.buyerRUTPath
        }
        setBuyerNotActiveData(data)

        const action = {
          type: dataTypes.setEntityEntryToBeModified,
          payload: data
        }

        dataDispatch(action)
      }
    }
    fetchUserNotActive()
  }, [])

  return buyerNotActiveData
}
