import { updatePQRS } from '../../utils/userQueries'
import { handleRequestButton, hasRepeatedCharactersAtMiddle, validateNoWhiteSpacesAtBeginning } from '../../utils/validateFunctions'

export const onInputStatusClick = (e, setResponseFields) => {
  setResponseFields(prev => {
    return { ...prev, statusId: Number(e.target.value) }
  })
}

export const onSubmitClick = async (
  pqrsId,
  requestId,
  responseFields,
  handleShow,
  idToken,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  handleRequestButton(setIsRequesting)
  const updatePQRSResponse = await updatePQRS(dataContext, idToken, requestId, responseFields, pqrsId)
  if (updatePQRSResponse.response) handleShow()
}

export const onCloseRequestAnsweredClick = (handleClose, navigate) => {
  navigate('../')
  handleClose()
}

export const validateTextarea = (e, setResponseFields) => {
  const text = e.target.value

  if (text.length > 1800) return

  if (validateNoWhiteSpacesAtBeginning(text)) {
    if (hasRepeatedCharactersAtMiddle(text)) {
      return ''
    }
    setResponseFields(prev => {
      return { ...prev, response: text }
    })
  }
}
