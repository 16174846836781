import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import iconPersonPlus from '../../assets/icons/icon_userplus.svg'
import { TablaGeneral } from '../../components/TablaGeneral/TablaGeneral'
import { useFetchEmployees } from './hooks/useFetchEmployees'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import { formatEmployeeList } from './UsersPanelAdministrativeFunctions'
import { ScriptContext } from '../../context/ScriptsContext'

import './UsersPanelAdministrative.css'

export const UsersPanelAdministrative = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { employeesList, idToken } = dataState.administrative

  useFetchEmployees(dataContext, dataDispatch, idToken)

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    employeesList,
    formatEmployeeList
  )

  return (
    <div>
      <div>
        <div className='usersAdminPanelHeaderContainer'>
          <p className={'usersAdminPanelParagraph'}>
            {scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.userPanelAdministrative : ''}
          </p>
        </div>
        <hr className='hrUsersAdminPanel' />
        <div className='newUserButtonContainer'>
          <NavLink to='NuevoUsuario' className='newUserButton'>
            <img
              className='iconNewUserButton'
              src={iconPersonPlus}
              alt={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.altImg1 : ''}
            />
            {scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.newUser : ''}
          </NavLink>
        </div>
      </div>
      <TablaGeneral
        originalUsersList={originalList}
        usersList={listFormatted}
        setUsersListFormatted={setListFormatted}
        first={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column1 : ''} // ID
        second={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column2 : ''} // Nombre
        third={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column3 : ''} // No. Identificación
        fourth={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column4 : ''} // Tipo de usuario
        fifth={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column5 : ''} // Correo
        sixth={scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column6 : ''} //
      />
    </div>
  )
}
