import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from '../../../../components/Modal/Modal'
import userIcon from '../../../../assets/icons/icon_usuario1.svg'
import saveIcon from '../../../../assets/icons/icon_saveinfo.svg'
import backIcon from '../../../../assets/icons/icon_volver.svg'

import './UserEdit.css'
import { useModal } from '../../../../hooks/useModal'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const UserEdit = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const [showModal, handleShow, handleClose] = useModal()
  const navigate = useNavigate()
  return (
    <section id="UserEdit">
      <div id="UserEdit-content">
        <div className="userEdit-lside">
          <img
            src={userIcon}
            alt={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.altImageTxt1 : ''}
          />
        </div>
        <div className="userEdit-rside">
          <div>
            <p className="userEdit-rside-inputName">
              {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.name : ''}
            </p>
            <input
              type="text"
              className="userEdit-rside-input"
              placeholder={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.placeholderTxt1 : ''}
            />
            <button className="userEdit-rside-btn">
              <img
                src={saveIcon}
                alt={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.altImageTxt2 : ''}
              />
            </button>
          </div>
          <div>
            <p className="userEdit-rside-inputName">
              {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.email : ''}
            </p>
            <input
              type="text"
              className="userEdit-rside-input"
              placeholder={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.placeholderTxt2 : ''}
            />
            <button className="userEdit-rside-btn">
              <img
                src={saveIcon}
                alt={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.altImageTxt3 : ''}
              />
            </button>
          </div>
          <div>
            <p className="userEdit-rside-inputName">
              {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.identification : ''}
            </p>
            <input
              type="text"
              className="userEdit-rside-input"
              placeholder={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.placeholderTxt3 : ''}
            />
            <button className="userEdit-rside-btn">
              <img
                src={saveIcon}
                alt={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.altImageTxt4 : ''}
              />
            </button>
          </div>
          <div>
            <p className="userEdit-rside-inputName">
              {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.cel : ''}
            </p>
            <input
              type="text"
              className="userEdit-rside-input"
              placeholder={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.placeholderTxt4 : ''}
            />
            <button className="userEdit-rside-btn">
              <img
                src={saveIcon}
                alt={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.altImageTxt5 : ''}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="NavFooter">
        <Link className="NavFooterBackLink">
          <img
            src={backIcon}
            alt={scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.altImageTxt6 : ''}
          />
          <span>{scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.back : ''}</span>
        </Link>
        <div className="NavFooterButtonsContainer">
          <button
            className="NavFooterButton NavFooterButton1"
            onClick={() => navigate(-1)}
          >
            {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.cancel : ''}
          </button>
          <button
            className="NavFooterButton NavFooterButton2"
            onClick={handleShow}
          >
            {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.save : ''}
          </button>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.changesDone : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-normalBtn" onClick={handleClose}>
            {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={() => navigate('../DetalleUsuario')}
          >
            {scriptsSave && scriptsSave.userEditString ? scriptsSave.userEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
