import { calculateNumberOfDigits, formatDateDDMMYYMonthInLetters } from '../../../../utils/functions'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'

export const formatTuDataControlPaymentsList = (controlPaymentsList, setControlPaymentsListFormatted, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(controlPaymentsList)
  const currentItem = page

  setControlPaymentsListFormatted(controlPaymentsList.map((payment, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      userId: payment.userId.userId,
      name: `${payment.userId.personId.personName} ${payment.userId.personId.personLastName}`,
      creationDate: formatDateDDMMYYMonthInLetters(payment?.creationDate),
      state: 'Finalizado',
      demandId: payment?.demandId?.demandId,
      packageId: payment?.packageId
    }
  }))
}

export const onDetailsClick = (
  navigate,
  payment,
  dataDispatch
) => {
  const action = {
    type: dataTypes.setTuDataControlPaymentToBeReviewd,
    payload: { packageId: payment.packageId }
  }
  dataDispatch(action)

  navigate('MovimientosTuDataControl')
}
