import React, { useContext } from 'react'
import iconoVer from '../../../../../../assets/icons/icon_ver-operativo.svg'
import expiredIcon from '../../../../../../assets/icons/icon_expired.png'
import './ActiveProcessList.css'
import { Link } from 'react-router-dom'
import { ScriptContext } from '../../../../../../context/ScriptsContext'

export const ActiveProcessList = ({ isActive, setActiveViews, demands, setDemandSelected }) => {
  const { scriptsSave } = useContext(ScriptContext)
  if (!isActive) {
    return null
  }

  const handleDemandSelected = (demandData) => {
    setDemandSelected(demandData)
    setActiveViews((activeViews) => ({ ...activeViews, activeProcessListActive: false, documentationProcess: true }))
  }

  return (
    <section id='ActiveProcessList'>
        <div className='ActiveProcessListTableBox'>
          <table className='ActiveProcessListTable'>
            <thead>
              <tr>
                <th className='ActiveProcessListTitle'>{scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.column1Name : ''}</th>
                <th className='ActiveProcessListTitle'>{scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.column2Name : ''}</th>
                <th className='ActiveProcessListTitle'>{scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.column3Name : ''}</th>
              </tr>
            </thead>
            <tbody>
              {
                demands?.map((item, index) => (
                  <tr key={index} className='ActiveProcessTRow'>
                    <td className='ActiveProcessListText'>{item.requirementId.requirementName}</td>
                    <td className='ActiveProcessListText'>
                      {item.statusId.statusName}
                      {item.expired &&
                        <div className='expiredAlertProcessList'>
                          <span className='expiredAlertProcessListText'>{item.expired && (scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.expiredItem : '')}</span>
                          <img
                            src={expiredIcon}
                            alt={scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.altImageTxt1 : ''}
                          />
                        </div>
                      }
                    </td>
                    <td>
                      <Link
                        onClick={() => handleDemandSelected({ demandId: item.demandId, name: item.requirementId.requirementName, status: item.statusId.statusName, createDate: item.creationDate })}>
                        <img
                          src={iconoVer}
                          className='ActiveProcessListSeeIcon'
                          alt={scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.altImageTxt2 : ''}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className='ActiveProcessListBtnBox'>
          <button
            className='ActiveProcessListButton'
            onClick={() => setActiveViews((activeViews) => ({ ...activeViews, activeProcessListActive: false, selectProcess: true }))}
          >
            {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.createNewProcess : ''}
          </button>
        </div>
      </section>
  )
}
