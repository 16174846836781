import { editCompany } from '../../../../utils/userQueries'
import { fileExtension } from '../../../../utils/validateFunctions'

export const handleSubmit = async (
  e,
  idToken,
  createValues,
  dataContext,
  handleSaveModalShow,
  setisDisabled,
  initialData,
  setIsComertialName,
  setIncorrectInputFormat,
  incorrectInputFormat,
  validateFunction,
  setIsBussinessName
) => {
  e.preventDefault()
  const wasDataUpdatedResponse = wasDataUpdated(initialData, createValues)
  if (!wasDataUpdatedResponse) return

  const { companyId } = createValues
  setisDisabled(true)
  const btnName = e.nativeEvent.submitter.name
  if (btnName === 'cancel') return null

  const createValuesFormatted = formatCreateValues(createValues)
  const bodyToRequest = formatBodyToRequest(createValuesFormatted)

  const comertialName = bodyToRequest.updateCompanyDto.companyComertialName
  if (comertialName.length < 10) {
    setIsComertialName(true)
    setIncorrectInputFormat({ ...incorrectInputFormat, comertialNameformat: 'employeeIncorrectFormat' })
    return false
  }
  setIsComertialName(false)
  const companyBussinesName = bodyToRequest.updateCompanyDto.companyBussinesName
  if (companyBussinesName.length < 10) {
    setIsBussinessName(true)
    setIncorrectInputFormat({ ...incorrectInputFormat, bussinessNameFormat: 'employeeIncorrectFormat' })
    return false
  }
  setIsBussinessName(false)
  // const companyNit = bodyToRequest.updateCompanyDto.companyNit
  // const responseCompanyValidate = await validateFunction(idToken, companyNit, dataContext)
  // if (responseCompanyValidate.response) {
  //   return
  // }
  bodyToRequest.updateCompanyDto.directRemitent = true
  createValuesFormatted.directRemitent = true

  const responseCompanyCreation = await editCompany(companyId, idToken, bodyToRequest, createValuesFormatted, dataContext)
  if (responseCompanyCreation.response) {
    setisDisabled(false)
    handleSaveModalShow()
  }
}

const formatBodyToRequest = (createValuesFormatted) => {
  delete createValuesFormatted.state
  const { file, ...restOfValues } = createValuesFormatted
  let bodyToRequest = {}

  if (typeof file === 'string') {
    bodyToRequest = {
      updateCompanyDto: {
        ...restOfValues
      }
    }
  } else {
    bodyToRequest = {
      updateCompanyDto: {
        ...restOfValues
      },
      file: {
        value: 'logos',
        fileNames: [
          {
            fileName: file.name,
            contentType: file.type
          }
        ]
      }
    }
  }

  return bodyToRequest
}

const formatCreateValues = (createValues) => {
  const keysToFormat = {
    city: 'cityId',
    email: 'companyEmail',
    phone: 'companyPhone',
    bussinessName: 'companyBussinesName',
    comertialName: 'companyComertialName'
  }

  const createValuesFormatted = Object.entries(createValues)

  const formattedValuesToRequest = createValuesFormatted.map(([key, value]) => {
    const newKey = keysToFormat[key] || key
    return [newKey, value]
  })

  const valuesFormatted = Object.fromEntries(formattedValuesToRequest)

  return valuesFormatted
}

export const wasDataUpdated = (initialData, newData) => {
  if (
    initialData.bussinessName === newData.bussinessName &&
    initialData.city === newData.city &&
    initialData.comertialName === newData.comertialName &&
    initialData.companyNit === newData.companyNit &&
    initialData.email === newData.email &&
    initialData.file === newData.file &&
    initialData.phone === newData.phone &&
    initialData.state === newData.state &&
    initialData.categoryCompany.categoryCompanyId === newData.categoryCompany.categoryCompanyId
  ) {
    return false
  }
  return true
}

export const onUploadLogoClick = (e, setCreateValues, createValues, message, setModalMessage, handleShow) => {
  const isAValidExtension = fileExtension(
    e,
    message,
    setModalMessage,
    handleShow
  )

  if (!isAValidExtension) return

  setCreateValues({ ...createValues, file: e.target.files[0] })
}

export const onFirstCheckboxChange = (e, createValues, setCreateValues) => {
  setCreateValues({ ...createValues, categoryCompany: { categoryCompanyId: 1 } })
}
export const onSecondCheckboxChange = (e, createValues, setCreateValues) => {
  setCreateValues({ ...createValues, categoryCompany: { categoryCompanyId: 2 } })
}
export const onThirdCheckboxChange = (e, createValues, setCreateValues) => {
  setCreateValues({ ...createValues, categoryCompany: { categoryCompanyId: 4 } })
}
