import React from 'react'
import './Modal.css'

export const Modal = ({ isOpen, children }) => {
  if (!isOpen) {
    return null
  }
  return (
    <section
      className={`ModalBackground ${!isOpen ? 'modalClose' : 'modalOpen'}`}
    >
      <div className="ModalBody">{children}</div>
    </section>
  )
}

const Content = ({ children }) => {
  return <div className="ModalBody-content">{children}</div>
}

const Buttons = ({ children }) => {
  return <div className="ModalBody-buttons">{children}</div>
}

Modal.Content = Content
Modal.Buttons = Buttons
