import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import {
  getBuyerData,
  getCities,
  getStates,
  updateBuyerData,
  updateBuyerEmail,
  updateBuyerPhone
} from '../../../../utils/userQueries'
import { compareValues } from '../EditPQRSUser/EditPQRSUserFunctions'

export const fetchData = async ({
  idToken,
  dataContext,
  buyerId,
  setInitialValues,
  setNewValues
}) => {
  const { body } = await getBuyerData(dataContext, idToken, buyerId)
  const initValuesBody = {
    buyerId,
    companyId: body.companyId.companyId,
    legalRepName: body.buyerLegalRepresentative,
    typeId: body.buyerRepresentativeIdType.identDocName,
    repId: body.buyerRepresentativeIdNum,
    expCity: body.buyerExpeditionCity,
    contactName: body.buyerContactName,
    address: body.buyerAddress,
    email: body.buyerCredentials.buyerCredentialEmail,
    phone: body.companyId.companyPhone
  }
  setInitialValues(initValuesBody)
  setNewValues(initValuesBody)
}

export const getLocationValues = async ({
  citiesFetched,
  statesFetched,
  dataDispatch
}) => {
  let cities, states

  if (citiesFetched.length < 1) {
    cities = await getCities()
    dataDispatch({
      type: dataTypes.getCities,
      payload: cities
    })
  }

  if (statesFetched.length < 1) {
    states = await getStates()
    dataDispatch({
      type: dataTypes.getStates,
      payload: states
    })
  }
}

export const handleSubmit = async (
  e,
  idToken,
  newValues,
  initialValues,
  handleCancelModalClose,
  handleSaveModalShow,
  dataContext,
  setIsDisabled
) => {
  e.preventDefault()
  setIsDisabled(true)

  const valuesToRequest = filterNewBuyerValues(initialValues, newValues)
  if (!valuesToRequest) return null

  if (valuesToRequest.buyerRepresentativeIdType) {
    valuesToRequest.bubuyerRepresentativeIdType = {
      identDocId: 1,
      identDocName: valuesToRequest.buyerRepresentativeIdType,
      identDocUsed: true
    }
  }

  let subQuerysResponse = null
  const { email, phone, ...restOfValues } = valuesToRequest
  if (email) {
    subQuerysResponse = await updateBuyerEmail(
      dataContext,
      idToken,
      initialValues.buyerId,
      { email }
    )
  }
  if (phone) {
    subQuerysResponse = await updateBuyerPhone(
      dataContext,
      idToken,
      initialValues.companyId,
      { companyPhone: phone }
    )
  }
  if (Object.keys(restOfValues).length) {
    const responseUpdateBuyer = await updateBuyerData(
      dataContext,
      idToken,
      initialValues.buyerId,
      restOfValues
    )
    if (responseUpdateBuyer.response) {
      setIsDisabled(false)
      handleCancelModalClose()
      handleSaveModalShow()
    }
  } else if (subQuerysResponse) {
    setIsDisabled(false)
    handleCancelModalClose()
    handleSaveModalShow()
  }
}

export const filterNewBuyerValues = (initValues, newValues) => {
  const keysToRequest = {
    legalRepName: 'buyerLegalRepresentative',
    typeId: 'buyerRepresentativeIdType',
    repId: 'buyerRepresentativeIdNum',
    expCity: 'buyerExpeditionCity',
    contactName: 'buyerContactName',
    address: 'buyerAddress'
  }

  const filteredValues = compareValues(initValues, newValues)

  const formattedValuesToRequest = filteredValues.map(([key, value]) => {
    const newKey = keysToRequest[key] || key
    return [newKey, value]
  })

  const valuesToRequest = Object.fromEntries(formattedValuesToRequest)

  return valuesToRequest
}

export const cancelEdit = (e, isDisabled, handleCancelModalShow, navigate, state) => {
  e.preventDefault()
  if (!isDisabled) handleCancelModalShow()
  else navigate('../SolicitudDelUsuario', { state })
}
