import React, { useContext, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'

import { DataContext } from '../../context/DataContext'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import editIcon from '../../assets/icons/icon_editar.svg'
import deleteIcon from '../../assets/icons/icon_borrar.svg'
import { Pagination } from '../Pagination/Pagination'
import { Dropdown } from '../Dropdown/Dropdown'
import {
  onDeleteEmployeeClick,
  onDeleteEmployeeConfirmationClick,
  onDetailsButtonClick,
  onEditEmployeeClick
} from './TablaGeneralFunctions'

import './TablaGeneral.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const TablaGeneral = ({
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  originalUsersList,
  usersList,
  setUsersListFormatted,
  originalDataBaseList,
  dataBaseList,
  setDataBaseListFormatted,
  paginationValues,
  setPaginationValues,
  isNextActive,
  setIsNextActive,
  isPrevActive,
  setIsPrevActive
}) => {
  const location = useLocation()
  const totalPages = paginationValues?.totalPages

  const dataContext = useContext(DataContext)
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, dataBases, employeeCredentialEmail, employeeToBeEdited } =
    dataState.administrative
  const { currentPage, scrollMeasurement } = dataBases.dataBases
  const employeeCredentialIdToDelete = employeeToBeEdited.employeeCredentialId

  const [showModalA, handleShowA, handleCloseA] = useModal()
  const [showModalB, handleShowB, handleCloseB] = useModal()

  const [isRequesting, setIsRequesting] = useState(false)

  const navigate = useNavigate()

  const userTypeOption1 = scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.userTypeOption1 : ''
  const userTypeOption2 = scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.userTypeOption2 : ''
  const userTypeOption3 = scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.userTypeOption3 : ''
  const userTypeFilterOptions = [userTypeOption1, userTypeOption2, userTypeOption3]

  const dbTypeOption1 = scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.dbTypeOption1 : ''
  const dbTypeOption2 = scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.dbTypeOption2 : ''
  const databaseTypeFilterOptions = [dbTypeOption1, dbTypeOption2]

  return (
    <div className={`${dataBaseList ? 'table-db-container' : 'table-container'}`}>
      <table className='mainTable'>
        <thead className='tableHeaderTG'>
          <tr className='trHeaderGeneralBA'>
            <th className='thTipoSolicitudBA'>{first}</th>
            <th className='thTipoSolicitudBA'>
              {second === `${scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column2 : ''}`
                ? (
                <Dropdown
                  filterBy={'username'}
                  list={originalUsersList}
                  title={second}
                  // type={'search'}
                  updateList={setUsersListFormatted}
                />
                  )
                : second === `${scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column2 : ''}`
                  ? (
                <Dropdown
                  filterBy={'databaseType'}
                  list={originalDataBaseList}
                  options={databaseTypeFilterOptions}
                  title={second}
                  type={'select'}
                  updateList={setDataBaseListFormatted}
                />
                    )
                  : (
                <></>
                    )}
            </th>
            <th className='thEmpresaBA'>
              {third === `${scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column3 : ''}`
                ? (
                <Dropdown
                  filterBy={'idnumber'}
                  list={originalUsersList}
                  title={third}
                  // type={'search'}
                  updateList={setUsersListFormatted}
                  isNumber={true}
                />
                  )
                : third === `${scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column3 : ''}`
                  ? (
                <Dropdown
                  filterBy={'name'}
                  list={originalDataBaseList}
                  title={third}
                  // type={'search'}
                  updateList={setDataBaseListFormatted}
                />
                    )
                  : (
                <></>
                    )}
            </th>
            <th className='thNitBA'>
              {fourth === `${scriptsSave && scriptsSave.usersPanelAdministrativeString ? scriptsSave.usersPanelAdministrativeString.column4 : ''}`
                ? (
                <Dropdown
                  filterBy={'usertype'}
                  list={originalUsersList}
                  options={userTypeFilterOptions}
                  title={fourth}
                  type={'select'}
                  updateList={setUsersListFormatted}
                />
                  )
                : (
                <></>
                  )}
            </th>
            <th className='thSolicitanteBA'>{fifth}</th>
            <th className='thEstadoBA'>{sixth}</th>
            <th className='thAccionesBA'>
              {location.pathname.endsWith('UsuariosAdmin')
                ? `${scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.actions : ''}`
                : `${scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.details : ''}`
              }
            </th>
          </tr>
        </thead>
        <tbody>
          {usersList === undefined
            ? null
            : usersList.map((user, index) => (
                <tr className='generalTableRow' key={index}>
                  <td className='thIdBA'>{user.id}</td>
                  <td className='thTipoSolicitudBA'>{user.username}</td>
                  <td className='thTipoSolicitudBA'>{user.idnumber}</td>
                  <td className='thEmpresaBA'>{user.usertype}</td>
                  <td className='thSolicitanteBA'>
                    {user.employeeCredentialEmail}
                  </td>
                  <td className='thEstadoBA'>{''}</td>
                  <td className='thAccionesBA'>
                    <div className='editButton'>
                      <img
                        alt={scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.altImg1 : ''}
                        className='actionButton'
                        onClick={() =>
                          onEditEmployeeClick(
                            usersList[index].employeeCredentialId,
                            dataDispatch,
                            idToken,
                            dataContext,
                            navigate
                          )
                        }
                        src={editIcon}
                      />
                    </div>
                    <img
                      alt={scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.altImg2 : ''}
                      className={
                        user.employeeCredentialEmail !== employeeCredentialEmail
                          ? 'actionButton'
                          : 'actionButtonHidden'
                      }
                      onClick={
                        user.employeeCredentialEmail !== employeeCredentialEmail
                          ? () =>
                              onDeleteEmployeeClick(
                                usersList[index],
                                employeeCredentialEmail,
                                dataDispatch,
                                handleShowA
                              )
                          : null
                      }
                      src={deleteIcon}
                    />
                  </td>
                </tr>
            ))}
          {dataBaseList === undefined
            ? null
            : dataBaseList.map((dataBase, index) => (
                <tr className='generalTableRow' key={index}>
                  <td className='thIdBA'>{dataBase.id}</td>
                  <td className='thTipoSolicitudBA'>
                    {dataBase.databaseType}
                  </td>
                  <td className='thTipoSolicitudBA'>{dataBase.name}</td>
                  <td className='thEmpresaBA2'>{dataBase.expeditionDate}</td>
                  <td className='thSolicitanteBA'> </td>
                  <td className='thEstadoBA'>{''}</td>
                  <td className='thAccionesBA'>
                    <NavLink
                      className='detailsButton'
                      onClick={() =>
                        onDetailsButtonClick(dataBase, dataDispatch)
                      }
                      to='DetalleDB'
                    >
                      {scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.details : ''}
                    </NavLink>
                  </td>
                </tr>
            ))}
        </tbody>
      </table>
      {dataBaseList && (
        <Pagination
          currentPage={currentPage}
          paginationValues={paginationValues}
          scrollMeasurement={scrollMeasurement}
          setPaginationValues={setPaginationValues}
          totalPages={totalPages}
          isNextActive={isNextActive}
          setIsNextActive={setIsNextActive}
          isPrevActive={isPrevActive}
          setIsPrevActive={setIsPrevActive}
        />
      )}
      <Modal isOpen={showModalA}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.deleteUser : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-normalBtn' onClick={handleCloseA}>
            {scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.cancel : ''}
          </button>
          <button
            className={`${isRequesting ? 'Modal-orangeBtn-disabled' : 'Modal-orangeBtn'}`}
            onClick={() =>
              onDeleteEmployeeConfirmationClick(
                employeeCredentialIdToDelete,
                idToken,
                dataDispatch,
                handleCloseA,
                handleShowB,
                isRequesting,
                setIsRequesting,
                dataContext
              )
            }
          >
            {scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.delete : ''}
          </button>
        </Modal.Buttons>
      </Modal>

      <Modal isOpen={showModalB}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.tablaGeneralStrings ? parse(scriptsSave.tablaGeneralStrings.credentialsDeleted) : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={handleCloseB}>
            {scriptsSave && scriptsSave.tablaGeneralStrings ? scriptsSave.tablaGeneralStrings.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </div>
  )
}
