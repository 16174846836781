import { calculateNumberOfDigits } from '../../../../utils/functions'

export const formatCaseTrackingList = (caseTrackingList, setCaseTrackingList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(caseTrackingList)
  const currentItem = page
  let id = (currentItem * limit) - limit + 1
  if (id < 1) id = 1
  const listFormatted = caseTrackingList.map((response, index) => {
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      userId: response.userId,
      name: `${response.personId?.personName} ${response.personId?.personLastName}`,
      userIdentNum: response.personId?.personIdentNum,
      plan: response.planId?.planName,
      expired: response.expired
    }
  })
  setCaseTrackingList(listFormatted)
}
