import { handleDownload } from '../../../../utils/functions'
import { markEmailAsReaded } from '../../../../utils/userQueries'
import { handleRequestButton } from '../../../../utils/validateFunctions'

export const onDownloadClick = async (attachment, isRequesting, setIsRequesting) => {
  if (isRequesting) return

  handleRequestButton(setIsRequesting)
  const downloadLinks = JSON.parse(attachment[0].attachmenLink)
  const onlyLinks = downloadLinks.map((link) => link.key)

  await handleDownload(onlyLinks)
}

export const handleCheckboxChange = async (e, setIsChecked, navigate, id, dataContext) => {
  const { checked } = e.target
  setIsChecked(checked)
  if (checked) {
    const { dataState } = dataContext
    const { idToken } = dataState.operative
    const markEmail = await markEmailAsReaded(dataContext, id, idToken)
    if (markEmail.response) {
      navigate('../')
    }
  }
}
