import { calculateNumberOfDigits, capitalizeFirstLetter } from '../../utils/functions'

export const formatEmployeeList = (employeesList, setEmployeesListFormatted) => {
  const numberOfDigits = calculateNumberOfDigits(employeesList)
  setEmployeesListFormatted(employeesList?.map((employee, index) => ({
    id: String(index + 1).padStart(numberOfDigits, '0'),
    username: `${employee.employeeId.personId.personName} ${employee.employeeId.personId.personLastName}`,
    idnumber: employee.employeeId.personId.personIdentNum,
    usertype: capitalizeFirstLetter(employee.employeeId.roleId.roleName),
    ...employee
  })))
}
