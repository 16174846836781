import { useContext, useEffect } from 'react'
import { initializeFormData } from '../views/CreateNewUser/CreateNewUserFunctions'
import { DataContext } from '../../../context/DataContext'

export const useFetchFormData = () => {
  const { dataState, dataDispatch } = useContext(DataContext)
  const { citiesDocTypesGenders } = dataState.administrative
  const { countriesFetched, citiesFetched, docTypesFetched, gendersFetched } = citiesDocTypesGenders

  useEffect(() => {
    initializeFormData(citiesDocTypesGenders, dataDispatch)
  }, [])

  return {
    countriesFetched,
    citiesFetched,
    docTypesFetched,
    gendersFetched
  }
}
