import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import editIcon from '../../../../../assets/icons/icon_editar.svg'
import './NotAllowedContactRowLawyers.css'
import { ScriptContext } from '../../../../../context/ScriptsContext'

export const NotAllowedContactRowLawyers = ({ item }) => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <div className="notAllowedContactRowLawyers">
      <p className="notAllowedContactRowCell">{item.id}</p>
      <p className="notAllowedContactRowCell">{item.name}</p>
      <p className="notAllowedContactRowCell">{item.claimTo}</p>
      <p className="notAllowedContactRowCell">{item.processType}</p>
      <p className="notAllowedContactRowCell notAllowedContactFifthRowCell">
        <Link to="EditarContactoNoPermitido" state={item}>
          <img
            src={editIcon}
            alt={scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.altImageTxt1 : ''}
          />
        </Link>
      </p>
    </div>
  )
}
