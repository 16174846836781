import React, { useContext } from 'react'

import { DataContext } from '../../context/DataContext'
import { TablaGeneral } from '../../components/TablaGeneral/TablaGeneral'
import { formatDataBaseList } from './DataBaseFunctions'
import { getShoppingHistory } from '../../utils/userQueries'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import { ScriptContext } from '../../context/ScriptsContext'

import './DataBase.css'

export const DataBase = () => {
  const dataContext = useContext(DataContext)
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState } = dataContext
  const { idToken } = dataState.administrative
  const { dataBases } = dataState.administrative.dataBases

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    dataBases,
    'dataBases',
    getShoppingHistory,
    dataTypes.updateDataBases,
    idToken,
    dataContext
  )

  const { page, limit } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    dataBases.list,
    formatDataBaseList,
    page,
    limit
  )

  return (
    <div className='dataBaseContainer'>
      <TablaGeneral
        originalDataBaseList={originalList}
        dataBaseList={listFormatted}
        setDataBaseListFormatted={setListFormatted}
        paginationValues={paginationValues}
        setPaginationValues={setPaginationValues}
        first={scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column1 : ''}
        second={scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column2 : ''}
        third={scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column3 : ''}
        fourth={scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column4 : ''}
        fifth={scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column5 : ''}
        sixth={scriptsSave && scriptsSave.dataBaseStrings ? scriptsSave.dataBaseStrings.column6 : ''}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </div>
  )
}
