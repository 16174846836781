import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { emptySession } from '../../utils/objects'
import { Amplify } from '../../utils/amplify'
const amplify = new Amplify()

export const removeData = ({ navigate, dataDispatch, handleShowInactiveSessionModal }) => {
  if (handleShowInactiveSessionModal) handleShowInactiveSessionModal()

  const clearSession = () => {
    const action = {
      type: dataTypes.logout,
      payload: emptySession
    }
    amplify.cleanToken()
    amplify.signOut()
    dataDispatch(action)
    sessionStorage.clear()
  }

  setTimeout(() => {
    clearSession()
    navigate('/')
  }, 500)
}
