import React from 'react'
import { Route, Routes } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import { DataProvider } from './context/DataContext.js'
import { BaseAdministrativo } from './views/BaseAdministrativo/BaseAdministrativo.jsx'
import { Requests } from './views/Requests/Requests.jsx'
import { EntradasLayout } from './views/Entradas/layout/EntradasLayout.jsx'
import { EntriesList } from './views/Entradas/views/EntriesList/EntriesList.jsx'
import { EntititesLayout } from './views/Entities/layout/EntitiesLayout.jsx'
import { EntitiesList } from './views/Entities/views/EntitiesList/EntitiesList.jsx'
import { CreateEntity } from './views/Entities/views/CreateEntity/CreateEntity.jsx'
import { PQRSLayout } from './views/PQRS/layout/PQRSLayout.jsx'
import { DeleteRequests } from './views/DeleteRequests/DeleteRequests.jsx'
import { DataBase } from './views/DataBase/DataBase.jsx'
import { Login } from './views/Login/Login.jsx'
import { PQRSList } from './views/PQRS/views/PQRSList/PQRSList.jsx'
import { HandlePQRS } from './views/PQRS/views/HandlePQRS/HandlePQRS.jsx'
import { UsersLayout } from './views/DeleteRequests/layout/UsersLayout.jsx'
import { UserDetail } from './views/DeleteRequests/views/UserDetail/UserDetail.jsx'
import { UserEdit } from './views/DeleteRequests/views/UserEdit/UserEdit.jsx'
import { MovementsLayout } from './views/Movements/layout/MovementsLayout.jsx'
import { BolsilloUsuario } from './views/Movements/views/BolsilloUsuario/BolsilloUsuario.jsx'
import { PagoEntidades } from './views/Movements/views/PagoEntidades/PagoEntidades.jsx'

import { PagosTuDataControl } from './views/Movements/views/PagosTuDataControl/PagosTuDataControl.jsx'
import { MovimientosTuDataControl } from './views/Movements/views/MovimientosTuDataControl/MovimientosTuDataControl.jsx'
import { DetalleDB } from './views/DetalleDB/DetalleDB.jsx'
import { DeleteUser } from './views/DeleteUser/DeleteUser.jsx'
import { UnregisteredUsers } from './views/UnregisteredUsers/UnregisteredUsers.jsx'
import { NewEntities } from './views/Entradas/views/NewEntities/NewEntities.jsx'
import { UnregisteredUser } from './views/Entradas/views/UnregisteredUser/UnregisteredUser.jsx'
import { RequestsLayout } from './views/Requests/layout/RequestsLayout.jsx'
import { EntitiesWithoutChannel } from './views/Requests/views/EntitiesWithoutChannel/EntitiesWithoutChannel.jsx'
import { CaseTrackingEntityDetail } from './views/Requests/views/RejectedRequestsDetail/CaseTrackingEntityDetail.jsx'
import { RejectedRequests } from './views/Requests/views/RejectedRequests/RejectedRequests.jsx'
import { UserRequest } from './views/UserRequest/UserRequest.jsx'
import { UserRequestLawyers } from './views/UserRequestLawyers/UserRequestLawyers.jsx'
import { ContactLayout } from './views/Contact/layout/ContactLayout.jsx'
import { NotAllowedContact } from './views/Contact/views/NotAllowedContact/NotAllowedContact.jsx'
import { EditNotAllowedContact } from './views/Contact/views/EditNotAllowedContact/EditNotAllowedContact.jsx'
import { EntityMovements } from './views/Movements/views/EntityMovements/EntityMovements.jsx'
import { UserMovements } from './views/Movements/views/UserMovements/UserMovements.jsx'

import { EntityValidation } from './views/EntityValidation/EntityValidation.jsx'
import { UserValidation } from './views/UserValidation/UserValidation.jsx'
import { BulkUploads } from './views/BulkUploads/BulkUploads.jsx'
import { UserAttachments } from './views/DeleteRequests/UserAttachments/UserAttachments.jsx'
import { DataBaseLayout } from './views/DataBase/layout/DataBaseLayout.jsx'
import { UsersPanelAdministrative } from './views/UsersPanelAdministrative/UsersPanelAdministrative.jsx'
import { CreateNewUser } from './views/UsersAdmin/views/CreateNewUser/CreateNewUser.jsx'
import { NewUser } from './views/UsersAdmin/views/NewUser/NewUser.jsx'
import { EditUser } from './views/UsersAdmin/views/EditUser/EditUser.jsx'
import { UserAdminLayout } from './views/UsersAdmin/layout/UserAdminLayout.jsx'
import { RequestDetail } from './views/Requests/views/RequestDetail/RequestDetail.jsx'
import { CaseTracking } from './views/Requests/views/CaseTracking/CaseTracking.jsx'
import { HistoryPays } from './views/HistoryPays/HistoryPays.jsx'
import { HistoryPaysDetails } from './views/HistoryPays/views/HistoryPays/HistoryPaysDetails.jsx'
import { UserUploads } from './views/BulkUploads/views/UserUploads.jsx'
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute.jsx'
import { PublicRoute } from './components/PublicRoute/PublicRoute.jsx'
import { RejectNotAllowedContact } from './views/Contact/views/RejectNotAllowedContact/RejectNotAllowedContact.jsx'
import { ProceedNotAllowedContact } from './views/Contact/views/ProceedNotAllowedContact/ProceedNotAllowedContact.jsx'
import { EditPQRS } from './views/UserRequest/views/EditPQRS.jsx'

import { Amplify } from 'aws-amplify'
import amplifyconfig from './amplifyconfiguration.json'
import { Amplify as AmplifyClass } from '../src/utils/amplify.jsx'

import './App.css'
import { DocumentsUpdate } from './views/Requests/views/DocumentsUpdate/DocumentsUpdate.jsx'
import { CompaniesAdmin } from './views/CompaniesAdmin/CompaniesAdmin.jsx'
import { EditCompany } from './views/UserRequest/views/EditCompany/EditCompany.jsx'
import { CompaniesLayout } from './views/Companies/layout/CompaniesLayout.jsx'
import { OperativeUsersAdmin } from './views/OperativeUsersAdmin/OperativeUsersAdmin.jsx'
import { CaseTrackingDetail } from './views/Requests/views/CaseTrackingDetail/CaseTrackingDetail.jsx'
import { OperativeUsersAdminDetails } from './views/OperativeUsersAdminDetails/OperativeUsersAdminDetails.jsx'
import { DocumentsUpdateLayout } from './views/Requests/views/DocumentsUpdate/layout/DocumentsUpdateLayout.jsx'
import { ScriptProvider } from './context/ScriptsContext.js'

Amplify.configure(amplifyconfig)

function App () {
  const amplify = new AmplifyClass()
  amplify.cleanToken()
  amplify.sign()

  return (
    <DataProvider>
      <ScriptProvider>
        <div className='App'>
          <Routes>

            <Route path='/*' element={
              <PublicRoute>
                <Routes>
                  <Route path='/' element={<Login />} />
                </Routes>
              </PublicRoute>
            } />

            <Route path='Cuenta/*' element={
              <PrivateRoute>
                <Routes>
                  <Route path='/*' element={<BaseAdministrativo />}>
                    <Route index element={<EntradasLayout />} />
                    <Route path=':id/Entradas/' element={<EntradasLayout />}>
                      <Route index element={<EntriesList />} />
                      <Route path='NuevosUsuarios' element={<EntriesList />} />
                      <Route path='NuevosUsuarios/ValidacionUsuario' element={<UserValidation />} />
                      <Route path='NuevosUsuarios/EliminarUsuario' element={<DeleteUser />} />
                      <Route path='NuevasEntidades' element={<NewEntities />} />
                      <Route path='NuevasEntidades/ValidacionEntidad' element={<EntityValidation />} />
                      <Route path='UsuariosNoRegistrados' element={<UnregisteredUser />} />
                      <Route path='UsuariosNoRegistrados/Response' element={<UnregisteredUsers />} />
                    </Route>
                    <Route path=':id/GestionUsuarios' element={<RequestsLayout />}>
                      <Route index element={<Requests />} />
                      <Route path='RespuestaEntidad' element={<RequestDetail />} />
                      <Route path='SeguimientoCasos' element={<CaseTracking />} />
                      <Route path='SeguimientoCasos/SeguimientoCasosDetalle' element={<CaseTrackingDetail />} />
                      <Route path='SeguimientoCasos/SeguimientoCasosEntidadDetalle' element={<CaseTrackingEntityDetail />} />
                      <Route path='EntidadesSinCanalDeContacto' element={<EntitiesWithoutChannel />} />
                      <Route path='EntidadesSinCanalDeContacto/EntidadesSinCanalDetalle' element={<CaseTrackingEntityDetail />} />
                      <Route path='SolicitudesRechazadas' element={<RejectedRequests />} />
                    </Route>
                    <Route path=':id/Entidades' element={<EntititesLayout />}>
                      <Route index element={<EntitiesList />} />
                      <Route path='CrearEntidad' element={<CreateEntity />} />
                    </Route>
                    <Route path=':id/PQRS' element={<PQRSLayout />}>
                      <Route index element={<PQRSList />} />
                      <Route path='GestionarPQRS' element={<HandlePQRS />} />
                      <Route path='SolicitudDelUsuario' element={<UserRequest />} />
                      <Route path='SolicitudDelUsuario/EditarPQRS' element={<EditPQRS />} />
                      <Route path='SolicitudDelUsuario/AdjuntosDeCambios' element={<UserAttachments />} />
                    </Route>
                    <Route path='SolicitudDelUsuario' element={<UserRequestLawyers />} />
                    <Route path=':id/Movimientos' element={<MovementsLayout />}>
                      <Route index element={<BolsilloUsuario />} />
                      <Route path='BolsilloUsuario' element={<BolsilloUsuario />} />
                      <Route path='MovimientosUsuario' element={<UserMovements />} />
                      <Route path='PagoEntidades' element={<PagoEntidades />} />
                      <Route path='PagoEntidades/MovimientosEntidad' element={<EntityMovements />} />
                      <Route index element={<PagosTuDataControl />} />
                      <Route
                        path='PagosTuDataControl'
                        element={<PagosTuDataControl />}
                      />
                      <Route
                        path='PagosTuDataControl/MovimientosTuDataControl'
                        element={<MovimientosTuDataControl />}
                      />
                      <Route path='HistorialPagos' element={<HistoryPays />} />
                      <Route path='HistorialPagos/DetallePagos' element={<HistoryPaysDetails />} />
                    </Route>
                    <Route path=':id/Empresas' element={<CompaniesLayout />}>
                      <Route index element={<CompaniesAdmin />} />
                      <Route path='DetalleEmpresa' element={<EditCompany />} />
                      <Route path='CrearEmpresa' element={<EditPQRS />} />
                      <Route path='CargasMasivas' element={<BulkUploads />} />
                      <Route path='CargasMasivas/CargaUsuarios' element={<UserUploads />} />
                    </Route>
                    <Route path=':id/ActualizacionInfo' element={<DocumentsUpdateLayout />}>
                      <Route index element={<DocumentsUpdate />} />
                    </Route>
                    <Route path=':id/ActualizacionInfo' element={<ContactLayout />}>
                      <Route index element={<DocumentsUpdate />} />
                    </Route>
                    <Route path=':id/Contacto' element={<ContactLayout />}>
                      <Route index element={<NotAllowedContact />} />
                      <Route path='EditarContactoNoPermitido' element={<EditNotAllowedContact />} />
                      <Route path='Rechazar' element={<RejectNotAllowedContact />} />
                      <Route path='Proceder' element={<ProceedNotAllowedContact />} />
                    </Route>
                    <Route path=':id/Usuarios' element={<UsersLayout />}>
                      <Route index element={<OperativeUsersAdmin />} />
                      <Route path='DetalleUsuario' element={<OperativeUsersAdminDetails />} />
                      <Route path='SolicitudesEliminacion' element={<DeleteRequests />} />
                      <Route path='SolicitudesEliminacion/DetalleSolicitudEliminacion' element={<UserDetail />} />
                      <Route path='EditarUsuario' element={<UserEdit />} />
                      {/* <Route path='CargasMasivas' element={<BulkUploads />} />
                      <Route path='CargasMasivas/CargaUsuarios' element={<UserUploads />} /> */}
                      {/* <Route path='HistorialPagos' element={<HistoryPays />} />
                      <Route path='HistorialPagos/DetallePagos' element={<HistoryPaysDetails />} /> */}
                    </Route>
                    <Route path=':id/UsuariosAdmin' element={<UserAdminLayout />} >
                      <Route index element={<UsersPanelAdministrative />} />
                      <Route path='NuevoUsuario' element={<NewUser />} />
                      <Route path='CrearNuevoUsuario' element={<CreateNewUser />} />
                      <Route path='DatosUsuario' element={<EditUser />} />
                    </Route>
                    <Route path=':id/DB' element={<DataBaseLayout />} >
                      <Route index element={<DataBase />} />
                      <Route path='DetalleDB' element={<DetalleDB />} />
                    </Route>
                  </Route>
                </Routes>
              </PrivateRoute>
            } />

          </Routes>
        </div>
      </ScriptProvider>
    </DataProvider>
  )
}

export default App
