import React, { useContext, useState } from 'react'
import orangeArrowIcon from '../../../../assets/icons/icon_flecha_naranja.svg'
import { handleSubmit, handleUploadFile } from './DocumentsUpdateFunctions'
import { DataContext } from '../../../../context/DataContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import iconBien from '../../../../assets/icons/icon_bien.svg'
import './DocumentsUpdate.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const DocumentsUpdate = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const [showModal, handleShow, handleClose] = useModal()
  const [showInvalidFileModal, handleShowInvalidFileModal, handleCloseInvalidFileModal] = useModal()
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const [showDropdown, setShowDropdown] = useState(false)
  const [documentType, setDocumentType] = useState(null)
  const [file, setFile] = useState(null)

  const handleSelectDocType = (e) => {
    setDocumentType(e.target.value)
    setShowDropdown(false)
    if (e.target.value !== documentType) setFile(null)
  }

  return (
    <section id='DocumentsUpdate'>
      <div className='DocumentsUpdateBox'>
        <p className='DocumentsUpdateFirstTitle'>
          {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.title : ''}
        </p>
        <p className='DocumentsUpdateFirstText'>
          {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.subtitle : ''}
        </p>
        <div>
          <p className='DocumentsUpdateSelectTypeDoc'>
            {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.documentType : ''}
          </p>
          <div className='inputField'>
            <input
              type="text"
              className='DocumentsUpdateInput'
              placeholder={
                !documentType
                  ? (scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.dropdown1DefaultTxt : '')
                  : documentType === 'cedula'
                    ? (scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.legalRepId : '')
                    : (scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.chamberComerce : '')
              }
              onFocus={() => setShowDropdown(true)}
              disabled
            />
            <section id='Dropdown'>
              <div className='DropdownBtn'>
                <button
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="DropdownBtnButton"
                >
                  <img
                    className="DropdownBtnButtonImg"
                    src={orangeArrowIcon}
                    alt={scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.altImageTxt1 : ''}
                  />
                </button>
              </div>
              <div>
                <div className={`DropdownOptions ${!showDropdown ? 'isDisabled' : 'isActive'}`}>
                  <option className="DocumentsUpdateDropdownOption" value={'cedula'} onClick={handleSelectDocType}>
                    <p>{scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.legalRepId : ''}</p>
                  </option>
                  <option className="DocumentsUpdateDropdownOption" value={'certificado'} onClick={handleSelectDocType}>
                    <p>{scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.chamberComerce : ''}</p>
                  </option>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className='DocumentsUpdateButtonsBox'>
        <div className='documentsUpdateButtonContainer'>
          <input
            type="file"
            name='uploadDocument'
            accept='.pdf, .jpg, .png, .jpeg'
            onChange={(e) => handleUploadFile(e, handleShowInvalidFileModal, setFile)}
            id='uploadDocument'
            disabled={!documentType} />
          <label htmlFor="uploadDocument" className={`${!documentType ? 'DocumentsUpdateButtonsBoxBtnDisabled' : 'DocumentsUpdateButtonsBoxBtn'}`} disabled={!documentType}>
            {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.uploadDocument : ''}
          </label>
          <button
            className='DocumentsUpdateButtonsBoxBtn'
            onClick={() => handleSubmit(idToken, dataContext, documentType, file, handleShow, setDocumentType, setFile)}
            disabled={!file}>
            {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.send : ''}
          </button>
          <div className='documentsUpdateFileContainer'>
            {
              !file
                ? <></>
                : (
                  <img
                    className='documentsUpdateFileLogo'
                    src={iconBien}
                    alt={scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.altImageTxt2 : ''}
                  />
                  )
            }
            <p>
              {!file ? '' : file.name}
            </p>
          </div>
        </div>
      </div>
      <Modal isOpen={showInvalidFileModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.invalidFileModalMsg : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-greenBtn' onClick={handleCloseInvalidFileModal}>
            {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.ok : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.modalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={handleClose}>
            {scriptsSave && scriptsSave.updateDocumentsStrings ? scriptsSave.updateDocumentsStrings.accept : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
