import { useState, useEffect } from 'react'
import { dashedFormatDateYYYYMMDD } from '../../../utils/functions'

export const useEditFormValuesInitializer = (
  countriesFetched,
  citiesFetched,
  employeeCredentialEmail,
  expeditionCity,
  genderId,
  identDocId,
  personBirthDt,
  personCity,
  personIdentNum,
  personLastName,
  personName,
  personPhone
) => {
  const initialFormValues = {
    email: employeeCredentialEmail,
    documentNumber: personIdentNum,
    phone: personPhone
  }

  const [formValues, setFormValues] = useState({
    birthdayDate: dashedFormatDateYYYYMMDD(personBirthDt),
    city: '',
    cityId: personCity,
    country: countriesFetched[0]?.countryName,
    email: employeeCredentialEmail,
    gender: genderId === 'Masculino' ? 1 : genderId === 'Femenino' ? 2 : genderId === 'Otro' ? 3 : genderId === 'No binario' ? 4 : genderId === 'Transgénero' ? 5 : genderId === 'Prefiero no decir' ? 6 : null,
    documentExpeditionCity: '',
    documentExpeditionCityId: expeditionCity,
    documentNumber: personIdentNum,
    documentTypeId: String(identDocId),
    lastName: personLastName,
    name: personName,
    password: '',
    passwordConfirmation: '',
    phone: personPhone
  })

  useEffect(() => {
    const updateCityValue = (key, cityId) => {
      const foundCity = citiesFetched.find(city => city.cityId === Number(cityId))
      if (foundCity) {
        setFormValues(prevState => ({
          ...prevState,
          [key]: foundCity.cityName
        }))
      }
    }

    const updateExpeditionCityValue = (key, cityName) => {
      const foundExpeditionCity = citiesFetched.find(city => city.cityName === cityName)
      if (foundExpeditionCity) {
        setFormValues(prevState => ({
          ...prevState,
          [key]: foundExpeditionCity.cityName
        }))
      }
    }

    updateCityValue('city', personCity)
    updateExpeditionCityValue('documentExpeditionCity', expeditionCity)
  }, [citiesFetched])

  return [formValues, setFormValues, initialFormValues]
}
