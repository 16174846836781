import { updatePQRS } from '../../../../utils/userQueries'
import {
  hasRepeatedCharactersAtMiddle,
  validateNoWhiteSpacesAtBeginning
} from '../../../../utils/validateFunctions'

export const validateTextarea = (e, setState) => {
  const text = e.target.value

  if (text.length > 2000) return

  if (validateNoWhiteSpacesAtBeginning(text)) {
    if (hasRepeatedCharactersAtMiddle(text)) {
      return ''
    }
    setState(text)
  }
}

export const handleRejectRequest = async (
  idToken,
  employeeId,
  requestData,
  reason,
  handleShow,
  dataContext
) => {
  const requestBody = {
    employeeId,
    requestId: requestData.requestId,
    response: reason,
    statusId: 3,
    pqrsId: requestData.pqrsId.pqrsId
  }

  const resQuery = await updatePQRS(
    dataContext,
    idToken,
    requestData.requestId,
    requestBody
  )
  if (resQuery.response) {
    handleShow()
  }
}
