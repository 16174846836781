import React, { useContext } from 'react'
import iconUsuario from '../../assets/icons/icon_usuario1.svg'
import { UserRequestDataContainer } from './components/UserRequestDataContainer'
import './UserRequestLawyers.css'
import { NavFooter } from '../../components/NavFooter/NavFooter'
import { ScriptContext } from '../../context/ScriptsContext'

export const UserRequestLawyers = () => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <>
      <section className="userRequestLawyers">
        <div className="userRequestLawyersSubcontainer">
          <div className="userRequestLawyersInfoContainer">
            <div className="userRequestLawyersInfoImageContainer">
              <img
                className="userRequestLawyersInfoImage"
                src={iconUsuario}
                alt={scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.altImageTxt1 : ''}
              />
            </div>
            <div className="userRequestLawyersInfoDataContainer">
              <h2 className="userRequestLawyersInfoTitle">
                {scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.data : ''}
              </h2>
              <div className="userRequestLawyersInfoDataSubcontainer">
                <UserRequestDataContainer
                  title={scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.name : ''}
                  data="Pepito Perez"
                />
                <UserRequestDataContainer
                  title={scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.identification : ''}
                  data="123456789"
                />
                <UserRequestDataContainer
                  title={scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.cel : ''}
                  data="3101234567"
                />
                <UserRequestDataContainer
                  title={scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.identificationType : ''}
                  data="Cédula de ciudadanía"
                />
                <UserRequestDataContainer
                  title={scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.email : ''}
                  data="tucorreo@correo.com"
                />
              </div>
            </div>
          </div>
          <div className="userRequestLawyersComplaintContainer">
            <h2 className="userRequestLawyersComplaintTitle">
              {scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.complain : ''}
            </h2>
            <textarea className="userRequestLawyersComplaintTextarea"></textarea>
          </div>
        </div>
      </section>
      <NavFooter>
        <button className="navFooterButton navFooterButton2">
          {scriptsSave && scriptsSave.userRequestLawyersString ? scriptsSave.userRequestLawyersString.reply : ''}
        </button>
      </NavFooter>
    </>
  )
}
