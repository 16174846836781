import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { passwordEncrypt } from '../../../../utils/functions'
import { editUserString } from '../../../../utils/strings'
import { updateEmployeeCredentialQuery, updatePersonQuery } from '../../../../utils/userQueries'
import { handleRequestButton } from '../../../../utils/validateFunctions'
import {
  onIsValidBirthdayDateBlur,
  onIsValidCityBlur,
  onIsValidCountryBlur,
  onIsValidDocumentNumberBlur,
  onIsValidDocumentTypeId,
  onIsValidEmailBlur,
  onIsValidExpeditionCityBlur,
  onIsValidGender,
  onIsValidLastNameBlur,
  onIsValidNameBlur,
  onIsValidPhoneBlur
} from '../../utils/createAndEditUserMethods'

export const editUserSendForm = async (
  handleShow,
  formValues,
  setFormValues,
  initialFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  countriesFetched,
  citiesFetched,
  maxBirthdayDate,
  minBirthdayDate,
  personId,
  employeeId,
  employeeCredentialId,
  idToken,
  dataContext,
  dataDispatch,
  isRequesting,
  setIsRequesting,
  handleShow3,
  setModal3Message
) => {
  if (isRequesting) return

  const timeOutId1 = handleRequestButton(setIsRequesting)
  const isValidFormData = await validateDataBeforeEditEmployee(
    formValues,
    setFormValues,
    initialFormValues,
    incorrectInputFormat,
    setIncorrectInputFormat,
    countriesFetched,
    citiesFetched,
    maxBirthdayDate,
    minBirthdayDate,
    idToken,
    dataContext
  )
  if (!isValidFormData) return

  const {
    name,
    lastName,
    documentNumber,
    birthdayDate,
    phone,
    documentTypeId,
    gender,
    cityId,
    documentExpeditionCity,
    email,
    passwordConfirmation
  } = formValues

  if (name.length < 3) {
    setModal3Message(editUserString.wrongNameLength)
    handleShow3()
    return
  }

  if (lastName.length < 3) {
    setModal3Message(editUserString.wrongLastnameLength)
    handleShow3()
    return
  }

  const updatePersonQueryBody = {
    personName: name,
    personLastName: lastName,
    personIdentNum: documentNumber,
    personBirthDt: birthdayDate,
    personPhone: phone,
    personActive: true,
    identDocId: documentTypeId,
    genderId: gender,
    personCity: cityId,
    expeditionCity: documentExpeditionCity
  }

  const timeOutId2 = handleRequestButton(setIsRequesting, timeOutId1)
  const updatePersonResponse = await updatePersonQuery(updatePersonQueryBody, personId, idToken, dataContext)
  if (!updatePersonResponse.response) return

  let updateEmployeeCredentialQueryBody = {
    employeeCredentialEmail: email,
    employeeId
  }
  if (passwordConfirmation) {
    const passwordEncrypted = passwordEncrypt(passwordConfirmation)

    updateEmployeeCredentialQueryBody = {
      ...updateEmployeeCredentialQueryBody,
      employeeCredentialPass: passwordEncrypted
    }
  }

  handleRequestButton(setIsRequesting, timeOutId2)
  const updateEmployeeCredentialResponse = await updateEmployeeCredentialQuery(updateEmployeeCredentialQueryBody, employeeCredentialId, idToken, dataContext)
  if (!updateEmployeeCredentialResponse.response) return

  const originalEmployeeCredentialEmail = initialFormValues.email
  const action = {
    type: dataTypes.updateEmployeeInEmployeesList,
    payload: {
      originalEmployeeCredentialEmail,
      employeeCredentialEmail: updateEmployeeCredentialResponse.body.employeeCredentialEmail,
      employeeCredentialId: updateEmployeeCredentialResponse.body.employeeCredentialId,
      employeeId: {
        employeeId: updateEmployeeCredentialResponse.body.employeeId,
        personId: {
          personId: updatePersonResponse.body.personId,
          personName: updatePersonResponse.body.personName,
          personLastName: updatePersonResponse.body.personLastName,
          personIdentNum: updatePersonResponse.body.personIdentNum,
          personPhone: updatePersonResponse.body.personPhone,
          personActive: updatePersonResponse.body.personActive,
          personBirthDt: updatePersonResponse.body.personBirthDt,
          expeditionCity: updatePersonResponse.body.expeditionCity,
          genderId: updatePersonResponse.body.genderId,
          identDocId: updatePersonResponse.body.identDocId,
          personCity: updatePersonResponse.body.personCity
        }
      }
    }
  }

  dataDispatch(action)
  handleShow()
}

// --------------------------------------------------------------------------------------------
// Other methods
export const setClassAndIcon = (roles, roleName, iconPersonCircleAdmin, iconPersonCircleLawyer, iconPersonCircleOper) => {
  const colorClasses = {
    [roles.ADMINISTRATIVO]: 'adminColor',
    [roles.ABOGADO]: 'lawyerColor',
    default: 'operativeColor'
  }
  const colorClass = colorClasses[roleName] || colorClasses.default

  const iconMap = {
    [roles.ADMINISTRATIVO]: iconPersonCircleAdmin,
    [roles.ABOGADO]: iconPersonCircleLawyer,
    default: iconPersonCircleOper
  }
  const iconSrc = iconMap[roleName] || iconMap.default

  return {
    colorClass,
    iconSrc
  }
}

export const closeModal = (navigate, handleClose) => {
  navigate('../')
  handleClose()
}

const validateDataBeforeEditEmployee = async (
  formValues,
  setFormValues,
  initialFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  countriesFetched,
  citiesFetched,
  maxBirthdayDate,
  minBirthdayDate,
  idToken,
  dataContext
) => {
  return (
    onIsValidNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    onIsValidLastNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    await onIsValidEmailBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken, initialFormValues) &&
    onIsValidCountryBlur(formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, countriesFetched) &&
    onIsValidCityBlur(formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, citiesFetched) &&
    // onIsValidPasswordBlur(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    onIsValidDocumentTypeId(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    await onIsValidDocumentNumberBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken, initialFormValues) &&
    onIsValidBirthdayDateBlur(formValues, incorrectInputFormat, setIncorrectInputFormat, maxBirthdayDate, minBirthdayDate) &&
    onIsValidExpeditionCityBlur(formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, citiesFetched) &&
    onIsValidGender(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    await onIsValidPhoneBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken, initialFormValues)
    // onIsValidPasswordConfirmationBlur(formValues, incorrectInputFormat, setIncorrectInputFormat)
  )
}
