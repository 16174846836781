import { useReducer } from 'react'
import { dataReducer } from './dataReducer'
import { emptySession } from '../../../utils/objects'

export const dataInitialState = emptySession

export const useDataReducer = () => {
  const [dataState, dataDispatch] = useReducer(dataReducer, dataInitialState)

  return {
    dataState,
    dataDispatch
  }
}
