import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../../../context/DataContext'
import editIcon from '../../../../assets/icons/icon_editar.svg'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { addElipsisAtEnd } from '../../../../utils/functions'
import { formatUnreadPqrsList, onDetailsClick } from './UnregisteredUserFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './UnregisteredUser.css'

export const UnregisteredUser = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const { newEntries } = dataState.operative
  const { paginationOfUnreadPqrs, pqrsOfUnregisteredUsers } = newEntries
  const pqrsOfUnregisteredUsersList = newEntries.pqrsOfUnregisteredUsers.list

  const { page, limit, totalPages } = paginationOfUnreadPqrs.paginationValues
  const { setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive } = paginationOfUnreadPqrs

  const [listFormatted, setListFormatted] = useState([])

  useEffect(() => {
    formatUnreadPqrsList(pqrsOfUnregisteredUsersList, setListFormatted, page, limit)
  }, [pqrsOfUnregisteredUsersList])

  const navigate = useNavigate()

  return (
    <section>
      <table className='unregisteredUserContainer'>
        <tbody id='unregisteredUserTable'>
          <tr id='unregisteredUserTableHead'>
            <th>{scriptsSave && scriptsSave.unregisteredUserString ? scriptsSave.unregisteredUserString.thead1 : ''}</th>
            <th>
              <Dropdown
                filterBy={'name'}
                list={listFormatted}
                sortType={'string'}
                title={scriptsSave && scriptsSave.unregisteredUserString ? scriptsSave.unregisteredUserString.thead2 : ''}
                type={'orderSelect'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.unregisteredUserString ? scriptsSave.unregisteredUserString.thead3 : ''}</th>
            <th>{scriptsSave && scriptsSave.unregisteredUserString ? scriptsSave.unregisteredUserString.thead4 : ''}</th>
            <th>{scriptsSave && scriptsSave.unregisteredUserString ? scriptsSave.unregisteredUserString.thead5 : ''}</th>
          </tr>
          {
            listFormatted.map(pqr => (
              <tr id='unregisteredUserTableBody' key={pqr.id}>
                <td>{pqr.id}</td>
                <td>{addElipsisAtEnd(pqr.name, 25)}</td>
                <td>{pqr.tel}</td>
                <td>{addElipsisAtEnd(pqr.email, 30)}</td>
                <td>
                  <button onClick={() => onDetailsClick(navigate, pqr, dataDispatch)}>
                    <img
                      src={editIcon}
                      className='editIcon'
                      alt={scriptsSave && scriptsSave.unregisteredUserString ? scriptsSave.unregisteredUserString.altImageTxt1 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Pagination
        currentPage={pqrsOfUnregisteredUsers.currentPage}
        paginationValues={paginationOfUnreadPqrs.paginationValues}
        scrollMeasurement={pqrsOfUnregisteredUsers.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </section>
  )
}
