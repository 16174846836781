import React, { useState, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import { useModal } from '../../hooks/useModal'
import { Modal } from '../../components/Modal/Modal'
import iconOk from '../../assets/icons/icon_bien.svg'
import backIcon from '../../assets/icons/icon_volver.svg'
import {
  onCleanClick,
  onSendClick,
  onSendUserDeletionClick,
  onValidateTextareaChange
} from './DeleteUserFunctions'
import { CharCounter } from '../../components/CharCounter/CharCounter'
import { ScriptContext } from '../../context/ScriptsContext'

import './DeleteUser.css'

export const DeleteUser = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, newEntries } = dataState.operative
  const { userEntryToBeModified } = newEntries
  const { credentialId, email } = userEntryToBeModified

  const navigate = useNavigate()

  const [showModal, handleShow] = useModal()

  const [responseFields, setResponseFields] = useState({
    email,
    description: ''
  })

  const [sendBtnDisabled, setSendBtnDisabled] = useState(true)
  const [isRequesting, setIsRequesting] = useState(false)

  return (
    <>
      <div className='deleteUserSubContainer'>
        <div>
          <h3 className='deleteUserH3'>
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.deleteUser : ''}
          </h3>
          <p className='deleteUserParagraph1'>
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.reasons : ''}
            {userEntryToBeModified.name}
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.next : ''}
          </p>
          <p className='deleteUserParagraph1'>
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.description : ''}
          </p>
          <textarea
            className='deleteUserTextArea'
            onChange={(e) =>
              onValidateTextareaChange(e, setResponseFields, setSendBtnDisabled)
            }
            value={responseFields.description}
          />
          <CharCounter description={responseFields.description}/>
          <div className='deleteUserFooterContainer'>
            <div className='deleteUserFooterSubContainer'>
              <p className='deleteUserParagraph2'>
                {scriptsSave && scriptsSave.compString ? scriptsSave.compString.directMessage : ''}
              </p>
              <div className='deleteUserBtnContainer'>
                <button
                  onClick={() =>
                    onCleanClick(setResponseFields, setSendBtnDisabled)
                  }
                  className='deleteUserButton'
                >
                  {scriptsSave && scriptsSave.compString ? scriptsSave.compString.clean : ''}
                </button>
                <button
                  className={`${isRequesting ? 'deleteUserButtonDisabled' : 'deleteUserButton'}`}
                  disabled={sendBtnDisabled}
                  onClick={() =>
                    onSendClick(
                      credentialId,
                      responseFields,
                      idToken,
                      handleShow,
                      dataContext,
                      isRequesting,
                      setIsRequesting
                    )
                  }
                >
                  {scriptsSave && scriptsSave.compString ? scriptsSave.compString.send : ''}
                </button>
              </div>
            </div>
          </div>
        </div>
        <NavLink
          to='../NuevosUsuarios/ValidacionUsuario'
          className='deleteUserFooterBackLink'
        >
          <img
            src={backIcon}
            alt={scriptsSave && scriptsSave.deleteUserAdminStrings ? scriptsSave.deleteUserAdminStrings.altImageTxt1 : ''}
          />
          <span>{scriptsSave && scriptsSave.compString ? scriptsSave.compString.return : ''}</span>
        </NavLink>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <img
            className='deleteUserIconOK'
            src={iconOk}
            alt={scriptsSave && scriptsSave.deleteUserAdminStrings ? scriptsSave.deleteUserAdminStrings.altImageTxt2 : ''}
          />
          <p>{scriptsSave && scriptsSave.compString ? scriptsSave.compString.wantToFinish : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            onClick={() => onSendUserDeletionClick(navigate)}
            className='Modal-normalBtn'
          >
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.finish : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
