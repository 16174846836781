import React, { useContext, useState } from 'react'
import './CaseTrackingEntityDetail.css'
import { ActiveProcessList } from './components/ActiveProcessList/ActiveProcessList'
import { DocumentationProcess } from './components/DocumentationProcess/DocumentationProcess'
import { SelectProcess } from './components/SelectProcess/SelectProcess'
import { SelectProcessNoContact } from './components/SelectProcessNoContact/SelectProcessNoContact'
import { useLocation } from 'react-router-dom'
import { DataContext } from '../../../../context/DataContext'
import { fetchCaseTrackingEntityDetail } from './CaseTrackingEntityDetailFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const CaseTrackingEntityDetail = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, infoSaved } = dataContext
  const { state: requestData } = useLocation()
  const { view, companyId, userId, requestId, userIdentNum } = requestData || infoSaved

  const { caseTracking } = dataState.lawyer
  const { caseTrackingEntityDetail, entityWithoutChannelContactDetail } = caseTracking

  const [activeViews, setActiveViews] = useState({
    activeProcessListActive: view === 'caseTrackingDetail',
    selectProcess: false,
    documentationProcess: false,
    selectProcessNoContact: view === 'noContact'
  })

  const [demandSelected, setDemandSelected] = useState({})

  const requestObject = view === 'caseTrackingDetail'
    ? { companyId, userId }
    : requestId

  fetchCaseTrackingEntityDetail(dataContext, requestObject, view)
  return (
    <section id='CaseTrackingEntityDetail'>
      <div className='CaseTrackingEntityDetailTSide'>
        <div className='CaseTrackingEntityDetailIconBox'>
          <img
            src={view === 'caseTrackingDetail'
              ? caseTrackingEntityDetail.logo
              : entityWithoutChannelContactDetail.logo
            }
            alt={scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.altImageTxt3 : ''}
          />
        </div>
        <div className='CaseTrackingEntityDetailInfo'>
          <h2 className='CaseTrackingEntityDetailTitle'>
            {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.title1 : ''}
          </h2>
          <div className='CaseTrackingEntityDetailInfoContent'>
            <div>
              <p className='CaseTrackingEntityDetailCompanyName'>
                {view === 'caseTrackingDetail'
                  ? caseTrackingEntityDetail.name
                  : entityWithoutChannelContactDetail.name
                }
              </p>
              {view === 'caseTrackingDetail' &&
                <p className='CaseTrackingEntityDetailText'>
                  {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.legalRepresentative : ''}
                  {caseTrackingEntityDetail?.legalName}
                </p>
              }
              <p className='CaseTrackingEntityDetailText'>
                {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.nit : ''}
                {view === 'caseTrackingDetail'
                  ? caseTrackingEntityDetail.nit
                  : entityWithoutChannelContactDetail.nit
                }
              </p>
            </div>
            <div>
              {view === 'caseTrackingDetail' &&
                <>
                  <p className='CaseTrackingEntityDetailText'>{caseTrackingEntityDetail.email}</p>
                  <p className='CaseTrackingEntityDetailText'>
                    {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.phone : ''}
                    {`+57 ${caseTrackingEntityDetail.phone}`}
                  </p>
                  <p className='CaseTrackingEntityDetailText'>
                    {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.contact : ''}
                    {caseTrackingEntityDetail?.contactName}
                  </p>
                </>
              }
              {view === 'noContact' &&
                <p className='CaseTrackingEntityDetailText'>
                  {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.noContactChannel : ''}
                </p>
              }
            </div>
          </div>
        </div>
      </div>
      <ActiveProcessList
        isActive={activeViews.activeProcessListActive}
        setActiveViews={setActiveViews}
        demands={caseTrackingEntityDetail.demands}
        setDemandSelected={setDemandSelected}
      />
      <SelectProcess
        isActive={activeViews.selectProcess}
        requestData={{ userId, companyId, userIdentNum, companyNit: caseTrackingEntityDetail.nit }}
        demands={caseTrackingEntityDetail.demands}
        companyInfo={caseTrackingEntityDetail}
      />
      <DocumentationProcess
        isActive={activeViews.documentationProcess}
        setActiveViews={setActiveViews}
        demandSelected={demandSelected}
      />
      <SelectProcessNoContact
        isActive={activeViews.selectProcessNoContact}
        demandId={requestObject}
        entityWithoutChannelContactDetail={entityWithoutChannelContactDetail}
      />
    </section>
  )
}
