import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import { Modal } from '../../components/Modal/Modal'
import { ImageModal } from '../../components/ImageModal/ImageModal'
import { useModal } from '../../hooks/useModal'
import { NavFooter } from '../../components/NavFooter/NavFooter'
import iconOk from '../../assets/icons/icon_bien.svg'
import userDocument from '../../assets/images/IDCard.png'
import { useFetchUserNotActive } from './hooks/useFetchUserNotActive'
import { setDocumentUrl } from '../../utils/functions'
import {
  handleClickChangeButtonContent,
  handleClick1,
  handleClick2,
  onContinueModalBtnClick
} from './UserValidationFunctions'

import './UserValidation.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const UserValidation = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken } = dataState.operative

  const [showModal, handleShow, handleClose] = useModal()
  const [selectedModal, setSelectedModal] = useState('')
  const [buttonContent, setButtonContent] = useState('Cara posterior')
  const [url, setUrl] = useState()
  const [userDocumentPathObject, setUserDocumentPathObject] = useState([])
  const [isImage, setIsImage] = useState(true)
  const {
    name,
    cell,
    email,
    documentIdType,
    documentIdNum,
    gender,
    documentPath,
    userId
  } = useFetchUserNotActive()

  const navigate = useNavigate()

  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    setDocumentUrl(documentPath, setUserDocumentPathObject, setUrl, setIsImage)
  }, [documentPath])

  return (
    <>
      <div className='userValidationMainContainer'>
        <div className='userValidationSubContainer1'>
          <div className='userValidationInfoContainer'>
            <div className='userValidationInfoSubContainer'>
              <p className='userValidationInfoContainerTitle'>
                {scriptsSave && scriptsSave.userString ? scriptsSave.userString.userName : ''}
              </p>
              <p className='userValidationInfoContainerP'>{name}</p>
            </div>
            <div className='userValidationInfoSubContainer'>
              <p className='userValidationInfoContainerTitle'>
                {scriptsSave && scriptsSave.userString ? scriptsSave.userString.mobile : ''}
              </p>
              <p className='userValidationInfoContainerP'>{cell}</p>
            </div>
            <div className='userValidationInfoSubContainer'>
              <p className='userValidationInfoContainerTitle'>
                {scriptsSave && scriptsSave.userString ? scriptsSave.userString.mail : ''}
              </p>
              <p className='userValidationInfoContainerP'>{email}</p>
            </div>
            <div className='userValidationInfoSubContainer'>
              <p className='userValidationInfoContainerTitle'>
                {scriptsSave && scriptsSave.userString ? scriptsSave.userString.id : ''}
              </p>
              <p className='userValidationInfoContainerP'>{documentIdNum}</p>
            </div>
            <div className='userValidationInfoSubContainer'>
              <p className='userValidationInfoContainerTitle'>
                {scriptsSave && scriptsSave.userString ? scriptsSave.userString.idType : ''}
              </p>
              <p className='userValidationInfoContainerP'>{documentIdType}</p>
            </div>
            <div className='userValidationInfoSubContainer'>
              <p className='userValidationInfoContainerTitle'>
                {scriptsSave && scriptsSave.userString ? scriptsSave.userString.gender : ''}
              </p>
              <p className='userValidationInfoContainerP'>{gender}</p>
            </div>
          </div>
          <div className='userValidationImageContainer'>
            <div className='userValidationInfoSubContainer'>
              <img
                className='userDocumentImage'
                src={userDocument}
                alt={scriptsSave && scriptsSave.userString ? scriptsSave.userString.altImageTxt1 : ''}
              />
              <div className='userValidationButtonGroup'>
                <button
                  className='userValidationButton'
                  onClick={() => {
                    if (!isImage && url) {
                      window.open(url, '_blank')
                    } else {
                      handleClick1(handleShow, setSelectedModal)
                    }
                  }}
                >
                  {scriptsSave && scriptsSave.userString ? scriptsSave.userString.seeId : ''}
                </button>
              </div>
            </div>
          </div>
        </div>
        <NavFooter returnPath='../'>
          <NavLink
            to='../NuevosUsuarios/EliminarUsuario'
            className='navFooterButton navFooterButton1Alternative'
          >
            {scriptsSave && scriptsSave.userString ? scriptsSave.userString.deleteUser : ''}
          </NavLink>
          <button
            onClick={() =>
              handleClick2(
                idToken,
                userId,
                handleShow,
                setSelectedModal,
                dataContext,
                isRequesting,
                setIsRequesting
              )
            }
            className={`${isRequesting ? 'navFooterButton2Disabled' : 'navFooterButton navFooterButton2'}`}
          >
            {scriptsSave && scriptsSave.userString ? scriptsSave.userString.confirmUser : ''}
          </button>
        </NavFooter>
      </div>
      {
        selectedModal === 'activaModalImagen'
          ? (
            <ImageModal isOpen={showModal} title={scriptsSave && scriptsSave.userString ? scriptsSave.userString.modalTitle1 : ''} imageSrc={url}>
              <ImageModal.Buttons buttonContainerClass='imageModalButtonsOperativo'>
                <button
                  className='editNotAllowedContactModalBtn'
                  onClick={handleClose}
                >
                  {scriptsSave && scriptsSave.userString ? scriptsSave.userString.close : ''}
                </button>
                {url && url.length > 1
                  ? (
                    <button
                      onClick={(event) =>
                        handleClickChangeButtonContent(
                          event,
                          buttonContent,
                          setButtonContent,
                          userDocumentPathObject,
                          setUrl
                        )
                      }
                      className='editNotAllowedContactModalBtn'
                    >
                      {buttonContent}
                    </button>
                    )
                  : null}
              </ImageModal.Buttons>
            </ImageModal>
            )
          : selectedModal === 'activaModalConfirmacion'
            ? (
              <Modal isOpen={showModal}>
                <Modal.Content>
                  <img
                    className='userValidationModalIcon'
                    src={iconOk}
                    alt={scriptsSave && scriptsSave.userString ? scriptsSave.userString.altImageTxt2 : ''}
                  />
                  <p>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.userConfirmation : ''}</p>
                </Modal.Content>
                <Modal.Buttons>
                  <button
                    className='Modal-orangeBtn'
                    onClick={() => onContinueModalBtnClick(navigate, handleClose)}
                  >
                    {scriptsSave && scriptsSave.userString ? scriptsSave.userString.continue : ''}
                  </button>
                </Modal.Buttons>
              </Modal>
              )
            : null}
    </>
  )
}
