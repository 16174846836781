import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../context/DataContext'
import { useLocation } from 'react-router-dom'

export const usePaginateQuery = (
  contextObjectState,
  payloadProp,
  query,
  dataType,
  idToken,
  dataContext,
  queryBody = null
) => {
  const { pathname } = useLocation()
  const { dataDispatch } = useContext(DataContext)
  const numberOfItemsToFetch = 6

  const [paginationValues, setPaginationValues] = useState({
    page: contextObjectState.currentPage,
    limit: numberOfItemsToFetch,
    scrollMeasurement: contextObjectState.scrollMeasurement,
    totalItems: null,
    totalPages: null
  })
  const [isNextActive, setIsNextActive] = useState(true)
  const [isPrevActive, setIsPrevActive] = useState(true)
  const { page, limit } = paginationValues

  const getFetchData = async () => {
    const queryResponse = queryBody === null
      ? await query(idToken, dataContext, page, limit)
      : await query(idToken, dataContext, queryBody, page, limit)
    if (queryResponse.response) {
      setPaginationValues({
        ...paginationValues,
        totalItems: queryResponse.body.meta.totalItems,
        totalPages: queryResponse.body.meta.totalPages
      })
      const action = {
        type: dataType,
        payload: {
          [payloadProp]: {
            currentPage: page,
            list: queryResponse.body.items,
            scrollMeasurement: paginationValues.scrollMeasurement
          }
        }
      }
      dataDispatch(action)
    }
    return queryResponse
  }

  if (payloadProp === 'pqrsOfUnregisteredUsers') {
    useEffect(() => {
      const action = {
        type: dataType,
        payload: {
          [payloadProp]: {
            ...contextObjectState,
            currentPage: paginationValues.page,
            scrollMeasurement: paginationValues.scrollMeasurement
          }
        }
      }
      dataDispatch(action)
      const fetch = async () => {
        const fetchedData = await getFetchData()
        if (fetchedData?.body?.meta?.itemCount === 0 && page > 0) {
          setPaginationValues({
            ...paginationValues,
            page: page - 1
          })
        }
        if (!isNextActive) {
          setIsNextActive(true)
        }
        if (!isPrevActive) {
          setIsPrevActive(true)
        }
      }
      fetch()
    }, [pathname, paginationValues.page])
  } else {
    useEffect(() => {
      const action = {
        type: dataType,
        payload: {
          [payloadProp]: {
            ...contextObjectState,
            currentPage: paginationValues.page,
            scrollMeasurement: paginationValues.scrollMeasurement
          }
        }
      }
      dataDispatch(action)
      const fetch = async () => {
        const fetchedData = await getFetchData()
        if (fetchedData?.body?.meta?.itemCount === 0 && page > 0) {
          setPaginationValues({
            ...paginationValues,
            page: page - 1
          })
        }
        if (!isNextActive) {
          setIsNextActive(true)
        }
        if (!isPrevActive) {
          setIsPrevActive(true)
        }
      }
      fetch()
    }, [page, queryBody])
  }
  return [
    paginationValues,
    setPaginationValues,
    isNextActive,
    setIsNextActive,
    isPrevActive,
    setIsPrevActive
  ]
}
