import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../utils/functions'
import { getBuyerQuery, getUserQuery } from '../../utils/queries'

export const formatDeleteRequestsList = (deleteRequestsList, setDeleteRequestsList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(deleteRequestsList)
  const currentItem = page

  setDeleteRequestsList(deleteRequestsList.map((user, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      buyerId: user.buyerId,
      userId: user.userId,
      nameInList: user.buyerId !== null ? user.companyId.companyComertialName : `${user.personId.personName} ${user.personId.personLastName}`,
      identNumInList: user.buyerId !== null ? user.companyId.companyNit : user.personId.personIdentNum,
      userType: user.buyerId !== null ? 'Usuario Empresa' : 'Usuario Persona',
      pqrsName: user.pqrsId,
      requestId: user.requestId
    }
  }))
}

export const onDetailClick = async (
  navigate,
  user,
  idToken,
  dataDispatch,
  dataContext
) => {
  if (user.buyerId) {
    const getBuyerQueryResponse = await getBuyerQuery(dataContext, user.requestId, idToken)
    if (getBuyerQueryResponse.response) {
      user.buyerCredentialId = getBuyerQueryResponse.body.buyerCredentialId
      user.email = getBuyerQueryResponse.body.companyEmail
      user.identType = getBuyerQueryResponse.body.identDocName
      user.name = getBuyerQueryResponse.body.buyerLegalRepresentative
      user.identNum = getBuyerQueryResponse.body.buyerRepresentativeIdNum
      user.phone = getBuyerQueryResponse.body.companyPhone
    }
  } else {
    const getUserQueryResponse = await getUserQuery(dataContext, user.requestId, idToken)
    if (getUserQueryResponse.response) {
      user.credentialId = getUserQueryResponse.body.credentialId.credentialId
      user.email = getUserQueryResponse.body.credentialId.credentialEmail
      user.identType = getUserQueryResponse.body.personId.identDocName
      user.name = `${getUserQueryResponse.body.personId.personName} ${getUserQueryResponse.body.personId.personLastName}`
      user.identNum = getUserQueryResponse.body.personId.personIdentNum
      user.phone = getUserQueryResponse.body.personId.personPhone
      user.personId = getUserQueryResponse.body.personId.personId
      user.requestContent = getUserQueryResponse.body.content
      user.createDate = getUserQueryResponse.body.createDt
      user.balance = getUserQueryResponse.body.total
      user.planName = getUserQueryResponse.body.planId.planName
    }
  }

  const action = {
    type: dataTypes.setUpdateDeleteRequests,
    payload: user
  }
  dataDispatch(action)

  navigate('DetalleSolicitudEliminacion')
}
