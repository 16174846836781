import React, { useContext } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import noContactEntitiesIcon from '../../../assets/icons/icon_nocontactentity.svg'

import './ContactLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const ContactLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { id } = useParams()
  return (
    <section className='contactLayout'>
      <div className='headerContainerContact'>
        <div className='ContactAdminContainer'>
          <p className='ContactAdminParagraph'>
            {scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.notAllowedContact : ''}
          </p>
        </div>
        <Link
          className="entriesHeaderContainerButton"
          to={`../${id}/GestionUsuarios/EntidadesSinCanalDeContacto`}
        >
          <img
            alt={scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.altImageTxt1 : ''}
            className="entriesHeaderContainerImg"
            src={noContactEntitiesIcon}
          />
        </Link>
      </div>
      <hr className='hrContactAdmin' />
      <Outlet />
    </section>
  )
}
