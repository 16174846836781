import { updatePQRS } from '../../utils/userQueries'
import {
  hasRepeatedCharactersAtMiddle,
  validateNoWhiteSpacesAtBeginning
} from '../../utils/validateFunctions'

export const validateTextarea = (e, state, setState) => {
  const text = e.target.value

  if (text.length > 1800) return

  if (validateNoWhiteSpacesAtBeginning(text)) {
    if (hasRepeatedCharactersAtMiddle(text)) {
      return ''
    }
    setState({ ...state, response: text })
  }
}

export const handleResponsePQRS = async (
  pqrsDetail,
  responseFields,
  dataContext,
  idToken,
  handleShow,
  setIsRequesting
) => {
  setIsRequesting(true)
  const pqrsReqBody = {
    userId: pqrsDetail.userId.userId,
    pqrsId: pqrsDetail.pqrsId.pqrsId,
    response: responseFields.response,
    statusId: responseFields.status,
    employeeId: responseFields.employeeId
  }

  const requestId = pqrsDetail.requestId

  const response = await updatePQRS(
    dataContext,
    idToken,
    requestId,
    pqrsReqBody
  )
  if (response.response) {
    setIsRequesting(false)
    handleShow()
  }
}
