import React, { useContext, useEffect, useState } from 'react'
import parse from 'html-react-parser'

import stateIcon from '../../../../assets/icons/icon_enproceso.svg'
import watchIcon from '../../../../assets/icons/icon_ver-operativo.svg'
import { Modal } from '../../../../components/Modal/Modal'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../../../hooks/useModal'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { getAllUsersNotActive } from '../../../../utils/userQueries'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { DataContext } from '../../../../context/DataContext'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { formatEntriesList, onDetailsClick } from './EntriesListFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './EntriesList.css'
import { addElipsisAtEnd } from '../../../../utils/functions'

export const EntriesList = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, newEntries } = dataState.operative
  const { users } = newEntries
  const newUserEntriesList = newEntries.users.list

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    users,
    'users',
    getAllUsersNotActive,
    dataTypes.updateNewUsersEntries,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const [listFormatted, setListFormatted] = useState([])

  useEffect(() => {
    formatEntriesList(newUserEntriesList, setListFormatted, page, limit)
  }, [newUserEntriesList])

  const navigate = useNavigate()

  const [showModal, handleClose] = useModal()

  return (
    <section className='entriesListContainer'>
      <table id='entriesTable'>
        <thead>
          <tr id='entriesTableHead'>
            <th>{scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead1 : ''}</th>
            <th className='entriesListHeadCell'>
              <Dropdown
                filterBy={'identNum'}
                list={listFormatted}
                sortType={'number'}
                title={scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead2 : ''}
                type={'orderSelect'}
                updateList={setListFormatted}
              />
            </th>
            <th className='entriesListHeadCell'>
              <Dropdown
                filterBy={'name'}
                list={listFormatted}
                sortType={'string'}
                title={scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead3 : ''}
                type={'orderSelect'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead4 : ''}</th>
            <th>{scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead5 : ''}</th>
            <th>{scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead6 : ''}</th>
            <th>{scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.thead7 : ''}</th>
          </tr>
        </thead>
        <tbody className='carousel-inner'>
          {
            listFormatted.map(entry => (
              <tr id='entriesTableBody' key={entry.id}>
                <td>{entry.id}</td>
                <td>{entry.identNum}</td>
                <td>{addElipsisAtEnd(entry.name, 15)}</td>
                <td>{entry.phone}</td>
                <td>{addElipsisAtEnd(entry.email, 25)}</td>
                <td>
                  <img
                    alt={scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.altImageTxt1 : ''}
                    className='stateIcon'
                    src={stateIcon}
                  />
                </td>
                <td>
                  <button
                    onClick={() => onDetailsClick(navigate, entry, dataDispatch)}
                  >
                    <img
                      alt={scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.altImageTxt2 : ''}
                      className='editIcon'
                      src={watchIcon}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Pagination
        currentPage={users.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={users.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />

      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.entriesListString ? parse(scriptsSave.entriesListString.modalDescription) : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-normalBtn' onClick={handleClose}>
            {scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.cancel : ''}
          </button>
          <button className='Modal-orangeBtn'>
            {scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
