import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import editIcon from '../../../../assets/icons/icon_edit.svg'
import clipIcon from '../../../../assets/icons/icon-clip.svg'
import './HandlePQRS.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const HandlePQRS = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, handleShow2] = useModal()
  return (
    <section id="handlePQRS">
      <div id="handlePQRSContent">
        <div id="handlePQRSHeader">
          <h2>{state}</h2>
          <div id="handlePQRSHeaderAsignedTo">
            <p>{`${scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.assignedTo : ''} ${'Pepito Perez'}`}</p>
            <p></p>
            <img
              src={editIcon}
              className="editIcon"
              alt={scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.altImageTxt1 : ''}
            />
          </div>
          <div id="handlePQRSHeaderStatus">
            <span id="handlePQRSHeaderStatusSpan">{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.rejected : ''}</span>
            <img
              src={editIcon}
              className="editIcon"
              alt={scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.altImageTxt2 : ''}
            />
          </div>
        </div>
        <div id="handlePQRSGrid">
          <div>
            <p>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.applicationDescription : ''}</p>
            <textarea
              name="PQRSTextarea"
              cols="30"
              rows="6"
              className="handlePQRSTextareaReadOnly"
            >
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.loremIpsum : ''}
            </textarea>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.answer : ''}</p>
            <textarea
              name="PQRSTextarea"
              cols="30"
              rows="6"
              className="handlePQRSTextareaReadOnly"
            >
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.loremIpsum : ''}
            </textarea>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.upDate1 : ''}</p>
            <textarea
              name="PQRSTextarea"
              cols="30"
              rows="6"
              className="handlePQRSTextarea"
              placeholder={scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.placeholder1 : ''}
            ></textarea>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.requestLetter : ''}</p>
            <textarea
              name="PQRSTextarea"
              cols="30"
              rows="3"
              className="handlePQRSTextareaQueryCard"
            ></textarea>
            <span>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.fileUpload : ''}</span>
            <img
              src={clipIcon}
              alt={scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.altImageTxt3 : ''}
              className="clipIcon"
            />
          </div>
        </div>
        <div className="entries-footer">
          <Link to={-1} className="entriesFooterBackLink">
            <img
              src={backIcon}
              alt={scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.altImageTxt4 : ''}
            />
            <span>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.goBack : ''}</span>
          </Link>
          <div>
            <button onClick={handleShow} className="cancelFooterButton">
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.cancel : ''}
            </button>
            <button onClick={handleShow2} className="entriesFooterButton">
              {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.upDate2 : ''}
            </button>
          </div>
        </div>
        <Modal isOpen={showModal}>
          <Modal.Content>
            {/* <p>
              {handlePQRSString.modalContent1} <strong>{handlePQRSString.modalContent2}</strong> {handlePQRSString.modalContent3}
              <strong> {handlePQRSString.modalContent4}</strong> {handlePQRSString.modalContent5}
              <br />
              {handlePQRSString.continue}
            </p> */}
            <p>
              {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent1 : ''} <strong>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent2 : ''}</strong> {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent3 : ''}
              <strong> {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent4 : ''}</strong> {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent5 : ''}
              <br />
              {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.continue : ''}
            </p>
          </Modal.Content>
          <Modal.Buttons>
            <button onClick={handleClose} className="Modal-normalBtn">
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.cancel : ''}
            </button>
            <button onClick={() => navigate('../')} className="Modal-orangeBtn">
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.continue : ''}
            </button>
          </Modal.Buttons>
        </Modal>
        <Modal isOpen={showModal2}>
          <Modal.Content>
            <p>
              {scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent6 : ''} <strong>{state} </strong>{scriptsSave && scriptsSave.handlePQRSString ? scriptsSave.handlePQRSString.modalContent7 : ''}
            </p>
          </Modal.Content>
          <Modal.Buttons>
            <button onClick={() => navigate('../')} className="Modal-orangeBtn">
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.continue : ''}
            </button>
          </Modal.Buttons>
        </Modal>
      </div>
    </section>
  )
}
