import React, { useContext } from 'react'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { Link } from 'react-router-dom'

import './CreateEntity.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const CreateEntity = () => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <section id="CreateEntity">
      <div id="CreateEntityContent">
        <h2 id="CreateEntityTitle">
          {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeData : ''}
        </h2>
        <div id="createEntityGrid">
          <label className="createEntityInputBox">
            <p className="createEntityText">
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.businessName : ''}
            </p>
            <input
              type="text"
              className="createEntityInput"
              placeholder={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeBusinessName : ''}
            />
          </label>
          <label className="createEntityInputBox">
            <p className="createEntityText">
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.tradename : ''}
            </p>
            <input
              type="text"
              className="createEntityInput"
              placeholder={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeTradename : ''}
            />
          </label>
          <label className="createEntityInputBox">
            <p className="createEntityText">
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.nit : ''}
            </p>
            <input
              type="text"
              className="createEntityInput"
              placeholder={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeNit : ''}
            />
          </label>
          <label className="createEntityInputBox">
            <p className="createEntityText">
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.email : ''}
            </p>
            <input
              type="text"
              className="createEntityInput"
              placeholder={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeEmail : ''}
            />
          </label>
          <label className="createEntityInputBox">
            <p className="createEntityText">
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.cel : ''}
            </p>
            <input
              type="text"
              className="createEntityInput"
              placeholder={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeCel : ''}
            />
          </label>
          <label className="createEntityInputBox">
            <p className="createEntityText">
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.tel : ''}
            </p>
            <input
              type="text"
              className="createEntityInput"
              placeholder={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.typeTel : ''}
            />
          </label>
        </div>
        {/* Componente Reutilizado */}
        <div className="entries-footer">
          <Link to={-1} className="entriesFooterBackLink">
            <img
              src={backIcon}
              alt={scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.altImageTxt1 : ''}
            />
            <span>
              {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.back : ''}
            </span>
          </Link>
          <button className="entriesFooterButton">
            {scriptsSave && scriptsSave.createEntityString ? scriptsSave.createEntityString.create : ''}
          </button>
        </div>
      </div>
    </section>
  )
}
