import React from 'react'
import { Link } from 'react-router-dom'

import './PQRSBodyRow.css'

export const PQRSBodyRow = ({ pqrs, editIcon }) => {
  return (
    <tr className='pqrsBodyRow'>
      <td className='pqrsBodyRowCell'>{pqrs.id}</td>
      <td className='pqrsBodyRowCell'>{pqrs.name}</td>
      <td className='pqrsBodyRowCell'>{pqrs.pqrs}</td>
      <td className='pqrsBodyRowCell'>{pqrs.user}</td>
      <td className='pqrsBodyRowCell textCenter'>
        <Link to='SolicitudDelUsuario' state={{ requestId: pqrs.requestId, isUser: pqrs.isUser } }>
          <img
            src={editIcon}
            className='pqrsBodyRowEditButton'
            alt='icono editar'
          />
        </Link>
      </td>
    </tr>
  )
}
