import React, { useContext, useRef, useState } from 'react'

import orangeArrowIcon from '../../assets/icons/icon_flecha_naranja.svg'
import lupaIcon from '../../assets/icons/icon_lupa.svg'
import upArrowIcon from '../../assets/icons/upArrowFilter.png'
import upArrowWhiteIcon from '../../assets/icons/upArrowWhiteFilter.png'
import downArrowIcon from '../../assets/icons/downArrowFilter.png'
import downArrowWhiteIcon from '../../assets/icons/downArrowWhiteFilter.png'
import { onAscendantSelectedOptionClick, onSearchInputChange, onSearchInputClick, onSelectedOptionClick } from './DropdownFunctions'

import './Dropdown.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const Dropdown = ({
  title,
  type,
  options,
  list,
  updateList,
  filterBy,
  sortType,
  isNumber,
  newQueryBody,
  setQueryBody,
  setNewValue
}) => {
  const [showIdDropdown, setShowIdDropdown] = useState(false)
  const dropdownRef = useRef(null)

  // useEffect(() => {
  //   const handleOutsideClick = (e) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(e.target)) setShowIdDropdown(false)
  //   }

  //   document.addEventListener('mousedown', handleOutsideClick)
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick)
  //   }
  // }, [])

  const { scriptsSave } = useContext(ScriptContext)

  return (
    <section id='Dropdown' ref={dropdownRef}>
      <div className='entriesListTheadDropdownBtn'>
        <p>{title}</p>
        {type &&
          <button
            className='dropdown-button_EntriesList'
            onClick={() => setShowIdDropdown(!showIdDropdown)}
          >
            <img
              alt={scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.altImageTxt1 : ''}
              className='dropdown-icon_EntriesList'
              src={orangeArrowIcon}
            />
          </button>
        }
      </div>
      {
        type === 'select' &&
        <SelectType
          options={options}
          setShow={setShowIdDropdown}
          show={showIdDropdown}
          list={list}
          updateList={updateList}
          filterBy={filterBy}
        />
      }
      {
        type === 'search' &&
        <SearchType
          show={showIdDropdown}
          list={list}
          updateList={updateList}
          filterBy={filterBy}
          isNumber={isNumber}
          newQueryBody={newQueryBody}
          setQueryBody={setQueryBody}
          setNewValue={setNewValue}
        />
      }
      {
        type === 'orderSelect' &&
        <AscendantDescendantType
          setShow={setShowIdDropdown}
          show={showIdDropdown}
          list={list}
          updateList={updateList}
          filterBy={filterBy}
          sortType={sortType}
        />
      }
    </section>
  )
}

const SelectType = ({
  show,
  setShow,
  options = [],
  list,
  updateList,
  filterBy
}) => {
  const [selectedOption, setSelectedOption] = useState(null)

  return (
    <div className={`requestGridDropdown ${!show ? 'isDisabled' : 'isActive'}`}>
      {
        options.map((option, index) => (
          <div
            className={`${selectedOption === option && 'dropdown-item_EntriesList-selected'} dropdown-item_EntriesList`}
            key={index}
            onClick={() => onSelectedOptionClick(
              option,
              setSelectedOption,
              list,
              updateList,
              filterBy,
              show,
              setShow
            )}
          >
            <p>{option}</p>
          </div>
        ))
      }
    </div>
  )
}

const SearchType = ({
  show,
  newQueryBody,
  setQueryBody,
  setNewValue
}) => {
  const { scriptsSave } = useContext(ScriptContext)
  const [inputValue, setInputValue] = useState('')
  if (!inputValue || !show) setQueryBody(null)

  return (
    <div className={`requestGridDropdown ${!show ? 'isDisabled' : 'isActive'}`}>
      <input
        className='requestGridDropdownInput'
        onChange={(e) => onSearchInputChange(
          e,
          setInputValue,
          setNewValue
        )}
        placeholder={scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.placeholderTxt1 : ''}
        value={inputValue}
      />
      <button
        onClick={() => onSearchInputClick(inputValue, newQueryBody, setQueryBody)}
      >
        <img
          src={lupaIcon}
          alt={scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.altImageTxt2 : ''}
        />
      </button>
    </div>
  )
}

const AscendantDescendantType = ({
  show,
  setShow,
  list,
  updateList,
  filterBy,
  sortType
}) => {
  const { scriptsSave } = useContext(ScriptContext)
  const [selectedOption, setSelectedOption] = useState(null)
  const option1 = scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.sortTypeOptionTxt1 : ''
  const option2 = scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.sortTypeOptionTxt2 : ''
  const option3 = scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.sortTypeOptionTxt3 : ''
  const option4 = scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.sortTypeOptionTxt4 : ''
  const options = sortType === 'date'
    ? [
        option1,
        option2
      ]
    : [
        option3,
        option4
      ]

  const optionIcons = [upArrowIcon, downArrowIcon]
  const optionWhiteIcons = [upArrowWhiteIcon, downArrowWhiteIcon]

  return (
    <div className={`requestGridDropdown ${!show ? 'isDisabled' : 'isActive'}`}>
      {
        options.map((option, index) => {
          return (
            <div
              className={`${selectedOption === option && 'dropdown-item_EntriesList-selected'} dropdown-item_EntriesList`}
              key={index}
              onClick={() => onAscendantSelectedOptionClick(
                option,
                setSelectedOption,
                list,
                updateList,
                filterBy,
                sortType,
                show,
                setShow,
                option1,
                option2,
                option3,
                option4
              )}
            >
              <p>{option}</p>
              <img
                className='dropdownIconItem'
                src={optionIcons[index]}
                alt={scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.altImageTxt3 : ''}
              />
              <img
                className='dropdownWhiteIconItem'
                src={optionWhiteIcons[index]}
                alt={scriptsSave && scriptsSave.dropdownAdminStrings ? scriptsSave.dropdownAdminStrings.altImageTxt4 : ''}
              />
            </div>
          )
        })
      }
    </div>
  )
}
