import React, { Children, cloneElement, useContext, useEffect, useRef, useState } from 'react'
import greyArrow from '../../assets/icons/icon_flecha_gris.svg'
import './CustomSelect.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const CustomSelect = ({ children, selector, defaultValue }) => {
  const { scriptsSave } = useContext(ScriptContext)
  const [dropdownActive, setdropdownActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectDefaultValue, setSelectDefaultValue] = useState({})
  const selectRef = useRef(null)

  const handleOptionSelect = (option) => {
    selector && selector(option.value)
    setSelectedOption(option.children)
    setdropdownActive(false)
  }

  const handleDefaultValue = () => {
    const childList = Children.map(children, (child) => child.props)
    let defValue = childList.find((props) => props.value === defaultValue)

    if (!defValue) {
      defValue = defaultValue
    }

    setSelectDefaultValue(defValue)
  }

  useEffect(() => {
    handleDefaultValue()
  }, [defaultValue, children])

  useEffect(() => {
    setSelectedOption(null)
  }, [defaultValue])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setdropdownActive(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <section id="CustomSelect" ref={selectRef}>
      <div
        className="CustomSelectMain"
        onClick={() => setdropdownActive(!dropdownActive)}
      >
        {
          !selectedOption
            ? (
                selectDefaultValue
                  ? (
                    <p className="CustomSelectOptionsItem">
                      {
                        typeof selectDefaultValue === 'string'
                          ? selectDefaultValue
                          : selectDefaultValue?.children
                      }
                    </p>
                    )
                  : (
                      children[0]
                    )
              )
            : (
                <p className="CustomSelectOptionsItem">{selectedOption}</p>
              )
        }
        <img
          className={`CustomSelectRotate ${dropdownActive ? 'CustomSelectRotate-active' : ''}`}
          src={greyArrow}
          alt={scriptsSave && scriptsSave.customSelectAdminStrings ? scriptsSave.customSelectAdminStrings.altImageTxt1 : ''}
        />
      </div>
      {dropdownActive && (
        <div className="CustomSelectOptions">
          {Children.map(children, (child, index) =>
            cloneElement(child, {
              onClick: () => handleOptionSelect(child.props),
              key: index
            })
          )}
        </div>
      )}
    </section>
  )
}
