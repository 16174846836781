import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataContext } from '../../../../context/DataContext'
import { formatDateDDMMYYMonthInLettersVersion2 } from '../../../../utils/functions'
import { handleCheckboxChange, onDownloadClick } from './RequestDetailFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './RequestDetail.css'

export const RequestDetail = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { responseByEntityDetail } = dataState.lawyer.requests
  const { date, sender, body, attachment } = responseByEntityDetail
  const attachmentContains = attachment[0]?.attachmenLink

  const [isRequesting, setIsRequesting] = useState(false)

  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)

  return (
    <section id='RequestDetail'>
      <div className="RequestDetailTitleContainer">
        <h2 className='RequestDetailTitle'>
          {scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.requestDetailString.data : ''}
        </h2>
        <div className="RequestDetailReadMsgContainer">
          <span className='RequestDetailReadMsgCheckboxContainer'>
            <input
              className="RequestDetailReadMsgCheckbox"
              type="checkbox"
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(e, setIsChecked, navigate, responseByEntityDetail.id, dataContext)}
            />
          </span>
          <p className="RequestDetailReadMsgText">
            {scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.readMsgText : ''}
          </p>
        </div>
      </div>
      <div className='RequestDetailTopBox'>
        <p className='RequestDetailTopBoxCompany'>{`(${sender.split('<')[1].replace(/>/g, '')})`}</p>
        <p className='RequestDetailTopBoxDate'>{scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.date : ''}{formatDateDDMMYYMonthInLettersVersion2(date)}</p>
      </div>
      <div className='RequestDetailBottomBox RequestDetailBodyContent'>
        <p className='RequestDetailBottomBoxTitles'>
          {scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.requestDetailString.bodyMessage : ''}
        </p>
        <br />
        <p className='RequestDetailBottomBoxTitles'>
          {scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.requestDetailString.senderResponse : ''}
        </p>
        <p className='RequestDetailBottomBoxText'>
          {body}
        </p>
      </div>
      <div className='RequestDetailBtnBox'>
        {attachmentContains && attachmentContains !== '[]' &&
          <button
            className={`${isRequesting ? 'RequestDetailBtnDisabled' : 'RequestDetailBtn'}`}
            onClick={() => onDownloadClick(attachment, isRequesting, setIsRequesting)}
          >
            {scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.requestDetailString.downloadBtn : ''}
          </button>
        }
      </div>
    </section>
  )
}
