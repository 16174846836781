import { useEffect } from 'react'
import { getEmployeeCredentialQuery } from '../../../utils/userQueries'
import { dataTypes } from '../../../context/reducers/dataReducer/dataTypes'
import { usersPanelAdministrativeString } from '../../../utils/strings'

export const useFetchEmployees = (dataContext, dataDispatch, idToken) => {
  useEffect(() => {
    const fetchEmployeesData = async () => {
      const getEmployeeCredentialResponse = await getEmployeeCredentialQuery(idToken, dataContext)
      if (!getEmployeeCredentialResponse.response) console.error(usersPanelAdministrativeString.employeeCredentialFailed)

      const action = {
        type: dataTypes.setEmployeesList,
        payload: getEmployeeCredentialResponse?.body
      }

      dataDispatch(action)
    }

    fetchEmployeesData()
  }, [])
}
