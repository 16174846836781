import React, { useContext } from 'react'
import orangeArrowIcon from '../../../../assets/icons/icon_flecha_naranja.svg'
import expiredIcon from '../../../../assets/icons/icon_expired.png'
import './CaseTrackingDetail.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataContext } from '../../../../context/DataContext'
import { fetchCaseTrackingDetail } from './CaseTrackingDetailFunctions'
import { dashedFormatDateDDMMYYYY } from '../../../../utils/functions'
import { downloadFiles } from '../../../Contact/views/ProceedNotAllowedContact/ProceedNotAllowedContactFunctions'
import downloadIcon from '../../../../assets/icons/icon_download.svg'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const CaseTrackingDetail = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  const dataContext = useContext(DataContext)
  const { dataState, infoSaved, setInfoSaved } = dataContext
  const { userId } = state || infoSaved
  const { caseTracking } = dataState.lawyer
  const { caseTrackingUserDetail } = caseTracking

  const handleDetailData = (demand) => {
    const stateData = {
      companyId: demand.companyId.companyId,
      view: 'caseTrackingDetail',
      userId,
      userIdentNum: caseTrackingUserDetail.identNum
    }
    navigate('../SeguimientoCasos/SeguimientoCasosEntidadDetalle', { state: stateData })
    setInfoSaved(stateData)
  }
  fetchCaseTrackingDetail(dataContext, userId)
  return (
    <section id='CaseTrackingDetail'>
      <div className='CaseTrackingDetailLSide'>
        <h2 className='CaseTrackingDetailTitle'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.userData : ''}</h2>
        <div className='CaseTrackingDetailItemBox'>
          <p className='CaseTrackingDetailKey'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.name : ''}</p>
          <p className='CaseTrackingDetailValue'>{caseTrackingUserDetail.name}</p>
        </div>
        <div className='CaseTrackingDetailItemBox'>
          <p className='CaseTrackingDetailKey'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.phone : ''}</p>
          <p className='CaseTrackingDetailValue'>{caseTrackingUserDetail.phone}</p>
        </div>
        <div className='CaseTrackingDetailItemBox'>
          <p className='CaseTrackingDetailKey'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.email : ''}</p>
          <p className='CaseTrackingDetailValue'>{caseTrackingUserDetail.email}</p>
        </div>
        <div className='CaseTrackingDetailItemBox'>
          <p className='CaseTrackingDetailKey'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.id : ''}</p>
          <p className='CaseTrackingDetailValue'>{`CC ${caseTrackingUserDetail.identNum}`}</p>
        </div>
        <div className='CaseTrackingDetailItemBox'>
          <p className='CaseTrackingDetailKey'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.gender : ''}</p>
          <p className='CaseTrackingDetailValue'>
            {
              caseTrackingUserDetail.gender === 1
                ? (scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.gender1 : '')
                : (scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.gender2 : '')
            }
          </p>
        </div>
        <div className='CaseTrackingDetailItemBox'>
          <p className='CaseTrackingDetailKey'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.registerDate : ''}</p>
          <p className='CaseTrackingDetailValue'>{dashedFormatDateDDMMYYYY(caseTrackingUserDetail.createDt)}</p>
        </div>
        <div className='CaseTrackingDetailItemBox'>
          <button onClick={() => downloadFiles(caseTrackingUserDetail.urls)} className='CaseTrackingDownloadBtn'>
            <img
              src={downloadIcon}
              alt={scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.altImageTxt1 : ''}
            />
            {scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.downloadFiles : ''}
          </button>
        </div>
      </div>
      <div className='CaseTrackingDetailRSide'>
        <h2 className='CaseTrackingDetailTitle'>{scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.blockedCompaniesList : ''}</h2>
        <div className='CaseTrackingCompanyList'>
          {caseTrackingUserDetail.demandList?.map((demand, index) => (
            <div key={index} className='CaseTrackingDetailCompanyCard'>
                <div>
                  <img
                    src={demand.companyId.companyLogoPath}
                    className='CaseTrackingCompanyIcon'
                    alt={scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.altImageTxt2 : ''}
                  />
                  <div>
                    <p>{demand.companyId.companyBussinesName}</p>
                    {demand.expired &&
                      <div className='expiredAlertTrackingDetail'>
                        <span className='expiredAlertText'>{demand.expired && 'Vencido'}</span>
                        <img
                          src={expiredIcon}
                          alt={scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.altImageTxt3 : ''}
                        />
                      </div>
                    }
                  </div>
                </div>
                <button
                  className='CaseTrackingDetailBtn'
                  onClick={() => handleDetailData(demand)}>
                  <img
                    src={orangeArrowIcon}
                    className='CaseTrackingDetailArrow'
                    alt={scriptsSave && scriptsSave.caseTrackingDetailString ? scriptsSave.caseTrackingDetailString.altImageTxt4 : ''}
                  />
                </button>
              </div>
          ))}
        </div>
      </div>
    </section>
  )
}
