import React, { useContext } from 'react'

import './ImageModal.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const ImageModal = ({ isOpen, children, title, imageSrc }) => {
  const { scriptsSave } = useContext(ScriptContext)
  if (!isOpen) {
    return null
  }
  return (
    <section className='imageModal'>
      <div className='imageModalBody'>
        <h2 className='imageModalTitle'>
          {title}
        </h2>
        <div className='imageModalContent'>
          <img
            src={imageSrc}
            alt={scriptsSave && scriptsSave.imageModalAdminStrings ? scriptsSave.imageModalAdminStrings.altImageTxt1 : ''}
          />
        </div>
        {children}
      </div>
    </section>
  )
}

const Buttons = ({ children, buttonContainerClass }) => {
  return <div className={buttonContainerClass}>{children}</div>
}

ImageModal.Buttons = Buttons
