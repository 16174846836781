import React, { useContext } from 'react'

// Components
import { RequestsGrid } from '../../../../components/RequestsGrid/RequestsGrid'

// Assets
import rejectedIcon from '../../../../assets/icons/icon_rechazada.svg'

import { ScriptContext } from '../../../../context/ScriptsContext'
import './RejectedRequests.css'

export const RejectedRequests = () => {
  const { scriptsSave } = useContext(ScriptContext)

  const dropdownOption1 = scriptsSave && scriptsSave.rejectedRequestsString ? scriptsSave.rejectedRequestsString.dropdownOption1 : ''
  const dropdownOption2 = scriptsSave && scriptsSave.rejectedRequestsString ? scriptsSave.rejectedRequestsString.dropdownOption2 : ''
  const dropdownOption3 = scriptsSave && scriptsSave.rejectedRequestsString ? scriptsSave.rejectedRequestsString.dropdownOption3 : ''
  const dropdownOptions = [dropdownOption1, dropdownOption2, dropdownOption3]

  const rejectedRequests = [
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
    { id: '8' },
    { id: '9' },
    { id: '10' },
    { id: '11' },
    { id: '12' },
    { id: '13' },
    { id: '14' },
    { id: '15' }
  ]

  return (
    <div className="rejectedRequests">
      <RequestsGrid
        icon={rejectedIcon}
        info={scriptsSave && scriptsSave.rejectedRequestsString ? scriptsSave.rejectedRequestsString.info : ''}
        requests={rejectedRequests}
        dropdownOptions={dropdownOptions}
        seeUrlBtn="../VerSolicitudesRechazadas"
      />
    </div>
  )
}
