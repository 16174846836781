import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { formatDateDDMMYYMonthInLetters, formatDateToTimeHHMM } from '../../../../utils/functions'
import { DataContext } from '../../../../context/DataContext'
import iconBack from '../../../../assets/icons/icon_volver.svg'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './HistoryPaysDetails.css'

export const HistoryPaysDetails = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState } = useContext(DataContext)
  const { historyPerCPDetailToBeReviewed } = dataState.operative.users
  const { details } = historyPerCPDetailToBeReviewed

  const personName = `${details[0].rewards[0].demandId.userId.personId.personName} ${details[0].rewards[0].demandId.userId.personId.personLastName}`
  const personPhone = details[0].rewards[0].demandId.userId.personId.personPhone
  const personEmail = details[0].rewards[0].demandId.userId.credentialId.credentialEmail
  const personIdentType = details[0].rewards[0].demandId.userId.personId.identDocId.identDocName
  const personIdentNum = details[0].rewards[0].demandId.userId.personId.personIdentNum
  const rewardsPerCompany = details[0].rewards

  const navigate = useNavigate()

  return (
    <section id='HistoryPaysDetails'>
      <div className='HistoryPaysDetailsBox'>
        <div className='HistoryPaysDetails-lside'>
          <h2 className='HistoryPaysDetails-lside-title'>
            {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.dataTitle : ''}
          </h2>
          <div>
            <p className='HistoryPaysDetails-lside-key'>
              {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.dataLabel1 : ''}
            </p>
            <p className='HistoryPaysDetails-lside-value'>{personName}</p>
          </div>
          <div>
            <p className='HistoryPaysDetails-lside-key'>
              {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.dataLabel2 : ''}
            </p>
            <p className='HistoryPaysDetails-lside-value'>{personPhone}</p>
          </div>
          <div>
            <p className='HistoryPaysDetails-lside-key'>
              {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.dataLabel3 : ''}
            </p>
            <p className='HistoryPaysDetails-lside-value'>{personEmail}</p>
          </div>
          <div>
            <p className='HistoryPaysDetails-lside-key'>
              {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.dataLabel4 : ''}
            </p>
            <p className='HistoryPaysDetails-lside-value'>{personIdentType}</p>
          </div>
          <div>
            <p className='HistoryPaysDetails-lside-key'>
              {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.dataLabel5 : ''}
            </p>
            <p className='HistoryPaysDetails-lside-value'>{personIdentNum}</p>
          </div>
        </div>
        <div className='HistoryPaysDetails-rside'>
          <h2 className='HistoryPaysDetails-rside-title'>
            {scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.subsectionTitle : ''}
          </h2>
          <div className='HistoryPaysDetailsTableContainer'>
            <table>
              <thead>
                <tr>
                  <th>{scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.thead1 : ''}</th>
                  <th>{scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.thead2 : ''}</th>
                  <th>{scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.thead3 : ''}</th>
                  <th>{scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.thead4 : ''}</th>
                </tr>
              </thead>
              <tbody>
                {
                  rewardsPerCompany.map(reward => (
                    <tr key={reward.demandId.demandId}>
                      <td>{formatDateDDMMYYMonthInLetters(reward.creationDate)}</td>
                      <td>{reward.demandId.companyId.companyComertialName}</td>
                      <td>{formatDateToTimeHHMM(reward.creationDate)}</td>
                      <td>{`${reward.rewardCurrencyId.rewardCurrencyValue}${scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.currencyUnit : ''}`}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        className='HistoryPaysBackBox'
        onClick={() => navigate('../HistorialPagos')}
      >
        <img
          src={iconBack}
          alt={scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.footerAltTextBtn : ''}
        />
        <span>{scriptsSave && scriptsSave.historyPaysDetailsString ? scriptsSave.historyPaysDetailsString.footerTextBtn : ''}</span>
      </button>
    </section>
  )
}
