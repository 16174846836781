import { removeData } from '../views/BaseAdministrativo/BaseAdministrativoFunctions'
import { Amplify } from './amplify'
import { queriesAdminString } from './strings'
const amplify = new Amplify()

export const handleSessionFailures = async (
  responsePromise,
  navigate,
  dataDispatch,
  handleShowInactiveSessionModal
) => {
  const fails = [queriesAdminString.inactive, queriesAdminString.expired]
  const response = await responsePromise
  const res = await response.json()
  if (!res.response && res.data && res.data.code && res.data.code.length < 3) {
    return res
  }
  if (
    res.response === false &&
    res.data &&
    res.data.code &&
    fails.includes(res.data.code)
  ) {
    removeData({ navigate, dataDispatch, handleShowInactiveSessionModal })
  }
  return res
}

export const getUserQuery = async (dataContext, id, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = fetch(
      `${process.env.REACT_APP_HOST}/user/find/registry/${id}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getBuyerQuery = async (dataContext, id, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }

    const response = fetch(
      `${process.env.REACT_APP_HOST}/buyer/find/admin/${id}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCompanyQuery = async (dataContext, id, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }

    const response = fetch(
      `${process.env.REACT_APP_HOST}/company/registry/data/${id}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const loginQuery = async (email, password) => {
  if (!email) {
    throw new Error('email is null.')
  } else if (!password) {
    throw new Error('password is null.')
  } else if (typeof email !== 'string') {
    throw new Error('email must be a string value.')
  } else if (typeof password !== 'string') {
    throw new Error('password must be a string value.')
  }

  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/loginEmployee`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export const uploadMassive = async (
  dataContext,
  companyId,
  csvData,
  setProgress
) => {
  if (!companyId || !csvData || csvData.length < 1) {
    throw new Error('Incomplete data')
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }

    const batchSize = 500
    let data = null
    const progressStepValue = 100 / (csvData.length / batchSize)

    const { dataState } = dataContext
    const { idToken } = dataState.operative

    for (let index = 0; index < csvData.length; index += batchSize) {
      const dataToLoad = { companyId, data: null }
      const batch = csvData.slice(index, index + batchSize)
      dataToLoad.data = batch
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/sponsorCode/charge/massive`,
        {
          method: 'POST',
          body: JSON.stringify(dataToLoad),
          headers: {
            'Content-Type': 'application/json',
            TokenCatalogo: token,
            Authorization: `Bearer ${idToken}`
          }
        }
      )

      const { navigate, dataDispatch, handleShowInactiveSessionModal } =
        dataContext
      data = await handleSessionFailures(
        response,
        navigate,
        dataDispatch,
        handleShowInactiveSessionModal
      )

      setProgress((prev) => {
        if (prev + progressStepValue < 100) return prev + progressStepValue
        return 100
      })
    }
    return data
  } catch (error) {
    console.error(error)
  }
}
