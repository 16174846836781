import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { DataContext } from '../../context/DataContext'

export const PublicRoute = ({ children }) => {
  const { dataState } = useContext(DataContext)
  const { logged, administrative, operative, lawyer } = dataState

  const administrativeEmployeeId = administrative.employeeId
  const operativeEmployeeId = operative.employeeId
  const lawyerEmployeeId = lawyer.employeeId

  return (
    !logged
      ? children
      : administrativeEmployeeId
        ? <Navigate to={`/Cuenta/${administrativeEmployeeId}/UsuariosAdmin`} />
        : operativeEmployeeId
          ? <Navigate to={`/Cuenta/${operativeEmployeeId}/Entradas`} />
          : <Navigate to={`/Cuenta/${lawyerEmployeeId}/GestionUsuarios`} />
  )
}
