import { formatDateDDMMYYMonthInLettersVersion2 } from '../../utils/functions'

export const formatResponsesByEntityList = (responsesByEntityList, setResponsesByEntityList, page, limit) => {
  // const numberOfDigits = calculateNumberOfDigits(responsesByEntityList)
  // let currentItem = page

  const listFormatted = responsesByEntityList.map((response, index) => {
    // let id = (currentItem * limit) - limit + 1
    return {
      // id: String(id + index).padStart(numberOfDigits, '0'),
      id: response.id,
      arrivalDate: formatDateDDMMYYMonthInLettersVersion2(response.date),
      email: response.sender.split('<')[1].replace(/>/g, ''),
      respondingEntity: response.subject,
      subject: response.subject
    }
  })

  setResponsesByEntityList(listFormatted)
}
