import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import editIcon from '../../assets/icons/icon_editar.svg'
import { Dropdown } from '../Dropdown/Dropdown'
import { Pagination } from '../Pagination/Pagination'
import { onActionsClick } from './RequestsGridFunctions'

import './RequestsGrid.css'
import { addElipsisAtEnd } from '../../utils/functions'
import { ScriptContext } from '../../context/ScriptsContext'

export const RequestsGrid = ({
  originalRequestsList,
  responsesByEntityList,
  setResponsesByEntityList,
  responsesByEntity,
  paginationValues,
  setPaginationValues,
  totalPages,
  isNextActive,
  setIsNextActive,
  isPrevActive,
  setIsPrevActive
}) => {
  const dataContext = useContext(DataContext)
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const navigate = useNavigate()

  return (
    <div className='requestsGrid'>
      <table>
        <thead>
          <tr>
            <th>
              <Dropdown
                filterBy={'arrivalDate'}
                list={responsesByEntityList}
                sortType={'date'}
                title={scriptsSave && scriptsSave.requestsGridString ? scriptsSave.requestsGridString.column1Name : ''}
                type={'orderSelect'}
                updateList={setResponsesByEntityList}
              />
            </th>
            <th>{scriptsSave && scriptsSave.requestsGridString ? scriptsSave.requestsGridString.column2Name : ''}</th>
            <th>
              <Dropdown
                filterBy={'subject'}
                list={originalRequestsList}
                title={scriptsSave && scriptsSave.requestsGridString ? scriptsSave.requestsGridString.column3Name : ''}
                updateList={setResponsesByEntityList}
              />
            </th>
            <th>{scriptsSave && scriptsSave.requestsGridString ? scriptsSave.requestsGridString.column4Name : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            responsesByEntityList.map((response) => (
              <tr key={response.id}>
                <td><p>{response.arrivalDate}</p></td>
                <td><p>{response.email}</p></td>
                <td><p>{addElipsisAtEnd(response.respondingEntity, 30)}</p></td>
                <td>
                  <button
                    className='requestsGridActionsBtn'
                    onClick={() => onActionsClick(
                      response,
                      dataContext,
                      idToken,
                      navigate
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.requestsGridString ? scriptsSave.requestsGridString.altImageTxt1 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Pagination
        currentPage={responsesByEntity.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={responsesByEntity.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </div>
  )
}
