import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { DataContext } from '../../../../../../context/DataContext'
import iconAdministrativo from '../../../../../../assets/icons/icon_administrador.svg'
import iconAbogado from '../../../../../../assets/icons/icon_admi_abogado.svg'
import iconOperativo from '../../../../../../assets/icons/icon_admi_operativo.svg'
import { onChooseRoleClick } from './NewUserCardFunctions'

import './NewUserCard.css'
import { ScriptContext } from '../../../../../../context/ScriptsContext'

const roleColors = {
  Administrativo: 'newUserCardBodyBGOrange',
  Abogado: 'newUserCardBodyBGDarkPurple',
  Operativo: 'newUserCardBodyBGLightPurple'
}

const roleIcons = {
  Administrativo: iconAdministrativo,
  Abogado: iconAbogado,
  Operativo: iconOperativo
}

export const NewUserCard = ({ type, rol }) => {
  const { scriptsSave } = useContext(ScriptContext)
  const { dataDispatch } = useContext(DataContext)

  return (
    <>
      {type === 'createUser'
        ? (
        <NavLink
          className={`createNewUserCardBody ${roleColors[rol]}`}
          onClick={() => onChooseRoleClick(rol, dataDispatch)}
          to='../CrearNuevoUsuario'
        >
          <p className='newUserCardParagraph'>{`Rol ${rol}`}</p>
          <img
            alt={scriptsSave && scriptsSave.newUserCardAdminStrings ? scriptsSave.newUserCardAdminStrings.altImageTxt1 : ''}
            className={`newUserCardImage ${rol === 'Abogado' ? 'newUserCardImgSizeAbogado' : rol === 'Operativo' ? 'newUserCardImgSizeOperativo' : ''
              }`}
            src={roleIcons[rol]}
          />
        </NavLink>
          )
        : type === 'editUser'
          ? (
        <div className={`editNewUserCardBody ${roleColors[rol]}`}>
          <p className='newUserCardParagraph'>{`Rol ${rol}`}</p>
          <img
            alt={scriptsSave && scriptsSave.newUserCardAdminStrings ? scriptsSave.newUserCardAdminStrings.altImageTxt2 : ''}
            className={`newUserCardImage ${rol === 'Abogado' ? 'newUserCardImgSizeAbogado' : rol === 'Operativo' ? 'newUserCardImgSizeOperativo' : ''
              }`}
            src={roleIcons[rol]}
          />
        </div>
            )
          : null}
    </>
  )
}
