import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits, formatDateDDMMYYMonthInLetters } from '../../utils/functions'
import { getPaymentHistoryDetailsPerContactNotAllowed } from '../../utils/userQueries'

export const formatPaymentHistoryPerContactNotAllowedList = (paymentHistoryList, setPaymentHistoryList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(paymentHistoryList)
  const currentItem = page

  const listFormatted = paymentHistoryList.map((payment, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      userId: payment.userId_userId,
      name: `${payment.personName} ${payment.personLastName}`,
      paymentDate: formatDateDDMMYYMonthInLetters(payment.creationDate),
      planType: payment.planName
    }
  })
  setPaymentHistoryList(listFormatted)
}

export const onDetailsClick = async (
  navigate,
  payment,
  idToken,
  dataDispatch,
  dataContext
) => {
  if (payment.userId) {
    const historyDetailsPerCPResponse = await getPaymentHistoryDetailsPerContactNotAllowed(dataContext, payment.userId, idToken)
    if (historyDetailsPerCPResponse.response) {
      payment.details = historyDetailsPerCPResponse.body
    }
  }
  const action = {
    type: dataTypes.setPaymentHistoryDetailsPerContactNotAllowed,
    payload: payment
  }
  dataDispatch(action)

  navigate('DetallePagos')
}
