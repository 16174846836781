import React, { useContext, useEffect, useState } from 'react'
import './ProceedNotAllowedContact.css'
import { NavFooter } from '../../../../components/NavFooter/NavFooter'
import { useLocation, useNavigate } from 'react-router-dom'
import { getProceedNotAllowedContactData } from '../../../../utils/userQueries'
import { DataContext } from '../../../../context/DataContext'
import {
  downloadFiles,
  handleSubmitProceedRequest,
  onUploadFileChange
} from './ProceedNotAllowedContactFunctions'
import { validateTextarea } from '../RejectNotAllowedContact/RejectNotAllowedContactFunctions'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { CharCounter } from '../../../../components/CharCounter/CharCounter'
import iconBien from '../../../../assets/icons/icon_bien.svg'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const ProceedNotAllowedContact = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { state: requestData } = useLocation()
  const navigate = useNavigate()
  const [showModal, handleShow] = useModal()
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, employeeId } = dataState.lawyer
  const requestSendString = scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.requestSendString : ''
  const [proceedData, setProceedData] = useState({})
  const [file, setFile] = useState(null)
  const [response, setResponse] = useState('')
  const [radicateNum, setRadicateNum] = useState('')
  const [uploadedFile, setUploadedFile] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const resQuery = await getProceedNotAllowedContactData(
        idToken,
        requestData.userId.userId,
        requestData.content.idempresa,
        dataContext
      )
      if (resQuery.response) {
        setProceedData(resQuery.body)
      }
    }
    fetchData()
  }, [])

  const documentPathRequestBody = {
    userId: requestData?.userId?.userId,
    personIdentNum: proceedData?.data?.personIdentNum,
    namefiles: file?.name?.replace(/\s/g, '_'),
    file,
    companyId: requestData?.content?.idempresa,
    companyNit: proceedData?.data?.companyNit,
    contentType: file?.type
  }

  const sendProceedResponseBody = {
    employeeId,
    userId: requestData?.userId?.userId,
    requestId: requestData?.requestId,
    companyId: requestData?.content?.idempresa,
    response,
    personIdentNum: proceedData?.data?.personIdentNum,
    companyNit: proceedData?.data?.companyNit,
    fileName: uploadedFile
  }

  console.log(uploadedFile)

  return (
    <section id='ProceedNotAllowedContact'>
      <h2 className='RequestSendTitles'>{requestSendString.form}</h2>
      <div className='RequestSendContent'>
        <div className='RequestSendContentLSide'>
          <h2 className='RequestSendTitles'>{requestSendString.user}</h2>
          <p>
            <span className='RequestSendSubTitle1'>{requestSendString.name}</span>
            <span className='RequestSendDesc1'>{`${proceedData?.data?.personName} ${proceedData?.data?.personLastName}`}</span>
          </p>
          <p>
            <span className='RequestSendSubTitle1'>{requestSendString.id}</span>
            <span className='RequestSendDesc1'>{proceedData?.data?.personIdentNum}</span>
          </p>
          <p>
            <span className='RequestSendSubTitle1'>{requestSendString.email}</span>
            <span className='RequestSendDesc1'>{proceedData?.data?.credentialEmail}</span>
          </p>
        </div>
        <div className='RequestSendContentRSide'>
          <h2 className='RequestSendTitles'>{requestSendString.entity}</h2>
          <p>
            <span className='RequestSendSubTitle1'>{requestSendString.name}</span>
            <span className='RequestSendDesc1'>{proceedData?.data?.companyComertialName}</span>
          </p>
          <p>
            <span className='RequestSendSubTitle1'>{requestSendString.nit}</span>
            <span className='RequestSendDesc1'>{proceedData?.data?.companyNit}</span>
          </p>
          <p>
            <span className='RequestSendSubTitle1'>{requestSendString.email}</span>
            <span className='RequestSendDesc1'>{proceedData?.data?.companyEmail}</span>
          </p>
        </div>
      </div>
      <div className='RequestSendInfo'>
        <h2 className='RequestSendInfoTitle'>
          {requestSendString.contentInfo}
        </h2>
        <textarea
          name='RequestSendInfoTextarea'
          className='RequestSendInfoTextarea'
          onChange={(e) => validateTextarea(e, setResponse)}
          value={response}
        ></textarea>
        <CharCounter description={response} />
      </div>
      <div className='RequestSendBottom'>
        <div className='RequestSendBottomBox'>
          <p className='RequestSendBottomBoxText'>
            {requestSendString.downloadAnnexes}
          </p>
          <button
            className='RequestSendBtn'
            onClick={() => downloadFiles(proceedData?.urls)}
          >
            {requestSendString.annexesBtn}
          </button>
        </div>
        <div className='RequestSendBottomBox'>
          <div className='RequestSendAuxContainer'>
            <div className='RequestSendNameAndLogoUploadedFile'>
              {
                uploadedFile &&
                  (
                    <img
                      src={iconBien}
                      alt={scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.altImageTxt1 : ''}
                    />
                  )
              }
              <p className='RequestUploadedFileName'>{uploadedFile}</p>
            </div>
            <input
              type='file'
              name='uploadDocument'
              id='uploadDocument'
              onChange={(e) => onUploadFileChange(e, setFile, setUploadedFile)}
              accept='.jpg, .jpeg, .png, .pdf'
            />
            <label htmlFor='uploadDocument' className='RequestSendBtn'>
              {requestSendString.uploadDocumentBtn}
            </label>
          </div>
          <button
            className='RequestSendBtn'
            disabled={response.length < 40 || !file}
            onClick={() =>
              handleSubmitProceedRequest(
                idToken,
                documentPathRequestBody,
                sendProceedResponseBody,
                dataContext,
                handleShow,
                setRadicateNum
              )
            }
          >
            {requestSendString.sendBtn}
          </button>
        </div>
      </div>
      <NavFooter returnPath='../EditarContactoNoPermitido' state={requestData}/>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>
            {requestSendString.documentSent}
            <u>{scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.modalTxt1 : ''}</u>
          </p>
          <p>
            {requestSendString.userNoticed1}
            {radicateNum}
            {requestSendString.userNoticed2}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={() => navigate('..')}>
            {scriptsSave && scriptsSave.RequestString ? scriptsSave.RequestString.modalBtn1Text1 : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
