import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../../../../context/DataContext'
import {
  getPackageInfo,
  getTuDataControlPaymentPerUser
} from '../../../../../utils/userQueries'
import { movementsControlString } from '../../../../../utils/strings'

export const useFetchPaymentPerUser = () => {
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, movements } = dataState.operative
  const { packageId } = movements.tuDataControlPaymentToBeReviewed
  const [paymentData, setPaymentData] = useState({
    companyId: '',
    userId: '',
    name: '',
    email: '',
    logo: '',
    nit: '',
    paymentDate: '',
    paymentId: '',
    type: ''
  })

  useEffect(() => {
    const fetchPaymentPerUser = async () => {
      const getPaymentPerUserResponse = await getTuDataControlPaymentPerUser(
        dataContext,
        packageId,
        idToken
      )
      const { response, body } = getPaymentPerUserResponse
      const packageInfo = await getPackageInfo(
        dataContext,
        packageId,
        idToken
      )
      if (response) {
        if (body.length > 1) {
          setPaymentData({
            companyId: '',
            userId: body[0].userId.userId,
            name: `${body[0].userId.personId.personName} ${body[0].userId.personId.personLastName}`,
            email: body[0].userId.credentialId.credentialEmail,
            logo: '',
            companyName:
              movementsControlString.infoPackage[
                packageInfo?.body?.packageCurrencyId?.packageCurrencyName
              ],
            nit: '',
            paymentDate: packageInfo?.body?.packageDate,
            paymentId: packageId,
            type: 'paquete'
          })
        } else {
          setPaymentData({
            companyId: body[0].companyId.companyId,
            userId: body[0].userId.userId,
            name: `${body[0].userId.personId.personName} ${body[0].userId.personId.personLastName}`,
            email: body[0].userId.credentialId.credentialEmail,
            logo: body[0].companyId.companyLogoPath,
            companyName: body[0].companyId.companyComertialName,
            nit: body[0].companyId.companyNit,
            paymentDate: body[0].creationDate,
            paymentId: packageId,
            type: 'compra'
          })
        }
      }
    }

    fetchPaymentPerUser()
  }, [])

  return paymentData
}
