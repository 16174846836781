import { entityValidationString } from '../../utils/strings'
import { patchDeleteBuyer, validateBuyerNit } from '../../utils/userQueries'
import { handleRequestButton } from '../../utils/validateFunctions'

export const handleButtonClick = (fileInputRef) => {
  fileInputRef.current.click()
}

export const handleImageChange = (event, setImage) => {
  const selectedImage = event.target.files[0]
  setImage(URL.createObjectURL(selectedImage))
}

export const onDeleteEntityClick = async (
  buyerCredentialId,
  email,
  companyComertialName,
  setModalMessage,
  idToken,
  handleShow,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  if (!buyerCredentialId || !email) return

  handleRequestButton(setIsRequesting)
  const patchDeleteBuyerResponse = await patchDeleteBuyer(
    dataContext,
    buyerCredentialId,
    { email },
    idToken
  )
  if (patchDeleteBuyerResponse.response) {
    setModalMessage(
      `${entityValidationString.modaltext1}${companyComertialName}`
    )
    handleShow()
  }
}

export const onSaveEntityClick = async (
  buyerId,
  companyComertialName,
  setModalMessage,
  idToken,
  handleShow,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  if (!buyerId) return

  handleRequestButton(setIsRequesting)
  const validateBuyerNitResponse = await validateBuyerNit(
    dataContext,
    idToken,
    buyerId,
    { validateNit: true }
  )
  if (validateBuyerNitResponse.response) {
    setModalMessage(
      `${entityValidationString.modaltext3}${companyComertialName}`
    )
    handleShow()
  }
}

export const onContinueModalBtnClick = (navigate, handleClose) => {
  navigate('../NuevasEntidades')
  handleClose()
}
