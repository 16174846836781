import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../../../utils/functions'

export const formatEntriesList = (entriesList, setEntriesListFormatted, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(entriesList)
  const currentItem = page

  const listFormatted = entriesList.map((entry, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      credentialId: entry.credentialId,
      userId: entry.userId.userId,
      identNum: entry.userId.personId.personIdentNum,
      name: `${entry.userId.personId.personName} ${entry.userId.personId.personLastName}`,
      phone: entry.userId.personId.personPhone,
      email: entry.credentialEmail
    }
  })
  setEntriesListFormatted(listFormatted)
}

export const onDetailsClick = (navigate, entry, dataDispatch) => {
  const action = {
    type: dataTypes.setNewUserEntryToBeReviewd,
    payload: { userId: entry.userId }
  }
  dataDispatch(action)
  navigate('NuevosUsuarios/ValidacionUsuario')
}
