import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../../../context/DataContext'
import editIcon from '../../../../assets/icons/icon_editar.svg'
import successIcon from '../../../../assets/icons/icon_bien.svg'

import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { getTuDataControlPayments } from '../../../../utils/userQueries'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import {
  formatTuDataControlPaymentsList,
  onDetailsClick
} from './PagosTuDataControlFunctions'
import { Pagination } from '../../../../components/Pagination/Pagination'

import './PagosTuDataControl.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const PagosTuDataControl = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, movements } = dataState.operative
  const { tuDataControlPayments } = movements
  const tuDataControlPaymentsList = movements.tuDataControlPayments.list

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    tuDataControlPayments,
    'tuDataControlPayments',
    getTuDataControlPayments,
    dataTypes.updateTuDataControlPayments,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    tuDataControlPaymentsList,
    formatTuDataControlPaymentsList,
    page,
    limit
  )

  const navigate = useNavigate()

  const filterOptions = ['Pendiente', 'Finalizado']

  return (
    <section id='PagosTuDataControl'>
      <table>
        <thead>
          <tr>
            <th>{scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.thead1 : ''}</th>
            <th>
              <Dropdown
                filterBy={'name'}
                list={originalList}
                title={scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.thead2 : ''}
                // type={'search'}
                updateList={setListFormatted}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'creationDate'}
                list={originalList}
                title={scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.thead4 : ''}
                // type={'search'}
                updateList={setListFormatted}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'state'}
                list={originalList}
                options={filterOptions}
                title={scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.thead5 : ''}
                type={'select'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.thead6 : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map(payment => (
              <tr key={payment.id}>
                <td>{payment.id}</td>
                <td>{payment.name}</td>
                <td>{payment.creationDate}</td>
                <td>
                  <img
                    src={payment.state === 'Finalizado' && successIcon}
                    alt={scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.altImageTxt1 : ''}
                  />
                </td>
                <td>
                  <button
                    className='PagosTuDataControlDetailsBtn'
                    onClick={() => onDetailsClick(
                      navigate,
                      payment,
                      dataDispatch
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.payControlString ? scriptsSave.payControlString.altImageTxt2 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Pagination
        currentPage={tuDataControlPayments.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={tuDataControlPayments.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </section>
  )
}
