import React, { useContext } from 'react'

import { NotAllowedContactRowLawyers } from './components/NotAllowedContactRowLawyers'
import { DataContext } from '../../../../context/DataContext'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { getNotAllowedContactList } from '../../../../utils/userQueries'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { formatNotAllowedContactList } from './NotAllowedContactFunctions'

import './NotAllowedContact.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const NotAllowedContact = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, notAllowedContact } = dataState.lawyer
  const { notAllowedContactList } = notAllowedContact
  const notAllowedContactData = notAllowedContactList.list

  const [paginationValues, setPaginationValues] = usePaginateQuery(
    notAllowedContactList,
    'notAllowedContactList',
    getNotAllowedContactList,
    dataTypes.getNotAllowedContactList,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    notAllowedContactData,
    formatNotAllowedContactList,
    page,
    limit
  )

  return (
    <section className='notAllowedContact'>
      <div className='notAllowedContactHeader'>
        <p className='notAllowedContactHeaderCell'>
          {scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.firstHeadColumn : ''}
        </p>
        <div className='notAllowedContactHeaderCell'>
          <p>
            <Dropdown
              filterBy={'name'}
              list={originalList}
              title={scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.secondHeadColumn : ''}
              updateList={setListFormatted}
            />
          </p>
        </div>
        <div className='notAllowedContactHeaderCell'>
          <p>
            <Dropdown
              filterBy={'claimTo'}
              list={originalList}
              title={scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.thirdHeadColumn : ''}
              updateList={setListFormatted}
            />
          </p>
        </div>
        <div className='notAllowedContactHeaderCell'>
          <p>
            <Dropdown
              filterBy={'processType'}
              list={originalList}
              title={scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.fourthHeadColumn : ''}
              updateList={setListFormatted}
            />
          </p>
        </div>
        <p className='notAllowedContactHeaderCell'>
          {scriptsSave && scriptsSave.notAllowedContactString ? scriptsSave.notAllowedContactString.fifthHeadColumn : ''}
        </p>
      </div>

      <div className='notAllowedContactBody'>
        {
          listFormatted.map((item, index) => (
            <NotAllowedContactRowLawyers key={index} item={item} />
          ))
        }
      </div>
      <Pagination
        currentPage={notAllowedContactList.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={notAllowedContactList.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
      />
    </section>
  )
}
