import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './NavFooter.css'

import backIcon from '../../assets/icons/icon_volver.svg'
import { ScriptContext } from '../../context/ScriptsContext'

export const NavFooter = ({ returnPath, children, state }) => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <div className='navFooter'>
      <Link to={returnPath || '.'} state={state} className='navFooterBackLink'>
        <img
          className='navFooterBackLinkImage'
          src={backIcon}
          alt={scriptsSave && scriptsSave.navFooterStrings ? scriptsSave.navFooterStrings.altImageTxt1 : ''}
        />
        <span className='navFooterBackLinkText'>
          {scriptsSave && scriptsSave.navFooterStrings ? scriptsSave.navFooterStrings.goBack : ''}
        </span>
      </Link>
      <div className='navFooterButtonsContainer'>
        {children}
      </div>
    </div>
  )
}
