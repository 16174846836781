import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import dbImage from '../../../assets/icons/icon_bd-operativo.svg'
import backIcon from '../../../assets/icons/icon_volver.svg'
import { useUploadMassive } from '../hooks/useUploadMassive'

import './UserUploads.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const UserUploads = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const [progress, setProgress] = useState(0)

  useUploadMassive(setProgress)

  return (
    <section id="UserUploads">
      <div className="UserUploadsMainContainer">
        <div className="UserUploadsImageBox">
          <img
            src={dbImage}
            alt={scriptsSave && scriptsSave.userUploadsString ? scriptsSave.userUploadsString.principalSectionAltImgText1 : ''}
          />
        </div>

        <div className="bulkUploadSubContainer2">
          <div className="bulkUploadProgressBarContainer">
            <span className="bulkUploadChargeType">
              {scriptsSave && scriptsSave.userUploadsString ? scriptsSave.userUploadsString.progressBarTitle : ''}
            </span>
            <div className="progressContainer">
              <div
                className={`${
                  progress < 100 ? 'progressBar' : 'progressBarFileCharged'
                }`}
                style={{ width: `${progress}%` }}
              >
                <span
                  className={`${
                    progress < 100
                      ? 'progressBarMessage'
                      : 'progressBarMessageFileCharged'
                  }`}
                >
                  {
                    progress < 100
                      ? `${scriptsSave && scriptsSave.compString ? scriptsSave.compString.fileCharging : ''}`
                      : `${scriptsSave && scriptsSave.compString ? scriptsSave.compString.fileCharged : ''}`
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="upLoadMessageState">
            <span className="upLoadMessageStatePercentage">
              {parseFloat(progress).toFixed(1)}
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.percentage : ''}
            </span>
          </div>
        </div>
      </div>

      <Link to={-1} className="bulkUploadFooterBackLink">
        <img
          src={backIcon}
          alt={scriptsSave && scriptsSave.userUploadsString ? scriptsSave.userUploadsString.footerBtnAltImgText1 : ''}
        />
        <span>
          {scriptsSave && scriptsSave.compString ? scriptsSave.compString.return : ''}
        </span>
      </Link>
    </section>
  )
}
