import { useEffect, useState } from 'react'
import { getPQRSDetail } from '../../../utils/userQueries'
import { dataTypes } from '../../../context/reducers/dataReducer/dataTypes'

export const useFetchPQRSDetail = (dataContext, requestId, isUser) => {
  const { dataState, dataDispatch } = dataContext
  const { idToken, pqrs } = dataState.operative
  const { pqrsDetail } = pqrs
  const [bodyData, setbodyData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const queryResponse = await getPQRSDetail(dataContext, idToken, requestId)
      if (queryResponse.response) {
        const data = queryResponse.body
        if (isUser) {
          const bodyUserData = {
            userId: data.userId,
            requestId: data.requestId,
            documentPath: data.documentPath,
            pqrsId: data.pqrsId,
            createDt: data.createDt,
            name: `${data?.userId?.personId?.personName} ${data?.userId?.personId?.personLastName}`,
            phone: data?.userId?.personId.personPhone,
            email: data?.userId?.credentialId.credentialEmail,
            idType: data?.userId?.personId.identDocId.identDocName,
            identNum: data?.userId?.personId.personIdentNum,
            radicatedNum: data?.radicatedNum,
            pqrsName: data?.pqrsId?.pqrsName,
            content: data?.content
          }
          setbodyData(bodyUserData)
        } else {
          const bodyBuyerData = {
            userId: data.buyerId,
            requestId: data.requestId,
            documentPath: data.documentPath,
            pqrsId: data.pqrsId,
            createDt: data.createDt,
            name: data.buyerId.buyerLegalRepresentative,
            phone: data.buyerId.companyId.companyPhone,
            email: data.buyerId.companyId.companyEmail,
            idType: 'NIT',
            identNum: data.buyerId.companyId.companyNit,
            radicatedNum: data.radicatedNum,
            pqrsName: data.pqrsId.pqrsName,
            content: data.content
          }
          setbodyData(bodyBuyerData)
        }
      }
    }

    if (pqrsDetail.requestId !== requestId && requestId) fetchData()
  }, [])

  useEffect(() => {
    if (pqrsDetail.requestId !== requestId && requestId) {
      const action = {
        type: dataTypes.getPqrsDetail,
        payload: {
          pqrsDetail: bodyData
        }
      }
      dataDispatch(action)
    }
  }, [bodyData])
}
