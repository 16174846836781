import React, { useContext, useState } from 'react'
import { NavFooter } from '../../../../components/NavFooter/NavFooter'
import './RejectNotAllowedContact.css'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  handleRejectRequest,
  validateTextarea
} from './RejectNotAllowedContactFunctions'
import { DataContext } from '../../../../context/DataContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const RejectNotAllowedContact = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  const { state: requestData } = useLocation()
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const [showModal, handleShow] = useModal()
  const { employeeId, idToken } = dataState.lawyer
  const [reason, setReason] = useState('')

  return (
    <section id="RejectNotAllowedContact">
      <div className="RejectNotAllowedContactTSide">
        <div className="RejectNotAllowedContactTSideLside">
          <h2>{scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.description : ''}</h2>
          <p>{requestData.content.descripción}</p>
        </div>
        <div className="RejectNotAllowedContactTSideRside">
          <h2>
            {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.forbiddenContact : ''}{' '}
            {requestData.claimTo}{' '}
          </h2>
          <p>
            <span className="boldText">
              {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.user : ''}
            </span>
            {`${requestData.name}${scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.txtBetweenPlanNameAndName : ''}${requestData.userId.planId.planName}`}
          </p>
        </div>
      </div>
      <div className="RejectNotAllowedContactBSide">
        <h2>{scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.responseRejectReason : ''}</h2>
        <textarea
          name="rejectReason"
          id="rejectReason"
          cols="30"
          rows="10"
          value={reason}
          onChange={(e) => validateTextarea(e, setReason)}
        ></textarea>
        <span>{reason.length}{scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.charactersLimit : ''}</span>
      </div>
      <NavFooter returnPath='../EditarContactoNoPermitido' state={requestData}>
        <button
          className="RejectNotAllowedContactSendBtn"
          onClick={() =>
            handleRejectRequest(
              idToken,
              employeeId,
              requestData,
              reason,
              handleShow,
              dataContext
            )
          }
          disabled={reason.length < 50}
        >
          {scriptsSave && scriptsSave.rejectString ? scriptsSave.rejectString.send : ''}
        </button>
      </NavFooter>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.rejectModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={() => navigate('..')}>
            {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.accept : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
