import React, { useContext } from 'react'
import iconLupa from '../../../../assets/icons/icon_lupa.svg'
import iconPlus from '../../../../assets/icons/icon_plus.svg'
import { Form, Button } from 'react-bootstrap'
import { TablaGeneral } from '../../../../components/TablaGeneral/TablaGeneral'
import { useNavigate } from 'react-router-dom'
import { ScriptContext } from '../../../../context/ScriptsContext'
import './EntitiesList.css'

export const EntitiesList = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  return (
    <section>
      <div className="buscardorBotonValidacionesAdmin">
        <Form className="validacionesAdminSearchContainer">
          <Form.Group
            className="searchFieldValidacionesAdminContainer"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              className="searchFieldValidacionesAdmin"
              type="text"
              placeholder={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.searchCompany : ''}
              disabled
            />
            <Button
              className="searchButtonValidacionesAdmin"
              // type="submit"
              // onClick={handleSearchSubmit}
            >
              <img
                className="iconLupa"
                src={iconLupa}
                alt={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.altImageTxt1 : ''}
              />
            </Button>
          </Form.Group>
        </Form>
        <button
          className="btnCrearEmpresaValidacionesAdmin"
          onClick={() => navigate('CrearEntidad')}
        >
          <img
            className="iconBtnCrearEmpresaAdmin"
            src={iconPlus}
            alt={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.altImageTxt2 : ''}
          />
          {scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.createEntity : ''}
        </button>
      </div>
      <TablaGeneral
        first={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.th1Txt : ''}
        second={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.th2Txt : ''}
        third={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.th3Txt : ''}
        fourth={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.th4Txt : ''}
        fifth={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.th5Txt : ''}
        sixth={scriptsSave && scriptsSave.entitiesListString ? scriptsSave.entitiesListString.th6Txt : ''}
      />
    </section>
  )
}
