import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import { Dropdown } from '../../components/Dropdown/Dropdown'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { getPaymentHistoryPerContactNotAllowed } from '../../utils/userQueries'
import { Pagination } from '../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import {
  formatPaymentHistoryPerContactNotAllowedList,
  onDetailsClick
} from './HistoryPaysFunctions'
import watchIcon from '../../assets/icons/icon_ver-operativo.svg'
import { ScriptContext } from '../../context/ScriptsContext'

import './HistoryPays.css'

export const HistoryPays = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, users } = dataState.operative
  const { paymentHistoryPerContactNotAllowed } = users
  const paymentHistoryPerContactNotAllowedList =
    users.paymentHistoryPerContactNotAllowed.list

  const [paginationValues, setPaginationValues] = usePaginateQuery(
    paymentHistoryPerContactNotAllowed,
    'paymentHistoryPerContactNotAllowed',
    getPaymentHistoryPerContactNotAllowed,
    dataTypes.updatePaymentHistoryPerContactNotAllowed,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    paymentHistoryPerContactNotAllowedList,
    formatPaymentHistoryPerContactNotAllowedList,
    page,
    limit
  )

  const navigate = useNavigate()

  const typePlanFilter = [
    scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.plus : '',
    scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.premium : ''
  ]

  return (
    <section id='HistoryPays'>
      <table>
        <thead>
          <tr>
            <th>{scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.thead1 : ''}</th>
            <th>
              <Dropdown
                filterBy={'name'}
                list={originalList}
                title={scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.thead2 : ''}
                // type={'search'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.thead3 : ''}</th>
            <th>
              <Dropdown
                filterBy={'planType'}
                list={originalList}
                options={typePlanFilter}
                title={scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.thead4 : ''}
                type={'select'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.thead5 : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.id}</td>
                <td>{payment.name}</td>
                <td>{payment.paymentDate}</td>
                <td>{payment.planType}</td>
                <td>
                  <button
                    onClick={() =>
                      onDetailsClick(
                        navigate,
                        payment,
                        idToken,
                        dataDispatch,
                        dataContext
                      )
                    }
                  >
                    <img
                      alt={scriptsSave && scriptsSave.historyPaysString ? scriptsSave.historyPaysString.altImageTxt1 : ''}
                      className='editIcon'
                      src={watchIcon}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Pagination
        currentPage={paymentHistoryPerContactNotAllowed.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={paymentHistoryPerContactNotAllowed.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
      />
    </section>
  )
}
