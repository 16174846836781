import { calculateNumberOfDigits } from '../../../../utils/functions'

export const formatEntityWithoutContactChannelList = (entityWithoutContactChannelList, setEntityWithoutContactChannelList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(entityWithoutContactChannelList)
  const currentItem = page
  const listFormatted = entityWithoutContactChannelList.map((response, index) => {
    let id = currentItem * limit - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      userId: response.userId?.userId,
      name: `${response.userId?.personId?.personName} ${response.userId?.personId?.personLastName}`,
      userIdentNum: response.userId?.personId?.personIdentNum,
      companyName: response.companyId?.companyBussinesName,
      companyId: response.demandId
    }
  })

  setEntityWithoutContactChannelList(listFormatted)
}
