import { useContext, useEffect } from 'react'

import { DataContext } from '../../../context/DataContext'
import { uploadMassive } from '../../../utils/queries'

export const useUploadMassive = (setProgress) => {
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { csvData, company } = dataState.operative.users.massiveUploads

  useEffect(() => {
    setTimeout(() => {
      try {
        uploadMassive(dataContext, company, csvData, setProgress)
      } catch (error) {
        console.error('Error procesando el archivo:', error)
      }
    }, 500)
  }, [])
}
