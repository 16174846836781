import { useState, useEffect } from 'react'

export const useModal = () => {
  const [showModal, setShowModal] = useState(false)

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (showModal) {
        event.preventDefault() // Previene la acción predeterminada del teclado
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [showModal])

  return [showModal, handleShow, handleClose]
}
