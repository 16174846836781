import React from 'react'
import './UserRequestDataContainer.css'

export const UserRequestDataContainer = ({ title, data }) => {
  return (
    <div className="userRequestDataContainer">
      <h3 className="userRequestDataTitle">{title}</h3>
      <p className="userRequestDataParagraph">{data}</p>
    </div>
  )
}
