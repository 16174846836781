import { useEffect, useState } from 'react'

import { getCompaniesToMassiveUploads } from '../../../utils/userQueries'
import { dataTypes } from '../../../context/reducers/dataReducer/dataTypes'

export const useGetCompanies = (idToken, dataDispatch, dataContext) => {
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    const getCompanies = async () => {
      const companiesToMassiveUploads = await getCompaniesToMassiveUploads(dataContext, idToken)
      if (companiesToMassiveUploads.response) setCompanies(companiesToMassiveUploads.body)
    }

    getCompanies()

    const action = {
      type: dataTypes.setCompanyOnMassiveUploads,
      payload: ''
    }
    dataDispatch(action)
  }, [])
  return {
    companies
  }
}
