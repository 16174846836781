import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../../../utils/functions'

export const formatUnreadPqrsList = (unreadPqrsList, setUnreadPqrsList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(unreadPqrsList)
  const currentItem = page

  const listFormatted = unreadPqrsList.map((pqrs, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      name: pqrs.content.match(/Nombre: (.+?)\n/)[1],
      tel: pqrs.content.match(/Teléfono: (.+?)\n/)[1],
      email: pqrs.content.match(/Email: (.+?)\n/)[1],
      asunto: pqrs.content.match(/Solicitud: (.+?)$/)[1],
      requestId: pqrs.requestId,
      pqrsId: pqrs.pqrsId.pqrsId
    }
  })
  setUnreadPqrsList(listFormatted)
}

export const onDetailsClick = (navigate, pqr, dataDispatch) => {
  const action = {
    type: dataTypes.setPqrsOfUnregisteredUsers,
    payload: pqr
  }
  dataDispatch(action)

  navigate('./Response')
}
