import React, { useContext, useState } from 'react'
import iconUpload from '../../../../../../assets/icons/icon_subir_archivos.svg'
import iconBien from '../../../../../../assets/icons/icon_bien.svg'
import './SelectProcessNoContact.css'
import { sendNewProcessSubmit } from './SelectProcessNoContactFunctions'
import { DataContext } from '../../../../../../context/DataContext'
import { Modal } from '../../../../../../components/Modal/Modal'
import { useModal } from '../../../../../../hooks/useModal'
import { useNavigate } from 'react-router-dom'
import { ScriptContext } from '../../../../../../context/ScriptsContext'

export const SelectProcessNoContact = ({ isActive, demandId }) => {
  const { scriptsSave } = useContext(ScriptContext)
  if (!isActive) return null
  const dataContext = useContext(DataContext)
  const [showModal, handleShow] = useModal()
  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(false)
  const [file, setFile] = useState(null)
  return (
    <section id='SelectProcessNoContact'>
      <div className='SelectProcessNoContactBox'>
        <h2 className='SelectProcessNoContactTitle'>
          {scriptsSave && scriptsSave.SelectProcessNoContactString ? scriptsSave.SelectProcessNoContactString.selectProcess : ''}
        </h2>
        <div className='SelectProcessNoContactCheck'>
          <input type="checkbox" className='SelectProcessNoContactCheckbox'
            onChange={(e) => setIsChecked(e.target.checked)} />
          <p>{scriptsSave && scriptsSave.SelectProcessNoContactString ? scriptsSave.SelectProcessNoContactString.title1 : ''}</p>
        </div>
      </div>
      <div className='SelectProcessNoContactBtnBox'>
        <div>
          <div>
            {
              file && (
                <div className='selectProcessNoContactUploadedFileContainer'>
                  <img
                    src={iconBien}
                    alt={scriptsSave && scriptsSave.SelectProcessNoContactString ? scriptsSave.SelectProcessNoContactString.altImageTxt1 : ''}
                    className='selectProcessNoContactUploadedFileImg'
                  />
                  <p>{file?.name}</p>
                </div>
              )
            }
            <input type="file" id='uploadFileEntityWithoutChannel' onChange={(e) => setFile(e.target.files[0])} />
            <label htmlFor='uploadFileEntityWithoutChannel' className='SelectProcessSendButton'>
              {scriptsSave && scriptsSave.SelectProcessNoContactString ? scriptsSave.SelectProcessNoContactString.uploadAnnexes : ''}
              <img
                src={iconUpload}
                className='SelectProcessUploadButtonImg'
                alt={scriptsSave && scriptsSave.SelectProcessNoContactString ? scriptsSave.SelectProcessNoContactString.altImageTxt2 : ''}
              />
            </label>
          </div>
          <button
            className='SelectProcessSendButton'
            onClick={() => sendNewProcessSubmit(dataContext, file, demandId, handleShow)}
            disabled={!isChecked || !file}
          >
            {scriptsSave && scriptsSave.SelectProcessNoContactString ? scriptsSave.SelectProcessNoContactString.send : ''}
          </button>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.processSuccess : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={() => navigate('../EntidadesSinCanalDeContacto')}>
            {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.accept : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
