import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../../../utils/functions'
import { getPaymentOfEntitiesMovementHistory } from '../../../../utils/userQueries'

export const formatPaymentOfEntitiesList = (paymentOfEntitiesList, setPaymentOfEntitiesListFormatted, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(paymentOfEntitiesList)
  const currentItem = page

  setPaymentOfEntitiesListFormatted(paymentOfEntitiesList.map((payment, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      shoppingHistoryId: payment.shoppingHistoryId, //
      entityName: payment.companyBussinesName,
      nit: payment.companyNit
    }
  }))
}

export const onDetailsClick = async (
  navigate,
  shoppingHistoryId,
  idToken,
  dataDispatch,
  dataContext
) => {
  const paymentOfEntitiesMovementHistoryResponse = await getPaymentOfEntitiesMovementHistory(dataContext, shoppingHistoryId, idToken)

  if (paymentOfEntitiesMovementHistoryResponse.response) {
    const action = {
      type: dataTypes.setPaymentOfEntityToBeReviewed,
      payload: {
        movementHystory: paymentOfEntitiesMovementHistoryResponse.body
      }
    }
    dataDispatch(action)
    navigate('../PagoEntidades/MovimientosEntidad')
  }
}
