import React, { useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import './EntitiesLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const EntititesLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { pathname } = useLocation()
  return (
    <section>
      <div className="validacionesAdminHeaderContainer">
        <p className={'validacionesAdminParagraph'}>
          {
            pathname.endsWith('CrearEntidad')
              ? (scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.createEntities : '')
              : (scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.validatedCompanies : '')
          }
        </p>
      </div>
      <hr className="hrValidacionesAdmin" />
      <Outlet />
    </section>
  )
}
