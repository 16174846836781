import React, { useContext, useState } from 'react'
import cardControl from '../../../../assets/icons/cardControl.png'
import cardPremium from '../../../../assets/icons/cardPremium.png'
import iconVolver from '../../../../assets/icons/icon_volver.svg'

import { DataContext } from '../../../../context/DataContext'

import { handleClick1, handleClick2, onConfirmDeletionClick, onConfirmDisablementClick } from './UserDetailFunctions'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { Link, useNavigate } from 'react-router-dom'
import { formatDateDDMMYYMonthInLetters } from '../../../../utils/functions'
import './UserDetail.css'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const UserDetail = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, users } = dataState.operative
  const { deleteRequestsToBeReviewed } = users
  const { planName } = deleteRequestsToBeReviewed

  const [showModal, handleShow, handleClose] = useModal()
  const [selectedModal, setSelectedModal] = useState()

  const navigate = useNavigate()

  const [isRequesting, setIsRequesting] = useState(false)

  return (
    <section className="userDetail">
      <h2 className="userDetailTitle">
        {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.title : ''}
      </h2>
      <div className="userDetailContent">
        <div className="userDetailPlanContainer">
          <img
            src={planName === 'Premium' ? cardPremium : planName === 'Control' ? cardControl : ''}
            alt={scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.imgAlt : ''}
            className="userDetailPlanImage"
          />
        </div>
        <div className="userDetailInfoContainer">
          <h2 className="userDetailInfoTitle">
            {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.subtitle1 : ''}
          </h2>
          <div className="userDetailSpecificInfo">
            <div className="userDetailSpecificContainer">
              <h2 className="userDetailSpecificTitle">
                {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card1Title1 : ''}
              </h2>
              <p className="userDetailSpecificDescription">
                {deleteRequestsToBeReviewed.nameInList}
              </p>
            </div>
            <div className="userDetailSpecificContainer">
              <h2 className="userDetailSpecificTitle">
                {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card1Title2 : ''}
              </h2>
              <p className="userDetailSpecificDescription">
                {deleteRequestsToBeReviewed.identType}
              </p>
            </div>
            <div className="userDetailSpecificContainer">
              <h2 className="userDetailSpecificTitle">
                {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card1Title3 : ''}
              </h2>
              <p className="userDetailSpecificDescription">
                {deleteRequestsToBeReviewed.phone}
              </p>
            </div>
            <div className="userDetailSpecificContainer">
              <h2 className="userDetailSpecificTitle">
                {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card1Title4 : ''}
              </h2>
              <p className="userDetailSpecificDescription">
                {deleteRequestsToBeReviewed.identNum}
              </p>
            </div>
            <div className="userDetailSpecificContainer">
              <h2 className="userDetailSpecificTitle">
                {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card1Title5 : ''}
              </h2>
              <p className="userDetailSpecificDescription">
                {deleteRequestsToBeReviewed.email}
              </p>
            </div>
          </div>
        </div>
        <div className="userDetailInfoContainer">
          <h2 className="userDetailInfoTitle">
            {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.subtitle2 : ''}
          </h2>
          <div className="userDetailGeneralContainer">
            <h2 className="userDetailSpecificTitle">
              {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card2Title1 : ''}
            </h2>
            <p className="userDetailSpecificDescription">
              {formatDateDDMMYYMonthInLetters(deleteRequestsToBeReviewed.createDate)}
            </p>
          </div>
          <div className="userDetailGeneralContainer">
            <h2 className="userDetailSpecificTitle">
              {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.card2Title2 : ''}
            </h2>
            <p className="userDetailSpecificDescription">
              {`$${deleteRequestsToBeReviewed.balance}`}
            </p>
          </div>
        </div>
        <div></div>
        <div className="userDetailInfoContainer">
          <h2 className="userDetailInfoTitle">
            {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.subtitle3 : ''}
          </h2>
          <p className='userDetailInfoRequestText'>
            {deleteRequestsToBeReviewed.requestContent}
          </p>
        </div>
        <div className="userDetailInfoContainer2">
          {
            deleteRequestsToBeReviewed.pqrsName === 'Eliminar Cuenta'
              ? (
                <button
                  onClick={() => handleClick1(handleShow, setSelectedModal)}
                  className="userDetailAction"
                >
                  {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.buttonTxt1 : ''}
                </button>
                )
              : (
                <button
                  onClick={() => handleClick2(handleShow, setSelectedModal)}
                  className="userDetailAction"
                >
                  {scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.buttonTxt2 : ''}
                </button>
                )
          }
        </div>
        <Link to='../SolicitudesEliminacion' className='userDetailBackContainer'>
          <img
            src={iconVolver}
            alt={scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.imgAlt2 : ''}
          />
          <p>{scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.backBtnTxt : ''}</p>
        </Link>
      </div>
      {selectedModal === 'inactivar'
        ? <Modal isOpen={showModal}>
          <Modal.Content>
            <p>{scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.inactiveUser : ''}</p>
          </Modal.Content>
          <Modal.Buttons>
            <button
              className='Modal-normalBtn'
              onClick={handleClose}>
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.cancel : ''}
            </button>
            <button
              className={`${isRequesting ? 'Modal-orangeBtn-disabled' : 'Modal-orangeBtn'}`}
              onClick={() => onConfirmDisablementClick(
                deleteRequestsToBeReviewed,
                handleClose,
                navigate,
                idToken,
                dataContext,
                isRequesting,
                setIsRequesting
              )}
            >
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.continue : ''}
            </button>
          </Modal.Buttons>
        </Modal>
        : selectedModal === 'eliminar'
          ? <Modal isOpen={showModal}>
            <Modal.Content>
              <p>{scriptsSave && scriptsSave.userDetailString ? scriptsSave.userDetailString.deleteUser : ''}</p>
            </Modal.Content>
            <Modal.Buttons>
              <button
                className='Modal-normalBtn'
                onClick={handleClose}
              >
                {scriptsSave && scriptsSave.compString ? scriptsSave.compString.cancel : ''}
              </button>
              <button
                className={`${isRequesting ? 'Modal-orangeBtn-disabled' : 'Modal-orangeBtn'}`}
                onClick={() => onConfirmDeletionClick(
                  deleteRequestsToBeReviewed,
                  handleClose,
                  navigate,
                  idToken,
                  dataContext,
                  isRequesting,
                  setIsRequesting
                )}
              >
                {scriptsSave && scriptsSave.compString ? scriptsSave.compString.continue : ''}
              </button>
            </Modal.Buttons>
          </Modal>
          : null
      }
    </section>
  )
}
