import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../../context/DataContext'
import { getUserNotActive } from '../../../utils/userQueries'
import { dataTypes } from '../../../context/reducers/dataReducer/dataTypes'

export const useFetchUserNotActive = () => {
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, newEntries } = dataState.operative
  const { userId } = newEntries.userEntryToBeReviewed
  const [userNotActiveData, setUserNotActiveData] = useState({
    credentialId: '',
    userId: '',
    name: '',
    cell: '',
    email: '',
    documentIdType: '',
    documentIdNum: '',
    gender: '',
    documentPath: ''
  })

  useEffect(() => {
    const fetchUserNotActive = async () => {
      const { response, body } = await getUserNotActive(
        userId,
        idToken,
        dataContext
      )

      if (response && body) {
        const userIdentity = body.userId.personId
        const data = {
          credentialId: body.credentialId,
          userId: body.userId.userId,
          name: `${userIdentity.personName} ${userIdentity.personLastName}`,
          cell: userIdentity.personPhone,
          email: body.credentialEmail,
          documentIdType: userIdentity.identDocId.identDocName,
          documentIdNum: userIdentity.personIdentNum,
          gender: userIdentity.genderId.genderType,
          documentPath: body.userId.userDocumentPath
        }

        setUserNotActiveData(data)

        const action = {
          type: dataTypes.setUserEntryToBeModified,
          payload: data
        }

        dataDispatch(action)
      }
    }
    fetchUserNotActive()
  }, [])

  return userNotActiveData
}
