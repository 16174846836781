import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import especializedBD from '../../assets/images/BDEspecializada.png'
import simpleBD from '../../assets/images/BDSimple.png'
import backIcon from '../../assets/icons/icon_volver.svg'
import {
  formatDateDDMMYYMonthInLetters,
  isExpired
} from '../../utils/functions'

import './DetalleDB.css'
import { getShoppingInfo } from '../../utils/userQueries'
import { shoppingInfoObject } from '../../utils/objects'
import { ScriptContext } from '../../context/ScriptsContext'

export const DetalleDB = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { databaseToBeReviewed } = dataState.administrative.dataBases
  const { companyComertialName } = databaseToBeReviewed.buyerId.companyId
  const { databaseType } = databaseToBeReviewed.databaseTypeId
  const [shoppingInfo, setShoppingInfo] = useState(shoppingInfoObject)
  const [dataBaseName, setDataBaseName] = useState(null)
  const [realPathCorrectLength, setRealPathCorrectLength] = useState(true)
  const [isDateExpired, setIsDateExpired] = useState(false)

  const getDataBaseUrl = async () => {
    const getInfo = await getShoppingInfo(
      dataContext,
      dataState.administrative.idToken,
      databaseToBeReviewed.shoppingHistoryId
    )
    if (getInfo.response) {
      const url = getInfo.body.shoppingHistory.databasePath
      setIsDateExpired(isExpired(getInfo.body.shoppingHistory.expeditionDate))
      const realPath = url.split('/')
      setRealPathCorrectLength(realPath.length === 5)
      setDataBaseName(realPath[realPath.length - 1])
    }

    setShoppingInfo(getInfo.body)
  }
  useEffect(() => {
    getDataBaseUrl()
  }, [])

  return (
    <>
      <div className="detalleDBMainContainer">
        <div id="detalleDBSubContainerA">
          <div id="detalleDBInfoContainer">
            <h2 id="detalleDBInfoContainerTitle">
              {scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.title : ''}
            </h2>
            <div className="detalleDBInfoContainerDataRow">
              <p className="detalleDBKey">
                {scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.label1 : ''}
              </p>
              <p className="detalleDbPContainer">{companyComertialName}</p>
            </div>
            <div className="detalleDBInfoContainerDataRow">
              <p className="detalleDBKey">
                {scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.label2 : ''}
              </p>
              <p className="detalleDbPContainer">
                {
                  isDateExpired
                    ? (scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.dbStatus2 : '')
                    : (scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.dbStatus1 : '')
                }
              </p>
            </div>
            <div className="detalleDBInfoContainerDataRow">
              <p className="detalleDBKey">
                {scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.label3 : ''}
              </p>
              <p className="detalleDbPContainer">
                {
                  formatDateDDMMYYMonthInLetters(
                    shoppingInfo.shoppingHistory.shoppingDate
                  )
                }
              </p>
            </div>
            <div className="detalleDBInfoContainerDataRow">
              <p className="detalleDBKey">
                {scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.label4 : ''}
              </p>
              <p className="detalleDbPContainer">
                {
                  formatDateDDMMYYMonthInLetters(
                    shoppingInfo.shoppingHistory.expeditionDate
                  )
                }
              </p>
            </div>
          </div>
          <img
            alt={scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.altImageTxt1 : ''}
            className="detalleDBSubContainerACardImg"
            src={
              databaseType === (scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.dbType1 : '')
                ? simpleBD
                : especializedBD
            }
          />
        </div>
        <div id="detalleDBSubContainerB">
          <div id="detalleDBSubContainerB-tablebox">
            <table>
              <thead>
                <tr>
                  <th>{scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.subtitle1 : ''}</th>
                  <th>{`${scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.subtitle2 : ''}${databaseType}`}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{shoppingInfo.shoppingHistory.usersAmount}</td>
                  <td>
                    {shoppingInfo.shoppingHistory.databasePath
                      ? dataBaseName
                      : ''}
                  </td>
                  <td>
                    {realPathCorrectLength
                      ? (
                      <button className="attachmentArchives">
                        <a href={shoppingInfo.download} target="blank">
                          {scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.buttonText1 : ''}
                        </a>
                      </button>
                        )
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="detalleDBFooter">
            <NavLink to={-1} className="detalleDBFooterBackLink">
              <img
                src={backIcon}
                alt={scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.altImageTxt2 : ''}
              />
              <span>{scriptsSave && scriptsSave.detalleDBStrings ? scriptsSave.detalleDBStrings.footerButton1Text1 : ''}</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}
