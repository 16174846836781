import React, { useContext, useState } from 'react'
import { ImageModal } from '../../../../../components/ImageModal/ImageModal'
import { useModal } from '../../../../../hooks/useModal'
import fileIcon from '../../../../../assets/icons/icon_archivo.svg'
import watchIcon from '../../../../../assets/icons/icon_ver-operativo.svg'

import './EditNotAllowedContactRow.css'
import { handleViewDocument } from '../../../../../utils/functions'
import { ScriptContext } from '../../../../../context/ScriptsContext'

export const EditNotAllowedContactRow = ({ name, path }) => {
  const { scriptsSave } = useContext(ScriptContext)
  const [showModal, handleShow, handleClose] = useModal()
  const [, setPathImg] = useState(null)
  return (
    <>
      <tr className="editNotAllowedContactRow">
        <td className="editNotAllowedContactCell editNotAllowedContactFile">
          <img
            className="editNotAllowedContactFileImage"
            src={fileIcon}
            alt={scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.altImageTxt2 : ''}
          />
          <p className="editNotAllowedContactFileText">{name}</p>
        </td>
        <td className="editNotAllowedContactCell editNotAllowedContactButton textEnd">
          <button
            className="editNotAllowedContactSeeBtn"
            onClick={() =>
              handleViewDocument(name, path, setPathImg, handleShow)
            }
          >
            <img
              src={watchIcon}
              alt={scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.altImageTxt3 : ''}
            />
          </button>
        </td>
      </tr>
      <ImageModal
        isOpen={showModal}
        title={scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.modalImgTitle1 : ''}
        imageSrc={path}
      >
        <ImageModal.Buttons buttonContainerClass="imageModalButtons">
          <button
            className="editNotAllowedContactModalBtn"
            onClick={handleClose}
          >
            {scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.modalBtnTxt1 : ''}
          </button>
        </ImageModal.Buttons>
      </ImageModal>
    </>
  )
}
