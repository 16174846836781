import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../../../utils/functions'
import { getUserPocketMovementHistory } from '../../../../utils/userQueries'

export const formatUserPocketList = (userPocketList, setUserPocketListFormatted, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(userPocketList)
  const currentItem = page

  setUserPocketListFormatted(userPocketList.map((obj, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      userId: obj.userId,
      name: `${obj.personId.personName} ${obj.personId.personLastName}`,
      identNum: obj.personId.personIdentNum,
      email: obj?.credentialId?.credentialEmail !== null ? obj?.credentialId?.credentialEmail : ''
    }
  }))
}

export const onDetailsClick = async (
  navigate,
  userPocket,
  userId,
  idToken,
  dataDispatch,
  dataContext
) => {
  const userPocketMovementHistoryResponse = await getUserPocketMovementHistory(dataContext, userId, idToken)

  const { name, email } = userPocket

  if (userPocketMovementHistoryResponse.response) {
    const action = {
      type: dataTypes.setUserPocketToBeReviewed,
      payload: {
        name,
        email,
        movementHystory: userPocketMovementHistoryResponse.body
      }
    }
    dataDispatch(action)
    navigate('MovimientosUsuario')
  }
}
