import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { getOneResponseByEntity } from '../../utils/userQueries'

export const onActionsClick = async (
  response,
  dataContext,
  idToken,
  navigate
) => {
  const emailId = response.id
  const { dataDispatch } = dataContext

  const getOneResponseByEntityResponse = await getOneResponseByEntity(idToken, dataContext, emailId)
  if (getOneResponseByEntityResponse.response) {
    const action = {
      type: dataTypes.setResponseByEntityDetail,
      payload: getOneResponseByEntityResponse.body
    }
    dataDispatch(action)

    navigate('RespuestaEntidad')
  }
}
