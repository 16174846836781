import React, { useContext, useState } from 'react'
import { DataContext } from '../../../../../../context/DataContext'
import { Modal } from '../../../../../../components/Modal/Modal'
import { useModal } from '../../../../../../hooks/useModal'
import { dashedFormatDateDDMMYYYY } from '../../../../../../utils/functions'
import { downloadAnnexes, handlefinishProcess, uploadNewAnnexes } from './DocumentationProcessFunctions'
import iconUpload from '../../../../../../assets/icons/icon_subir_archivos.svg'
import iconDownload from '../../../../../../assets/icons/icon_bajar_archivos.svg'
import iconCheck from '../../../../../../assets/icons/icon_bien.svg'
import './DocumentationProcess.css'
import { ScriptContext } from '../../../../../../context/ScriptsContext'

export const DocumentationProcess = ({ isActive, setActiveViews, demandSelected }) => {
  const { scriptsSave } = useContext(ScriptContext)
  if (!isActive) return null
  const [showModal, handleShow] = useModal()
  const dataContext = useContext(DataContext)
  const [file, setFile] = useState(null)
  const [radicateNum, setRadicateNum] = useState(null)
  const [downloadAnnexesDisabled, setDownloadAnnexesDisabled] = useState(false)
  return (
    <section id='DocumentationProcess'>
        <div className='DocumentationProcessTableBox'>
            <table className='DocumentationProcessTable'>
                <thead>
                    <tr>
                        <th className='DocumentationProcessTitle'>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.column1Name : ''}
                        </th>
                        <th className='DocumentationProcessTitle'>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.column2Name : ''}
                        </th>
                        <th className='DocumentationProcessTitle'>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.column3Name : ''}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='DocumentationProcessText'>{demandSelected.name}</td>
                        <td className='DocumentationProcessText'>{demandSelected.status}</td>
                        <td className='DocumentationProcessText'>{dashedFormatDateDDMMYYYY(demandSelected.createDate)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className='DocumentationProcessBSide'>
            <div className='DocumentationProcessBSideLSide'>
                <h2 className='CaseTrackingEntityDetailTitle'>
                  {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.documentationTitle : ''}
                </h2>
                <div className='DocumentationProcessBSideLSideBox'>
                    <div className='DocumentationProcessBSideLSideBoxLSide'>
                        <p className='DocumentationProcessBSideText'>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.downloadAnnexes : ''}
                        </p>
                        <button
                          className='DocumentationProcessBSideBtn'
                          onClick={() => downloadAnnexes(dataContext, demandSelected.demandId, setDownloadAnnexesDisabled)}
                          disabled={downloadAnnexesDisabled}
                          >
                            {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.annexes : ''}
                            <img src={iconDownload} className='SelectProcessUploadButtonImg'
                            alt={scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.altImageTxt1 : ''}
                          />
                        </button>
                    </div>
                    <div className='DocumentationProcessBSideLSideBoxRSide'>
                        <p className='DocumentationProcessBSideText'>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.addNewAnnex : ''}
                        </p>
                        <input
                          type="file"
                          id='uploadAnnexes'
                          onChange={(e) => setFile(e.target.files[0])}/>
                        <label htmlFor="uploadAnnexes" className='DocumentationProcessBSideBtn'>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.addAnnex : ''}
                          <img
                            src={iconUpload}
                            className='SelectProcessUploadButtonImg'
                            alt={scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.altImageTxt2 : ''}
                          />
                        </label>
                        <button
                          className='DocumentationProcessBSideBtn'
                          onClick={() => uploadNewAnnexes(dataContext, demandSelected.demandId, file, setFile)}
                          disabled={!file}>
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.sendAnnex : ''}
                        </button>
                        <span className={`DocumentationProcessBSideMessage ${file ? 'isVisible' : ''}`}>
                          <img
                            src={iconCheck}
                            alt={scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.altImageTxt3 : ''}
                          />
                          {file?.name.replace(/\s/g, '_')}
                        </span>
                    </div>
                </div>
            </div>
            <div className='DocumentationProcessBSideRSide'>
                <div className='DocumentationProcessBSideRSideBox'>
                    <p className='DocumentationProcessBSideText'>
                      {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.acceptOrReject : ''}
                      <br />
                      {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.asAppropiate : ''}
                    </p>
                    <div className='DocumentationProcessBSideRSideBtnBox'>
                        <button
                          className='DocumentationProcessBSideBtn'
                          onClick={() => handlefinishProcess(dataContext, 2, handleShow, demandSelected.demandId, setRadicateNum)}
                          disabled={demandSelected.status === 'Finalizado'}
                        >
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.accept : ''}
                        </button>
                        <button
                          className='DocumentationProcessBSideBtn'
                          onClick={() => handlefinishProcess(dataContext, 3, handleShow, demandSelected.demandId, setRadicateNum)}
                          disabled={demandSelected.status === 'Rechazado'}
                        >
                          {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.reject : ''}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Modal isOpen={showModal}>
          <Modal.Content>
            <p>{`
              ${scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.processFinished.split('*')[0] : ''}
              ${radicateNum}
              ${scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.processFinished.split('*')[2] : ''}
            `}</p>
            <p>{scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.userNoticed : ''}</p>
          </Modal.Content>
          <Modal.Buttons>
            <button
              className='Modal-orangeBtn'
              onClick={() => setActiveViews((activeViews) => ({ ...activeViews, activeProcessListActive: true, documentationProcess: false }))}>
                {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.accept : ''}
              </button>
          </Modal.Buttons>
        </Modal>
    </section>
  )
}
