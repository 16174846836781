import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Anchor } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import logo from '../../assets/icons/logo.svg'
import openEyeIcon from '../../assets/icons/icon_pass2.svg'
import closeEyeIcon from '../../assets/icons/icon_pass1.svg'
import redWarningIcon from '../../assets/icons/icon_red_warning.svg'
import { DataContext } from '../../context/DataContext'
import { useModal } from '../../hooks/useModal'
import { Modal } from '../../components/Modal/Modal'
import {
  maxSize,
  validateEmailFormat,
  validatePassFormat
} from '../../utils/validateFunctions'
import {
  handleEyeIconClick,
  login,
  onEmailChange,
  onNotValidEmailBlur,
  onNotValidPasswordBlur,
  onPasswordChange
} from './LoginFunctions'

import { Amplify as AmplifyClass } from '../../utils/amplify'

import './Login.css'
import { getUserData } from '../../utils/functions'
import { ScriptContext } from '../../context/ScriptsContext'

export const Login = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const amplify = new AmplifyClass()
  amplify.sign()

  const dataContext = useContext(DataContext)
  const { dataDispatch } = dataContext
  const navigate = useNavigate()

  const [loginValues, setLoginValues] = useState({ email: '', password: '' })

  const [incorrectInputFormat, setIncorrectInputFormat] = useState({
    inputFormatClass: 'loginRightFormat',
    inputFormatMessage: ''
  })

  const [passwordState, setPasswordState] = useState({
    eyeIcon: openEyeIcon,
    isPasswordVisible: false,
    passwordInputType: 'password'
  })

  const [modalMessage, setModalMessage] = useState('')
  const [showModal, handleShow, handleClose] = useModal()
  const [isRequesting, setIsRequesting] = useState(false)
  const [remindMe, setRemindMe] = useState(false)

  const emailInputRef = useRef()
  const passwordInputRef = useRef()

  useEffect(() => {
    const data = getUserData()
    if (data.adminEmail && data.adminPass) {
      const reminded = {
        email: data.adminEmail,
        password: data.adminPass
      }
      setLoginValues(reminded)
    }
  }, [])

  return (
    <Container className="loginContainer">
      <div className="loginSubcontainer1">
        <div className="loginLogoContainer">
          <div className='loginContainerLogoImg'>
            <img
              className="loginLogo"
              src={logo}
              alt={scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.altImageTxt1 : ''}
            />
            <p className="loginPortalRole">{scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.portalRole : ''}</p>
          </div>
        </div>
        <h1 className="loginTitle">{scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.portalType : ''}</h1>
        <div className="loginBackContainer"></div>
      </div>
      <div className="loginSubcontainer2">
        <Form
          className="loginForm"
          onSubmit={(e) =>
            login(
              e,
              loginValues.email,
              loginValues.password,
              dataDispatch,
              handleShow,
              setModalMessage,
              isRequesting,
              setIsRequesting,
              navigate,
              emailInputRef,
              passwordInputRef,
              incorrectInputFormat,
              setIncorrectInputFormat,
              remindMe,
              scriptsSave && scriptsSave.logInString
            )
          }
        >
          <h2 className="loginSubtitle">{scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.title1 : ''}</h2>
          <p className="loginDescription">{scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.description1 : ''}</p>
          <div className={incorrectInputFormat.inputFormatClass}>
            <div className="loginWarningIconContainer">
              <img
                src={redWarningIcon}
                alt={scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.altImageTxt2 : ''}
              />
            </div>
            <span className="logininputFormatMessage">
              {incorrectInputFormat.inputFormatMessage}
            </span>
          </div>
          <FloatingLabel
            className="mb-4 loginUserInputContainer"
            label={scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.usernameLabel : ''}
          >
            <Form.Control
              autoComplete="off"
              id="email"
              placeholder={loginValues.email === '' ? ' ' : loginValues.email}
              value={loginValues.email}
              onBlur={() =>
                onNotValidEmailBlur(
                  scriptsSave && scriptsSave.logInString,
                  loginValues.email,
                  incorrectInputFormat,
                  setIncorrectInputFormat
                )
              }
              onChange={(e) => {
                onEmailChange(e, loginValues, setLoginValues)
                setLoginValues({ ...loginValues, email: e.target.value })
              }
              }
              onInput={(e) => maxSize(e, 'email')}
              ref={emailInputRef}
              type="email"
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-4 loginUserInputContainer"
            label={scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.passwordLabel : ''}
          >
            <Form.Control
              autoComplete="off"
              id="password"
              onBlur={() =>
                onNotValidPasswordBlur(
                  scriptsSave && scriptsSave.logInString,
                  loginValues.password,
                  incorrectInputFormat,
                  setIncorrectInputFormat
                )
              }
              onChange={(e) => {
                onPasswordChange(e, loginValues, setLoginValues)
                setLoginValues({ ...loginValues, password: e.target.value })
              }
              }
              onInput={(e) => maxSize(e, 'password')}
              ref={passwordInputRef}
              type={passwordState.passwordInputType}
              placeholder={loginValues.password === '' && ' '}
              value={loginValues.password}
            />
            <Anchor
              className="showPassButtonInicioSesion"
              onClick={() =>
                handleEyeIconClick(
                  passwordState,
                  setPasswordState,
                  openEyeIcon,
                  closeEyeIcon
                )
              }
            >
              <img
                className="loginIconEye"
                src={passwordState.eyeIcon}
                alt={scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.altImageTxt3 : ''}
              />
            </Anchor>
          </FloatingLabel>
          <Container className="loginAditionaOptions">
            <div className="loginAditionaOptions">
              {['checkbox'].map((type) => (
                <div key={`inline-${type}`} className="">
                  <Form.Check
                    className="loginRememberData"
                    id={`inline-${type}-1`}
                    inline
                    label={scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.rememberLabel : ''}
                    name="group1"
                    type={type}
                    onClick={() => setRemindMe(!remindMe)}
                  />
                </div>
              ))}
            </div>
            {/* <div>
              <Link className='loginForgetPassword' to='/login/RecuperarContrasena'>{logInString.forgotPassword}</Link>
            </div> */}
          </Container>
          <button
            disabled={
              isRequesting ||
              !validateEmailFormat(loginValues.email) ||
              !validatePassFormat(loginValues.password)
            }
            className="loginButton"
          >
            {scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.loginButton : ''}
          </button>
        </Form>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={handleClose} className="Modal-greenBtn">
            {scriptsSave && scriptsSave.logInString ? scriptsSave.logInString.ok : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </Container>
  )
}
