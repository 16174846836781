import { patchDeleteUser } from '../../utils/userQueries'
import { handleRequestButton, hasRepeatedCharactersAtMiddle, validateNoWhiteSpacesAtBeginning } from '../../utils/validateFunctions'

export const onSendUserDeletionClick = (
  navigate
) => {
  navigate('../')
}

export const onValidateTextareaChange = (e, setResponseFields, setSendBtnDisabled) => {
  const text = e.target.value

  if (text.length < 40) {
    setSendBtnDisabled(true)
  } else {
    setSendBtnDisabled(false)
  }

  if (text.length > 200) return

  if (validateNoWhiteSpacesAtBeginning(text)) {
    if (hasRepeatedCharactersAtMiddle(text)) {
      return ''
    }
    setResponseFields(prev => {
      return { ...prev, description: text }
    })
  }
}

export const onCleanClick = (setResponseFields, setSendBtnDisabled) => {
  setSendBtnDisabled(true)

  setResponseFields(prev => {
    return {
      ...prev,
      description: ''
    }
  })
}

export const onSendClick = async (
  credentialId,
  responseFields,
  idToken,
  handleShow,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  const { email, description } = responseFields
  if (!credentialId || !email || !description) return

  handleRequestButton(setIsRequesting)
  const patchDeleteUserResponse = await patchDeleteUser(dataContext, credentialId, responseFields, idToken)
  if (patchDeleteUserResponse.response) handleShow()
}
