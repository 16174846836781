import { calculateNumberOfDigits, slashedFormatDateDDMMYYYY } from '../../utils/functions'

export const formatDataBaseList = (dataBaseList, setDataBaseListFormatted, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(dataBaseList)
  const currentItem = page

  setDataBaseListFormatted(dataBaseList.map((dataBase, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      ...dataBase,
      id: String(id + index).padStart(numberOfDigits, '0'),
      expeditionDate: slashedFormatDateDDMMYYYY(dataBase.expeditionDate),
      name: dataBase.buyerId.companyId.companyComertialName,
      databaseType: dataBase.databaseTypeId.databaseType
    }
  }))
}
