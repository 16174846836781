import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../../../utils/functions'

export const formatNewEntitiesList = (entitiesList, setEntitiesList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(entitiesList)
  const currentItem = page

  const listFormatted = entitiesList.map((entry, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      buyerCredentialId: entry.buyerCredentialId,
      companyComertialName: entry.buyerId.companyId.companyComertialName,
      companyNit: entry.buyerId.companyId.companyNit,
      companyEmail: entry.buyerId.companyId.companyEmail
    }
  })
  setEntitiesList(listFormatted)
}

export const onEditClick = (
  navigate,
  entity,
  dataDispatch
) => {
  const action = {
    type: dataTypes.setNewEntityEntryToBeReviewd,
    payload: { buyerCredentialId: entity.buyerCredentialId }
  }
  dataDispatch(action)

  navigate('ValidacionEntidad')
}
