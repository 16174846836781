import { useEffect, useState } from 'react'

export const useFilterBySearch = (
  list,
  formatListFunction,
  page,
  limit
) => {
  const [listFormatted, setListFormatted] = useState([])
  const [originalList, setOriginalList] = useState([])

  useEffect(() => {
    if (originalList.length > 0) setOriginalList([])
    formatListFunction(list, setListFormatted, page, limit)
  }, [list])

  if (listFormatted.length > 0 && originalList.length === 0) setOriginalList(listFormatted)
  return { originalList, listFormatted, setListFormatted }
}
