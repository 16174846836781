import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Modal } from '../../../../components/Modal/Modal'
import checkIcon from '../../../../assets/icons/icon_bien.svg'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { formatDateDDMMYYMonthInLetters } from '../../../../utils/functions'
import {
  onContinueModalBtnClick
} from '../MovimientosTuDataControl/MovimientosTuDataControlFunctions'

import './EntityMovements.css'
import { useModal } from '../../../../hooks/useModal'
import { onAcceptClick, onRejectClick } from './EntityMovementsFunctions'
import { DataContext } from '../../../../context/DataContext'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const EntityMovements = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const navigate = useNavigate()

  const [modalMessage, setModalMessage] = useState('')
  const [showModal, handleShow, handleClose] = useModal()

  const { paymentOfEntityToBeReviewed } = dataContext?.dataState?.operative?.movements
  const { movementHystory } = paymentOfEntityToBeReviewed
  const {
    shoppingDate,
    usersAmount,
    databaseType,
    companyBussinesName,
    companyLogoPath,
    companyEmail,
    shoppingCode
  } = movementHystory

  const [authorize, setAuthorize] = useState(true)

  return (
    <section id="entityMovements">
      <div className="entityMovements-tside">
        <div className="entityMovements-tside-lside">
          <div className="entityMovements-tside-lside-header">
            <p>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.dataUsers : ''}</p>
          </div>
          <div className="entityMovements-tside-lside-info">
            <div>
              <p className="entityMovements-name">
                {scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.name : ''}
              </p>
              <p className="entityMovements-value">{companyBussinesName}</p>
            </div>
            <div>
              <span>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.payDone : ''}</span>
              <img
                src={checkIcon}
                alt={scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.altImageTxt1 : ''}
              />
            </div>
            <div>
              <p className="entityMovements-name">
                {scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.email : ''}
              </p>
              <p className="entityMovements-value">{companyEmail}</p>
            </div>
            <div>
              <p className="entityMovements-name">
                {scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.dataBaseType : ''}
              </p>
              <p className="entityMovements-value">{databaseType}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="entityMovements-bside">
        <h2>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.buyDBQuery : ''}</h2>
        <table>
          <thead>
            <th>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.thead1 : ''}</th>
            <th>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.thead2 : ''}</th>
            <th>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.thead3 : ''}</th>
            <th className="entityMovements-th3">
              {scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.thead4 : ''}
            </th>
          </thead>
          <tbody>
            <td>
              <div className="entityMovements-bside-companyCard">
                <img
                  src={companyLogoPath}
                  alt={scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.altImageTxt2 : ''}
                />
                <p>{companyBussinesName}</p>
              </div>
            </td>
            <td>
              <span>{`${usersAmount}${scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.usersAmountTxt : ''}`}</span>
            </td>
            <td>
              <p>{formatDateDDMMYYMonthInLetters(shoppingDate)}</p>
            </td>
            <td>
              <p>{shoppingCode}</p>
            </td>
          </tbody>
        </table>
      </div>
      <div className="entityMovements-comments">
      </div>
      <div className="entityMovements-footer">
        <button
          onClick={() => navigate('../PagoEntidades')}
          className="entityMovements-footer-backLink"
        >
          <img
            src={backIcon}
            alt={scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.altImageTxt3 : ''}
          />
          <span>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.back : ''}</span>
        </button>
        <div className="entityMovements-comments-btnbox">
          <button onClick={() => {
            setAuthorize(true)
            onAcceptClick(setModalMessage, handleShow, scriptsSave && scriptsSave.entityMovementsString)
          }}>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.accept : ''}</button>
          <button onClick={() => {
            setAuthorize(false)
            onRejectClick(setModalMessage, handleShow, scriptsSave && scriptsSave.entityMovementsString)
          }}>{scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.reject : ''}</button>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>
            {modalMessage}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className="Modal-normalBtn"
            onClick={handleClose}
          >
            {scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={() => onContinueModalBtnClick(authorize, shoppingCode, dataContext)}
          >
            {scriptsSave && scriptsSave.entityMovementsString ? scriptsSave.entityMovementsString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
