import React, { useContext, useState } from 'react'

import { DataContext } from '../../context/DataContext'
import inhabilitarIcon from '../../assets/icons/icon_inhabilitar_usuario.svg'
import eliminarIcon from '../../assets/icons/icon_eliminar_usuario.svg'
import editIcon from '../../assets/icons/icon_editar.svg'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from '../../components/Dropdown/Dropdown'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { getUsersDeletingAccountData, searchFilterDeletionRequests } from '../../utils/userQueries'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { Pagination } from '../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import { formatDeleteRequestsList, onDetailClick } from './DeleteRequestsFunctions'

import './DeleteRequests.css'
import { addElipsisAtEnd } from '../../utils/functions'
import { ScriptContext } from '../../context/ScriptsContext'

export const DeleteRequests = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, users } = dataState.operative
  const { deleteRequestsManagement } = users
  const deleteRequestsList = users.deleteRequestsManagement.list

  const [queryPersonNameBody, setQueryPersonNameBody] = useState(null)
  const [newPersonName, setNewPersonName] = useState('')
  const newPersonNameBody = {
    personId: {
      personName: newPersonName
    }
  }

  const [queryPersonIdentNumBody, setQueryPersonIdentNumBody] = useState(null)
  const [newPersonIdentNum, setNewPersonIdentNum] = useState('')
  const newPersonIdentNumBody = {
    personId: {
      personIdentNum: newPersonIdentNum
    }
  }

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    deleteRequestsManagement,
    'deleteRequestsManagement',
    queryPersonNameBody || queryPersonIdentNumBody ? searchFilterDeletionRequests : getUsersDeletingAccountData,
    dataTypes.updateDeleteRequests,
    idToken,
    dataContext,
    queryPersonNameBody || queryPersonIdentNumBody || null
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    deleteRequestsList,
    formatDeleteRequestsList,
    page,
    limit
  )

  const userTypeOption1 = scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.userTypeOption1 : ''
  const userTypeOption2 = scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.userTypeOption2 : ''
  const userTypeFilterOptions = [userTypeOption1, userTypeOption2]

  const statusOption1 = scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.statusOption1 : ''
  const statusOption2 = scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.statusOption2 : ''
  const statusFilterOptions = [statusOption1, statusOption2]

  const navigate = useNavigate()

  return (
    <section id='DeleteRequests'>
      <table>
        <thead>
          <tr>
            <th>{scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.id : ''}</th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.name : ''}
                type={'search'}
                newQueryBody={newPersonNameBody}
                setQueryBody={setQueryPersonNameBody}
                setNewValue={setNewPersonName}
              />
            </th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.id : ''}
                type={'search'}
                newQueryBody={newPersonIdentNumBody}
                setQueryBody={setQueryPersonIdentNumBody}
                setNewValue={setNewPersonIdentNum}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'userType'}
                list={originalList}
                options={userTypeFilterOptions}
                title={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.user : ''}
                updateList={setListFormatted}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'pqrsName'}
                list={originalList}
                options={statusFilterOptions}
                title={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.state : ''}
                type={'select'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.actions : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{addElipsisAtEnd(user.nameInList, 20)}</td>
                <td>{user.identNumInList}</td>
                <td>{user.userType}</td>
                <td>
                  <img src={
                    user.pqrsName === 'Eliminar Datos'
                      ? eliminarIcon
                      : inhabilitarIcon
                    }
                    alt={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.altImageTxt1 : ''}
                  />
                </td>
                <td>
                  <button className='UsersPanelDetailBtn'
                    onClick={() => onDetailClick(
                      navigate,
                      user,
                      idToken,
                      dataDispatch,
                      dataContext
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.altImageTxt2 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className='UsersPanelPagination'>
        <Pagination
          currentPage={deleteRequestsManagement.currentPage}
          paginationValues={paginationValues}
          scrollMeasurement={deleteRequestsManagement.scrollMeasurement}
          setPaginationValues={setPaginationValues}
          totalPages={totalPages}
          isNextActive={isNextActive}
          setIsNextActive={setIsNextActive}
          isPrevActive={isPrevActive}
          setIsPrevActive={setIsPrevActive}
        />
      </div>
    </section>
  )
}
