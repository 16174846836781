import { validateUserDocument } from '../../utils/userQueries'
import { handleRequestButton } from '../../utils/validateFunctions'

export const handleClickChangeButtonContent = (event, buttonContent, setButtonContent, userDocumentPathObject, setUrl) => {
  const buttonSelfContent = event.target.textContent
  setButtonContent(buttonSelfContent)
  if (buttonContent === 'Cara frontal') {
    setButtonContent('Cara posterior')
    setUrl(userDocumentPathObject[0].userDocumentPath)
  } else {
    setButtonContent('Cara frontal')
    setUrl(userDocumentPathObject[1].userDocumentPath)
  }
}

export const handleClick1 = (handleShow, setSelectedModal) => {
  handleShow()
  setSelectedModal('activaModalImagen')
}

export const handleClick2 = async (
  idToken,
  userId,
  handleShow,
  setSelectedModal,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  if (userId) {
    handleRequestButton(setIsRequesting)
    const validateUserDocumentResponse = await validateUserDocument(dataContext, idToken, userId, { validateDocument: true })
    if (validateUserDocumentResponse.response) {
      handleShow()
      setSelectedModal('activaModalConfirmacion')
    }
  }
}

export const onContinueModalBtnClick = (navigate, handleClose) => {
  navigate('../')
  handleClose()
}
