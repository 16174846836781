import React, { useContext } from 'react'

import { PQRSBodyRow } from './PQRSBodyRow/PQRSBodyRow'
import editIcon from '../../../../assets/icons/icon_editar.svg'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { getPQRSList } from '../../../../utils/userQueries'
import { DataContext } from '../../../../context/DataContext'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import { formatPqrsList } from './PQRSListFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './PQRSList.css'

export const PQRSList = () => {
  const { scriptsSave } = useContext(ScriptContext)

  const userPerson = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.userPerson : ''
  const userEnterprise = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.userEnterprise : ''
  const userFilter = [userPerson, userEnterprise]

  const pqrsFilterOption1 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption1 : ''
  const pqrsFilterOption2 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption2 : ''
  const pqrsFilterOption3 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption3 : ''
  const pqrsFilterOption4 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption4 : ''
  const pqrsFilterOption5 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption5 : ''
  const pqrsFilterOption6 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption6 : ''
  const pqrsFilterOption7 = scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.pqrsFilterOption7 : ''
  const PQRSFilter = [
    pqrsFilterOption1,
    pqrsFilterOption2,
    pqrsFilterOption3,
    pqrsFilterOption4,
    pqrsFilterOption5,
    pqrsFilterOption6,
    pqrsFilterOption7
  ]

  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, pqrs } = dataState.operative
  const { pqrsList } = pqrs
  const pqrsContent = pqrs.pqrsList.list
  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    pqrsList,
    'pqrsList',
    getPQRSList,
    dataTypes.updatePqrsList,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    pqrsContent,
    formatPqrsList,
    page,
    limit
  )

  return (
    <div className='pqrsListContainer'>
      <table className='pqrsList'>
        <thead className='pqrsListHead'>
          <tr className='pqrsListHeadRow'>
            <th className='pqrsListHeadCell'>
              <p>{scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.thead1 : ''}</p>
            </th>
            <th className='pqrsListHeadCell'>
              <div className='pqrsListTheadDropdowBtnn'>
                <Dropdown
                  filterBy={'name'}
                  list={originalList}
                  title={scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.thead2 : ''}
                  // type={'search'}
                  updateList={setListFormatted}
                />
              </div>
            </th>
            <th className='pqrsListHeadCell'>
              <div className='pqrsListTheadDropdowBtnn'>
                <Dropdown
                  filterBy={'pqrs'}
                  list={originalList}
                  options={PQRSFilter}
                  title={scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.thead3 : ''}
                  type={'select'}
                  updateList={setListFormatted}
                />
              </div>
            </th>
            <th className='pqrsListHeadCell'>
              <div className='pqrsListTheadDropdowBtnn flexCenter'>
                <Dropdown
                  filterBy={'user'}
                  list={originalList}
                  options={userFilter}
                  title={scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.thead5 : ''}
                  updateList={setListFormatted}
                />
              </div>
            </th>
            <th className='pqrsListHeadCell textCenter'>
              <p>{scriptsSave && scriptsSave.pqrsString ? scriptsSave.pqrsString.thead6 : ''}</p>
            </th>
          </tr>
        </thead>
        <tbody className='pqrsListBody'>
          {listFormatted.map((pqrs, index) => (
            <PQRSBodyRow key={index} pqrs={pqrs} editIcon={editIcon} />
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={pqrsList.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={pqrsList.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </div>
  )
}
