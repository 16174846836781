import React, { useContext, useState } from 'react'

import { DataContext } from '../../context/DataContext'
import habilitarIcon from '../../assets/icons/icon_bien.svg'
import inhabilitarIcon from '../../assets/icons/icon_inhabilitar_usuario.svg'
import editIcon from '../../assets/icons/icon_editar.svg'
import addCompanyIcon from '../../assets/icons/icon_userplus.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown } from '../../components/Dropdown/Dropdown'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { Pagination } from '../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'

import { showCompanies, searchFilterCompanies } from '../../utils/userQueries' //
import { formatCompaniesManagementList, onDetailClick } from './CompaniesAdminFunctions' //
import { addElipsisAtEnd } from '../../utils/functions'
import './CompaniesAdmin.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const CompaniesAdmin = () => {
  const dataContext = useContext(DataContext)
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, users } = dataState.operative
  const { companiesManagement } = users
  const companiesManagementList = users.companiesManagement.list

  const [queryCompanyNameBody, setQueryCompanyNameBody] = useState(null)
  const [newCompanyName, setNewCompanyName] = useState('')
  const newCompanyNameBody = {
    companyComertialName: newCompanyName
  }

  const [queryCompanyNitBody, setQueryCompanyNitBody] = useState(null)
  const [newCompanyNit, setNewCompanyNit] = useState('')
  const newCompanyNitBody = {
    companyNit: newCompanyNit
  }

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    companiesManagement,
    'companiesManagement',
    queryCompanyNameBody || queryCompanyNitBody ? searchFilterCompanies : showCompanies,
    dataTypes.updateCompaniesManagement,
    idToken,
    dataContext,
    queryCompanyNameBody || queryCompanyNitBody || null
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    companiesManagementList,
    formatCompaniesManagementList,
    page,
    limit
  )
  const userTypeOption1 = scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.userTypeOption1 : ''
  const userTypeOption2 = scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.userTypeOption2 : ''
  const userTypeFilterOptions = [userTypeOption1, userTypeOption2]

  const statusOption1 = scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.statusOption1 : ''
  const statusOption2 = scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.statusOption2 : ''
  const statusFilterOptions = [statusOption1, statusOption2]

  const navigate = useNavigate()

  return (
    <section id='companiesAdmin'>
      <div className="companiesAdminAddNewCompanyContainer">
        <Link className='companiesAdminAddNewCompanyBtn' to='./CrearEmpresa' state={{ pqrsId: 6, isUser: true, isCompanyUser: true }}>
          <img
            className='companiesAdminAddNewCompanyImg'
            src={addCompanyIcon}
            alt={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.altImgTxt1 : ''}
          />
          <p className='companiesAdminAddNewCompanyTxt'>
            {scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.btnTxt : ''}
          </p>
        </Link>
      </div>
      <table>
        <thead>
          <tr>
            <th>{scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.id : ''}</th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.name : ''}
                type={'search'}
                newQueryBody={newCompanyNameBody}
                setQueryBody={setQueryCompanyNameBody}
                setNewValue={setNewCompanyName}
              />
            </th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.id : ''}
                type={'search'}
                newQueryBody={newCompanyNitBody}
                setQueryBody={setQueryCompanyNitBody}
                setNewValue={setNewCompanyNit}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'userType'}
                list={originalList}
                options={userTypeFilterOptions}
                title={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.user : ''}
                updateList={setListFormatted}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'companyUsed'}
                list={originalList}
                options={statusFilterOptions}
                title={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.state : ''}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.actions : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map((company) => (
              <tr key={company.id}>
                <td>{company.id}</td>
                <td>{addElipsisAtEnd(company.companyComertialName, 20)}</td>
                <td>{company.companyNit}</td>
                <td>{company.userType}</td>
                <td>
                  <img src={
                    company.companyUsed
                      ? habilitarIcon
                      : inhabilitarIcon
                    }
                    alt={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.altImgTxt2 : ''}
                  />
                </td>
                <td>
                  <button className='companiesAdminDetailBtn'
                    onClick={() => onDetailClick(
                      navigate,
                      company,
                      idToken,
                      dataDispatch,
                      dataContext
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.companiesAdminString ? scriptsSave.companiesAdminString.altImgTxt3 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className='companiesAdminPagination'>
        <Pagination
          currentPage={companiesManagement.currentPage}
          paginationValues={paginationValues}
          scrollMeasurement={companiesManagement.scrollMeasurement}
          setPaginationValues={setPaginationValues}
          totalPages={totalPages}
          isNextActive={isNextActive}
          setIsNextActive={setIsNextActive}
          isPrevActive={isPrevActive}
          setIsPrevActive={setIsPrevActive}
        />
      </div>
    </section>
  )
}
